import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 1px 0;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

export const Search = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
  height: 35px;
  padding: 0 5px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Input = styled.input`
  display: flex;
  flex: 1;
  padding-left: 5px;
  border-radius: 5px;
  background: white;
  color: #202933;
  outline:none;
  border: none;
`;

export const Items = styled.div`
  position: absolute;
  min-height: 35px;
  max-height: 150px;
  height: fit-content;
  width: 100%;
  background: #fff;
  top: calc(100% + 1px);
  left: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

export const Item = styled.div`
  width: 100%;
  height: 35px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    color: #4485f3;
    transition: all 150ms linear;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  height: 42px;
`;

Wrapper.Search = Search;
Wrapper.Toolbar = Toolbar;

Search.Icon = Icon;
Search.Input = Input;
Search.Items = Items;

Items.Item = Item;
