import styled, { keyframes } from 'styled-components';

const preloader = keyframes`
  0% {
    box-shadow: 0 15px 0 -15px #000;
  }
  100% {
    box-shadow: 0 15px 0 #000;
  }
`;

const preloaderMobile = keyframes`
  0% {
    box-shadow: 0 15px 0 -15px #fff;
  }
  100% {
    box-shadow: 0 15px 0 #fff;
  }
`;

export const MicrophoneContainer = styled.div`
  margin: 3px 10px 0 0;
  cursor: pointer;
  color: #000;
  
  @media (max-width: 480px) {
    color: #fff;
    margin: 10px 0 0 0;
  }
`;

export const Wrapper = styled.div`
  fill: #387BE0;
  cursor: pointer;
  position: relative;
  
  @media (max-width: 480px) {
    color: #fff;
    margin-top: 10px;
    margin-left: 10px;
  }
`;

export const Container = styled.div`
  font-size: 10px;
  position: absolute;
  width: auto;
  height: auto;
  display: inline-block;
  text-align: center;
  border-collapse: collapse;
  vertical-align: top;
  left: 25px;
`;

export const Preloader = styled.div`
  width: 5px;
  height: 5px;
  top: 50%;
  border-radius: 50%;
  position: relative;
  animation: ${preloader} 0.9s 0.35s ease alternate infinite;
  margin: -10px auto 0;
  
  &:after, &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    animation: ${preloader} 0.9s ease alternate infinite;
  }

  &:before {
    left: -10px;
    animation-delay: 0.2s;
  }

  &:after {
    right: -10px;
    animation-delay: 0.5s;
  }
  
  @media (max-width: 480px) {
    animation: ${preloaderMobile} 0.9s 0.35s ease alternate infinite;

    &:after, &:before {
      animation: ${preloaderMobile} 0.9s ease alternate infinite;
    }
  }
`;

export const Audio = styled.audio``;

Wrapper.Container = Container;

Container.Preloader = Preloader;
