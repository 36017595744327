import { delay, put, select, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getClientsList } from '../../infra/api/ChatAPI/index';
import { searchListActions } from '../reducers/searchList';
import roomsSelectors from '../selectors/rooms';
import createActions from '../reducers/createActions';
import { logError } from '../../helper/sentry';

export default function* searchClient(a) {
  try {
    const clientID = a.payload;

    yield delay(1200);

    if (clientID.length > 3) {
      const { clients_ids } = yield call(getClientsList, clientID);
      yield put(searchListActions.setState(clients_ids));
    } else {
      yield put(searchListActions.setState([]));
    }

    if (clientID) {
      const rooms = yield select(roomsSelectors.getRooms);
      const foundRooms = Object.keys(rooms).filter(
        (key) => !(rooms[key].client_id.indexOf(clientID) === -1 && rooms[key].client_name.indexOf(clientID) === -1),
      );

      if (!foundRooms.length) {
        yield put(createActions.onTryBindClient(clientID));
      }
    }
  } catch (error) {
    yield call(toast.error, `searchClient-saga: ${error.message}`);
    logError(error);
  }
}
