import { put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { messagesActions } from '../reducers/messages';
import messagesSelectors from '../selectors/messages';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* receivedEditedMessage(action) {
  if (!action || !action.payload) return;
  try {
    let exist = false;
    const rooms = JSON.parse(JSON.stringify(yield select(roomsSelectors.getRooms)));
    const messagesReducer = JSON.parse(JSON.stringify(yield select(messagesSelectors.getStore)));
    const roomId = Object.keys(rooms).filter((item) => rooms[item].client_id === action.payload[0].client_id);
    const { latest_msg } = rooms[roomId];
    const { messages } = messagesReducer[roomId[0]];
    action.payload.forEach((item) => {
      const msg = Object.keys(messages).filter((key) => messages[key].id === item.msg_id);
      messages[msg[0]].type = 4;
      messages[msg[0]].text = item.text;
      if (latest_msg.id === item.msg_id) exist = true;
    });
    if (exist) {
      latest_msg.text = action.payload[0].text;
      yield put(roomsActions.setUpdateRoomSettings({
        id: roomId,
        room: {
          latest_msg,
        },
      }));
    }
    yield put(messagesActions.setMessages({ messages, room_id: roomId, rooms: messagesReducer }));
  } catch (error) {
    yield call(toast.error, `receivedEditedMessage-saga: ${error.message}`);
    logError(error);
  }
}
