import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';
import reducers from './reducers';
import rootSaga from './sagas/rootSaga';
import config from '../config';

const bindMiddleware = (middlewares) => {
  if (config.nodeEnv !== 'production') {
    return composeWithDevTools(applyMiddleware(...middlewares));
  }
  return applyMiddleware(...middlewares);
};

const configureStore = () => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
  const sagaMiddleware = createSagaMiddleware();
  const persistedState = localStorage.getItem('settings')
    ? JSON.parse(localStorage.getItem('settings'))
    : {
      volume: 1.0,
      visibilityNotification: true,
      visibilityPushNotification: true,
    };
  const store = createStore(
    reducers,
    compose(sentryReduxEnhancer, bindMiddleware([sagaMiddleware])),
  );

  store.getState().settings = persistedState;

  store.subscribe(() => {
    localStorage.setItem('settings', JSON.stringify(store.getState().settings));
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
