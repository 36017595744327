import { put, select, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from '../../constants/actions';
import { splitFileNameAndUrl } from '../../helper/links';
import unreadMsgCountSaga from './unreadMsgCount';
import msg from '../../sounds/new_message-3.ogg';
import settingsSelectors from '../selectors/settings';
import { imageLinksActions } from '../reducers/imageLinks';
import { chatMessagesUpdaterActions } from '../reducers/chatMessagesUpdater';
import managerInfoSelectors from '../selectors/managerInfo';
import messagesSelectors from '../selectors/messages';
import { messagesActions } from '../reducers/messages';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import createActions from '../reducers/createActions';
import { logError } from '../../helper/sentry';

export default function* messageReceived(action) {
  if (!action || !action.payload) return;
  try {
    const {
      sender, text, room_id, id, time, type, client_id, status, quoted_msg = null,
    } = action.payload;

    const messagesStore = yield select(messagesSelectors.getStore);
    const rooms = yield select(roomsSelectors.getRooms);
    const managerID = yield select(managerInfoSelectors.getManagerId);
    const { volume, visibilityNotification } = yield select(settingsSelectors.getSettings);
    let isAppend = false;
    const roomMsgs = messagesStore[room_id];
    const room = rooms[room_id] || {};
    if (room.client_name === undefined) {
      yield put(createActions.onGetUserRooms({
        room_id,
        count: 0,
      }));
      yield call(unreadMsgCountSaga, 1);
      return;
    }
    const { messages = [] } = roomMsgs || {};
    const { id: lastMessageId = 0 } = room?.latest_msg || {};
    let { oldest_msg_id = 0, unread_msg_count = 0 } = room || {};
    if (messages.length === 0 && lastMessageId === 0) {
      isAppend = true;
    }
    if (messages.length > 0 && messages[0].id === lastMessageId) {
      isAppend = true;
    }

    const storeMsg = {
      sender, text, time, id, room_id, type, client_id, status, quoted_msg,
    };

    oldest_msg_id = oldest_msg_id === 0 ? id : oldest_msg_id;
    oldest_msg_id = sender !== managerID ? oldest_msg_id : 0;
    unread_msg_count = sender !== managerID ? ++unread_msg_count : 0;

    const latestMsgAppend = true;

    yield put(roomsActions.setUpdateRoomSettings({
      id: room_id,
      room: {
        id: room_id,
        managerId: room.managerId,
        latestMsg: action.payload,
        client_id,
        oldest_msg_id,
        unread_msg_count,
      },
    }));

    if (isAppend) {
      yield put(roomsActions.setUpdateRoomSettings({
        id: room_id,
        room: {
          client_id,
          oldest_msg_id,
          unread_msg_count,
        },
      }));
      yield put(messagesActions.setMessageReceived(storeMsg));
      yield put(chatMessagesUpdaterActions.setState({
        client: sender,
        room_id,
      }));
    }
    if (latestMsgAppend) {
      yield put(roomsActions.setUpdateRoomSettings({
        id: room_id,
        room: {
          id: room_id,
          latest_msg: storeMsg,
          unread_msg_count,
        },
      }));
    }
    if (type === actions.MSG_TYPE_IMAGE) {
      const { url } = splitFileNameAndUrl(text);
      yield put(imageLinksActions.setImageLinkStore({
        roomId: room_id,
        link: url,
      }));
    }
    yield call(unreadMsgCountSaga, 1);
    if ((type === 0 || type === 1 || type === 2) && sender !== managerID) {
      const activeRoom = rooms[room_id];
      let content = '';
      switch (type) {
      case 0: content = `${activeRoom.client_name}: \n ${text}`; break;
      case 1: content = `${activeRoom.client_name}: new File`; break;
      case 2: content = `${activeRoom.client_name}: new Image`; break;
      default: break;
      }
      const audio = document.createElement('audio');
      audio.src = msg;
      audio.volume = volume;
      audio.play();
      if (visibilityNotification) toast(content);
    }
  } catch (error) {
    yield call(toast.error, `receivedMessage-saga: ${error.message}`);
    logError(error);
  }
}
