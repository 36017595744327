import constants from '../../../../../../config';

const checkValidation = (str) => {
  const maxLengthStr = str.trim().length <= constants.switchToLength;
  const emptyStr = str.trim().length !== 0;
  const whitespaceAtTheBeginning = /^(?![\s-])[\w\s-]+$/.test(str);
  return emptyStr && maxLengthStr && whitespaceAtTheBeginning;
};

export default checkValidation;
