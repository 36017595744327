import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getToken from '../../firebase';
import createActions from '../../redux/reducers/createActions';

const FireBase = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getToken()
      .then((promiseResult) => {
        const payload = {
          data: {
            firebaseToken: promiseResult,
          },
        };
        dispatch(createActions.onSendFireBaseToken(payload));
      });
  }, []);

  return <></>;
};

export default FireBase;
