import { createSelector } from 'reselect';

const roomsState = (state) => state.roomsReducer;

const getRooms = createSelector(roomsState, (s) => s.rooms);
const getActiveRoomId = createSelector(roomsState, (s) => s.active_room_id);
const getPrevActiveRoomId = createSelector(roomsState, (s) => s.prev_active_room_id);

const roomsSelectors = { getRooms, getActiveRoomId, getPrevActiveRoomId };

export default roomsSelectors;
