import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import config from '../../config';
import { fileListActions } from '../reducers/fileList';
import { modalsActions } from '../reducers/modals';
import createActions from '../reducers/createActions';
import { logError } from '../../helper/sentry';

export const readFileAsDataURL = (file) => new Promise((resolve) => {
  const fileReader = new FileReader();
  fileReader.onload = () => resolve(`name:{${file.name}},dataUrl:{${fileReader.result}}`);
  fileReader.readAsDataURL(file);
});

export default function* sendFiles(a) {
  try {
    const { roomId, files, type } = a.payload;
    if (typeof roomId !== 'string' || roomId.length === 0) {
      console.error('sendFiles saga: invalid roomId param');
      return;
    }
    if (!files) {
      console.error('sendFiles saga: invalid files param');
      return;
    }
    if (typeof type !== 'number') {
      console.error('sendFiles saga: invalid type param');
      return;
    }
    const rejectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      let finalType = type;
      const fileType = files[i].type.split('/')[0];
      if (finalType === 2 && fileType !== 'image') finalType = 1;
      if (files[i].size <= config.maxFileSize) {
        const message = yield call(readFileAsDataURL, files[i]);
        yield put(createActions.onSendMessage({
          roomId,
          text: message,
          type: finalType,
        }));
      } else {
        rejectedFiles.push(files[i].name);
      }
    }
    if (rejectedFiles.length > 0) {
      const msg = `${rejectedFiles.toString()} can not be loaded because the size is bigger than
          allowed - 5MB.`;
      yield put(modalsActions.openWarningModal({ msg }));
    }
    yield put(fileListActions.setState([]));
  } catch (error) {
    yield call(toast.error, `sendFiles-saga: ${error.message}`);
    logError(error);
  }
}
