import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { editedMessageActions } from '../reducers/editedMessage';
import { logError } from '../../helper/sentry';

export default function* closeEditedMessage(action) {
  if (!action) return;
  try {
    yield put(editedMessageActions.setVisibility(false));
    yield put(editedMessageActions.setId(null));
    yield put(editedMessageActions.setText(''));
  } catch (error) {
    yield call(toast.error, `closeEditedMessage-saga: ${error.message}`);
    logError(error);
  }
}
