import React from 'react';
import ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { modalsActions } from '../../../../redux/reducers/modals';
import modalsSelectors from '../../../../redux/selectors/modals';
import { Modal, Window, Buttons } from './styled';

const ConfirmModalWindow = ({ intl }) => {
  const {
    confirm: { msg, enabled, resultCb, negativeButton, positiveButton },
  } = useSelector(modalsSelectors.getModals);
  const dispatch = useDispatch();

  const onConfirm = () => {
    resultCb(true);
    dispatch(modalsActions.closeConfirmModal());
  };

  const onCancel = () => {
    resultCb(false);
    dispatch(modalsActions.closeConfirmModal());
  };

  const confirmText = intl.formatMessage({ id: 'confirmationHeader' });

  if (!enabled) {
    return <></>;
  }
  return (
    ReactDOM.createPortal(
      <Modal>
        <Modal.Window>
          <Window.Header>{confirmText}</Window.Header>
          <Window.Message>{msg}</Window.Message>
          <Window.Buttons>
            <Buttons.Cancel onClick={onCancel}>{negativeButton}</Buttons.Cancel>
            <Buttons.Line className="vertical_line" />
            <Buttons.Confirm onClick={onConfirm} className="modal__btn positive">{positiveButton}</Buttons.Confirm>
          </Window.Buttons>
        </Modal.Window>
      </Modal>,
      document.getElementById('modal'),
    )
  );
};

export default injectIntl(ConfirmModalWindow);
