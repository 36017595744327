import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import quotedMessageSelectors from '../../../redux/selectors/quotedMessage';
import messageSelectionSelectors from '../../../redux/selectors/messageSelection';
import editedMessageSelectors from '../../../redux/selectors/editedMessage';
import roomsSelectors from '../../../redux/selectors/rooms';
import createActions from '../../../redux/reducers/createActions';
import Quoted from './Quoted';
import EditedWarningMessage from './EditedWarningMessage';
import SendBox from './SendBox';

const Footer = ({ roomId, intl }) => {
  const [text, setText] = useState('');
  const { visibility, selectedMessages } = useSelector(messageSelectionSelectors.getMessageSelection);
  const visibilityQuotedMessage = useSelector(quotedMessageSelectors.getVisibility);
  const editedMessageStore = useSelector(editedMessageSelectors.getStore);
  const quoteMessage = useSelector(quotedMessageSelectors.getMessage);
  const rooms = useSelector(roomsSelectors.getRooms);
  const { client_version, client_id } = rooms[roomId];
  const dispatch = useDispatch();

  const placeholder = intl.formatMessage({ id: 'typeYourMessage' });

  const zeroFirstFormat = (value) => (value < 10 ? `0${value}` : value);

  const sendMsg = () => {
    switch (true) {
    case visibilityQuotedMessage: dispatch(createActions.onSendMessage({
      text,
      roomId,
      quoteMessage,
    })); break;
    case editedMessageStore.visibility: dispatch(createActions.onSendEditMessage({ text, roomId })); break;
    default: dispatch(createActions.onSendMessage({ text, roomId })); break;
    }
    setText('');
  };

  const closeEditMessage = () => {
    dispatch(createActions.onCloseEditedMessage());
  };

  const handleOnKeyDown = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      setText(`${text}\n`);
    } else if (event.keyCode === 13) {
      event.preventDefault();
      if (editedMessageStore.visibility) {
        if (editedMessageStore.edited) sendMsg();
      } else {
        sendMsg();
      }
    }
    return true;
  };

  const cancelSelectedMessage = () => {
    dispatch(createActions.onMessageSelectionVisibility({
      visibility: false,
      msgId: 'delete',
    }));
  };

  const deleteMessages = () => {
    dispatch(createActions.onDeleteMessages(selectedMessages));
  };

  const closeQuote = () => {
    dispatch(createActions.onQuotedMessage({
      visibility: false,
      message: null,
    }));
  };

  const dateFormatting = (ms) => {
    const data = new Date(ms);
    const day = zeroFirstFormat(data.getDate());
    const month = zeroFirstFormat(data.getMonth() + 1);
    const year = data.getFullYear();
    const hours = zeroFirstFormat(data.getHours());
    const minutes = zeroFirstFormat(data.getMinutes());
    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  };

  useEffect(() => setText(editedMessageStore.text), [editedMessageStore.text]);

  useEffect(() => {
    if (editedMessageStore.visibility) dispatch(createActions.onCheckingEditedMessage({ text }));
  }, [text]);

  return (
    <>
      {visibilityQuotedMessage && (
        <Quoted
          clientVersion={client_version}
          text={quoteMessage.text}
          sender={quoteMessage.sender}
          clientId={client_id}
          time={dateFormatting(quoteMessage.time)}
          isImage={quoteMessage.type === 2}
          onClose={closeQuote}
        />
      )}
      {editedMessageStore.visibility && client_version === 'Mobile Version' && (
        <EditedWarningMessage />
      )}
      <SendBox
        text={text}
        roomId={roomId}
        visibility={visibility}
        placeholder={placeholder}
        selectedMessages={selectedMessages}
        editedMessageStore={editedMessageStore}
        sendMsg={sendMsg}
        setText={setText}
        deleteMessages={deleteMessages}
        handleOnKeyDown={handleOnKeyDown}
        closeEditMessage={closeEditMessage}
        cancelSelectedMessage={cancelSelectedMessage}
      />
    </>
  );
};

Footer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
  roomId: PropTypes.string.isRequired,
};

export default Footer;
