import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #CFD2DE;
`;

export const Deleted = styled.div`
  height: 62px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Cancel = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  &:hover {
    color: #0b76ef;
    transition: 300ms all linear;
  }
  
  & > svg {
    width: 30px;
    height: 30px;
  }  
  
  & > svg:hover {
    fill: #0b76ef;
    transition: 300ms all linear;
    cursor: pointer;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 12px;
`;

export const SelectedMessages = styled.p`
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  user-select: text;
  font-weight: bold;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 25px;

  &:hover {
    color: #0b76ef;
    transition: 300ms all linear;
  }

  & > svg {
    width: 30px;
    height: 30px;
  }

  & > svg:hover {
    fill: #0b76ef;
    transition: 300ms all linear;
    cursor: pointer;
  }
`;

export const SendBoxContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 0 10px;
  align-items: center;
`;

export const Input = styled(TextareaAutosize)`
  display: flex;
  flex: 1 0 0;
  border: none;
  outline: none;
  resize: none;
  padding: 17px 15px;
  scrollbar-width: none;
  background-color: #CFD2DE;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 15px;
  
  &::-webkit-scrollbar {
    display: none;
    scroll-behavior: smooth;
  }
  
  &::placeholder {
    color: #5a5a5a;
    font-size: 1em;
  }
`;

export const Button = styled.div`
  display: flex;
  margin: 10px -3px;
  color: #202933;
  cursor: pointer;
  z-index: 1;
`;

export const Image = styled.img``;

Wrapper.Deleted = Deleted;
Wrapper.SendBoxContainer = SendBoxContainer;

Deleted.Cancel = Cancel;
Deleted.SelectedMessages = SelectedMessages;
Deleted.Icon = Icon;

Cancel.Text = Text;
Icon.Text = Text;

SendBoxContainer.Input = Input;
SendBoxContainer.Button = Button;

Button.Image = Image;
