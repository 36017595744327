import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from '../../constants/actions';
import * as chatAPI from '../../infra/api/ChatAPI';
import config from '../../config';
import { splitFileNameAndUrl } from '../../helper/links';
import { quotedMessageActions } from '../reducers/quotedMessage';
import { imageLinksActions } from '../reducers/imageLinks';
import { chatMessagesUpdaterActions } from '../reducers/chatMessagesUpdater';
import { messagesActions } from '../reducers/messages';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export const splitMessage = (message, maxLen, separator = ' ') => {
  const messages = [];
  if (message.length <= maxLen) {
    messages.push(message);
    return messages;
  }
  let startPos = 0;
  let endPos = maxLen;
  while (startPos <= message.length) {
    const nextMsg = message.substring(startPos, endPos);
    if (nextMsg.length === 0) break;
    messages.push(nextMsg);
    startPos = endPos;
    endPos += maxLen;
  }
  messages.forEach((val, idx, arr) => {
    if (val[val.length - 1] === separator) {
      return;
    }
    // having next part
    if (idx + 1 < arr.length) {
      const lastSpaceIdx = val.lastIndexOf(separator);
      if (lastSpaceIdx === -1) return;
      const transPart = val.substring(lastSpaceIdx + 1);
      messages[idx + 1] = transPart + arr[idx + 1];
      messages[idx] = val.substring(0, lastSpaceIdx);
    }
  });
  return messages;
};

export default function* sendMessage(a) {
  if (!a || !a.payload) return;
  const { roomId, text, type = 0, quoteMessage = null } = a.payload;
  const date = new Date().getTime();
  if (typeof roomId !== 'string' || roomId.length === 0) return;

  if (typeof text !== 'string' || !text.match(/[\S]/)) return;

  try {
    let messages;
    if (type === actions.MSG_TYPE_TXT) {
      messages = splitMessage(text, config.sendingSymbolsCount);
    } else {
      messages = [text];
    }
    for (let i = 0; i < messages.length; i++) {
      const msgArgs = {
        room_id: roomId,
        text: messages[i],
        msg_time: date,
        quoted_msg_id: quoteMessage?.id || null,
        type,
      };
      yield put(messagesActions.setSendMessage({
        room_id: roomId,
        text: messages[i],
        quoted_msg: quoteMessage,
        time: date,
        type,
        status: 0,
      }));
      yield put(quotedMessageActions.setVisibility(false));
      yield put(quotedMessageActions.setMessage(null));
      const message = yield call(chatAPI.sendMessage, msgArgs);
      const { room_id, sender, text: link } = message;
      if (room_id !== roomId) return;
      yield put(messagesActions.setSendedMessage({ ...message, time: new Date(message.time).getTime() }));
      yield put(chatMessagesUpdaterActions.setState({
        client: sender,
        roomId,
      }));

      const rooms = yield select(roomsSelectors.getRooms);
      yield put(roomsActions.setUpdateRoom({
        ...rooms[roomId],
        id: roomId,
        manager_id: sender,
        status: 'active',
        latest_msg: message,
      }));
      if (type === actions.MSG_TYPE_IMAGE) {
        const { url } = splitFileNameAndUrl(link) || {};
        yield put(imageLinksActions.setImageLinkStore({
          roomId,
          link: url,
        }));
      }
    }
  } catch (error) {
    yield call(toast.error, `sendMessage-saga: ${error.message}`);
    logError(error);
  }
}
