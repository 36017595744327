import styled from 'styled-components';

export const Wrapper = styled.span``;

export const Text = styled.span``;

export const Copy = styled.span`
  & > svg {
    cursor: pointer;
  }
  
  @media (max-width: 480px) {
    display: none;
  }
`;

Wrapper.Text = Text;
Wrapper.Copy = Copy;
