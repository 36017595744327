import { createSlice } from '@reduxjs/toolkit';

const storeName = 'messageSelection';

const initialState = {
  visibility: false,
  selectedMessages: [],
};

export const messageSelectionSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setVisibility(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.visibility = action.payload;
    },
    setSelectedMessages(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.selectedMessages = action.payload;
    },
    clear(state) {
      // eslint-disable-next-line no-param-reassign
      state.selectedMessages = initialState.selectedMessages;
    },
  },
});

export const messageSelectionActions = {
  setVisibility: messageSelectionSlice.actions.setVisibility,
  clear: messageSelectionSlice.actions.clear,
  setSelectedMessages: messageSelectionSlice.actions.setSelectedMessages,
};

export const messageSelectionReducer = messageSelectionSlice.reducer;
