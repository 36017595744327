import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as linkify from 'linkifyjs';
import { Wrapper, Quote } from './styled';
import managerInfoSelectors from '../../../../../redux/selectors/managerInfo';
import CompositionMessage from '../CompositionMessage';
import quoteSvg from '../../../../../images/quote.svg';
import { Container, Preloader, Content } from '../ImageMessage/styled';
import { splitFileNameAndUrl } from '../../../../../helper/links';

const DefaultMessage = (props) => {
  const managerId = useSelector(managerInfoSelectors.getManagerId);
  const { message: { text, quoted_msg: quotedMessage, status }, isMyMsg, expirationTime } = props;

  const urls = linkify.find(text);
  const deleteMessage = status === 3;
  const viewPreviewLink = urls.length > 0 && !quotedMessage;
  const timeFormatted = moment(quotedMessage?.time).format('DD.MM.YYYY, hh:mm');
  const description = `${managerId === quotedMessage?.sender_id ? 'Manager' : 'Client'}, ${timeFormatted}`;
  const isImageQuoted = quotedMessage?.type === 2;
  const { url = '' } = (isImageQuoted && quotedMessage?.text && splitFileNameAndUrl(quotedMessage?.text)) || {};
  const imageView = quotedMessage?.text && splitFileNameAndUrl(quotedMessage?.text)
    ? (
      <Container.QuotedImage
        down
        src={url}
        unloader="Ops... Failed to download image"
        loader={(
          <Preloader>
            <Preloader.Content>
              <Content.First />
              <Content.Second />
              <Content.Third />
            </Preloader.Content>
          </Preloader>
        )}
      />
    )
    : (
      <Preloader>
        <Preloader.Content>
          <Content.First />
          <Content.Second />
          <Content.Third />
        </Preloader.Content>
      </Preloader>
    );
  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <CompositionMessage {...props}>
      <Wrapper isMyMsg={isMyMsg} msgDelete={deleteMessage} expiration={expirationTime}>
        {quotedMessage && (
          <Wrapper.Quote>
            <Quote.Icon src={quoteSvg} alt="quote" />
            <Quote.Text>{isImageQuoted ? imageView : quotedMessage.text}</Quote.Text>
            <Quote.Description>{description}</Quote.Description>
          </Wrapper.Quote>
        )}
        {viewPreviewLink && <Wrapper.Preview url={urls[0].href} />}
        <Wrapper.Content>{text}</Wrapper.Content>
      </Wrapper>
    </CompositionMessage>
  );
};

export default DefaultMessage;
