import { createSlice } from '@reduxjs/toolkit';

const storeName = 'fileList';

const initialState = {
  state: [],
};

export const fileListSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const fileListActions = {
  setState: fileListSlice.actions.setState,
};

export const fileListReducer = fileListSlice.reducer;
