import styled from 'styled-components';

export const WarningMessage = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 73px;
  background-color: #e8aa3b;
  border-bottom: 1px solid gray;
  
  & > svg {
    margin-right: 10px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 73px;
  background-color: #CFD2DE;
  border-bottom: 1px solid gray;
  
  & > svg {
    position: absolute;
    top: 5px;
    left: 45px;
  }
`;

export const Message = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 1430px;
  width: 100%;
`;

export const Sender = styled.div`
  font-size: 13px;
  margin-top: 3px;
  color: #635e5e;
`;

export const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 40px;
  cursor: pointer;
`;

Wrapper.Message = Message;
Wrapper.Sender = Sender;
Wrapper.Close = Close;
