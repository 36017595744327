/* eslint-disable react/forbid-prop-types */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { RawContainer, RawCell, RawContainerLi, AnsweredHeaderContainers, RawContainerSpan } from './styled';

const Raw = ({ managerStat, addMargin, children }) => {
  const hasSub = managerStat.sub_managers;
  const renderCell = (key) => (hasSub ? `${managerStat[`sum_${key}`]} / ${managerStat[key]}` : managerStat[key]);
  const renderTimerCell = (key) => (hasSub
    ? `${moment.utc(managerStat[`sum_${key}`] * 1000).format('HH:mm:ss')} / ${moment.utc(managerStat[key] * 1000).format('HH:mm:ss')}`
    : moment.utc(managerStat[key] * 1000).format('HH:mm:ss')
  );

  const renderContent = (
    <>
      <RawCell width={`${320 - addMargin}px`} right>
        <RawContainerSpan>
          {managerStat.manager_id}
        </RawContainerSpan>
      </RawCell>
      <RawCell width="185px">
        {renderCell('total_calls')}
      </RawCell>
      <RawCell width="185px">
        {renderCell('unique_clients')}
      </RawCell>
      <RawCell width="185px">
        {renderCell('decline_calls')}
      </RawCell>
      <RawCell width="185px">
        {renderCell('missed_calls')}
      </RawCell>
      <RawCell width="372px">
        <AnsweredHeaderContainers>
          <div>
            {renderCell('answered_15s')}
          </div>
          <div>
            {renderCell('answered_60s')}
          </div>
          <div>
            {renderCell('answered_90s')}
          </div>
          <div>
            {renderCell('answered_calls')}
          </div>
        </AnsweredHeaderContainers>
      </RawCell>
      <RawCell width="250px">
        {renderTimerCell('total_calls_duration')}
      </RawCell>
    </>
  );
  return (
    <RawContainerLi className={hasSub ? '' : 'summary'}>
      {hasSub
        ? (
          <details>
            <RawContainer className="summary">
              {renderContent}
            </RawContainer>
            {children}
          </details>
        )
        : renderContent}
    </RawContainerLi>
  );
};
Raw.propTypes = {
  managerStat: PropTypes.object.isRequired,
  addMargin: PropTypes.number.isRequired,
  children: PropTypes.object.isRequired,
};

export default Raw;
