/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { statisticActions } from '../../redux/reducers/statistics';
import { getStatistics, getStatisticsIsLoading } from '../../redux/selectors/statistics';
import { TableContainer, List, STBody } from './styled';
import Raw from './Raw';
import Headers from './Headers';
import Filters from './Filters';
import { Container, Preloader, Content } from '../Loading/styled';

const PersonalStatistics = () => {
  const dispatch = useDispatch();
  const statistics = useSelector(getStatistics);
  const isLoading = useSelector(getStatisticsIsLoading);
  const [showedSubsFor, setShowedSubsFor] = useState([]);
  useEffect(() => {
    dispatch(statisticActions.getStatisticsRequest());
  }, []);
  useEffect(() => {
    addBg();
  });
  const openSubs = (id) => {
    if (showedSubsFor.includes(id)) {
      setShowedSubsFor(showedSubsFor.filter((showedId) => showedId !== id));
    } else {
      setShowedSubsFor([...showedSubsFor, id]);
    }
  };
  const renderStat = (stats, addMargin = 0) => {
    const hasSub = stats.sub_managers;
    const showedSubs = showedSubsFor.includes(stats.manager_id);
    if (hasSub) {
      return (
        <>
          <Raw managerStat={stats} key={stats.manager_id} addMargin={addMargin} openSubs={openSubs} showedSubs={showedSubs}>
            <ul>{stats.sub_managers.map((manStat) => renderStat(manStat, addMargin + 24))}</ul>
          </Raw>
        </>
      );
    }
    return (
      <Raw managerStat={stats} key={stats.manager_id} addMargin={addMargin} openSubs={openSubs} showedSubs={showedSubs} />
    );
  };
  const addBg = () => {
    const a = document.querySelectorAll('.summary');
    a.forEach((el, i) => el.classList.add(i % 2 === 0 ? 'even' : 'odd'));
  };
  return (
    <>
      <Filters />
      <TableContainer>
        <Headers />
        {isLoading
          ? (
            <STBody padding={isLoading ? '5px 0 15px' : '0'}>
              <Container.Preloader>
                <Preloader.Content>
                  <Content.First />
                  <Content.Second />
                  <Content.Third />
                </Preloader.Content>
              </Container.Preloader>
            </STBody>
          )
          : isEmpty(statistics) || statistics.message
            ? <STBody padding="15px 0">{statistics.message || 'There are no results.'}</STBody>
            : <List>{renderStat(statistics)}</List>}
      </TableContainer>
    </>
  );
};

export default PersonalStatistics;
