import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eceefa;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  & > svg {
    width: 60px;
    height: 60px;
    color: #a1a1a1;
  }
`;

export const Text = styled.h2`
  color: #a1a1a1;
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
`;

Wrapper.Container = Container;

Container.Text = Text;
