import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: inherit;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 1.5em;
  text-align: center;
  color: #CFD2DE;
`;

Wrapper.Container = Container;

Container.Text = Text;
