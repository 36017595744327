import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { editedMessageActions } from '../reducers/editedMessage';
import { logError } from '../../helper/sentry';

export default function* editingMessage(action) {
  if (!action || !action.payload) return;
  try {
    const { id, text } = action.payload;
    yield put(editedMessageActions.setVisibility(true));
    yield put(editedMessageActions.setId(id));
    yield put(editedMessageActions.setText(text));
  } catch (error) {
    yield call(toast.error, `editingMessages-saga: ${error.message}`);
    logError(error);
  }
}
