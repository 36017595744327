import { createSlice } from '@reduxjs/toolkit';

const storeName = 'wiretapper';

const initialState = {
  state: {},
};

export const wiretapperSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      const { clientId, active } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.state = { ...state.state, [clientId]: active };
    },
  },
});

export const wiretapperActions = {
  setState: wiretapperSlice.actions.setState,
};

export const wiretapperReducer = wiretapperSlice.reducer;
