import styled from 'styled-components';

export const Wrapper = styled.li``;

export const TimeDivider = styled.span`
  display: flex;
  justify-content: center;
  color: #79838A;
  font-size: 1em;
  font-weight: 600;
  margin: 10px;
`;

export const Expiration = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding: 7px 0;
  word-break: break-word;
  align-items: center;
  color: #81a1d0;
  font-size: 12px;
  width: 100%;
  white-space: nowrap;
`;

export const Line = styled.div`
  height: 1px;
  background: #81a1d0;
  width: 100%;
  margin: 0 5px;
`;

Wrapper.TimeDivider = TimeDivider;
Wrapper.Expiration = Expiration;

Expiration.Line = Line;
