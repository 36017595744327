/* eslint-disable no-param-reassign */
import { put, call, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import _ from 'lodash';
import config from '../../config';
import { statisticActions } from '../reducers/statistics';
import { getStatisticsFilters } from '../selectors/statistics';
import managerInfoSelectors from '../selectors/managerInfo';
import { logError } from '../../helper/sentry';

const keysToSum = ['total_calls', 'answered_calls', 'answered_15s', 'answered_60s', 'answered_90s', 'missed_calls', 'decline_calls', 'total_calls_duration', 'unique_clients'];
export default function* getStatistics() {
  try {
    const managerID = Cookies.get('manager') || '';
    // const authChat = Cookies.get('AUTHCHAT') || '';
    const filters = yield select(getStatisticsFilters);
    const manager_id = yield select(managerInfoSelectors.getManagerId);

    const params = {
      managerId: filters.manager_id || manager_id || managerID,
      fromPeriod: filters.from?.toISOString(),
      toPeriod: filters.to?.toISOString(),
    };
    const resp = yield call(fetch,
      `${config.backendManagerChatApi}/statistics?${new URLSearchParams(_.pickBy(params, _.identity))}`, {
        method: 'GET',
        headers: {
          // AUTHCHAT: authChat,
          // Authorization: 'Basic ',
        },
      });
    const responseBody = yield resp.json();
    const value = { ...responseBody };
    const func = (obj) => {
      if (obj.sub_managers) {
        obj.sub_managers.forEach((k) => func(k));
        obj.sub_managers.forEach((man) => {
          keysToSum.forEach((k) => {
            obj[`sum_${k}`] = obj[`sum_${k}`] ? obj[`sum_${k}`] + man[`sum_${k}`] : man[`sum_${k}`];
          });
        });
      }
      keysToSum.forEach((k) => {
        obj[`sum_${k}`] = obj[`sum_${k}`] ? obj[`sum_${k}`] + obj[k] : obj[k];
      });
    };
    func(value);
    yield put(statisticActions.setStatistic(value));
  } catch (e) {
    yield call(toast.error, `getStatistics-saga: ${e.message}`);
    logError(e);
  }
}
