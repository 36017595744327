import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdChevronLeft,
  // MdVoicemail,
  MdLanguage,
} from 'react-icons/md';
import { IconContext } from 'react-icons';
import PropTypes from 'prop-types';
import Call from './Call';
import { RoomType } from '../../../types';
// import WireTapper from './WireTapper';
import * as wireTapper from '../../../infra/webrtc/wireTapper';
import microphoneStatusSelectors from '../../../redux/selectors/microphoneStatus';
import { roomListActions } from '../../../redux/reducers/roomList';
import preloaderSelectors from '../../../redux/selectors/preloader';
import roomIdWithActiveCallSelectors from '../../../redux/selectors/roomIdWithActiveCall';
import offlineCallStatusSelectors from '../../../redux/selectors/offlineCallStatus';
import callStatusSelectors from '../../../redux/selectors/callStatus';
import wiretapperSelectors from '../../../redux/selectors/wiretapper';
import { recordingsActions } from '../../../redux/reducers/recordings';
import recordingsSelectors from '../../../redux/selectors/recordings';
import { roomsActions } from '../../../redux/reducers/rooms';
import roomsSelectors from '../../../redux/selectors/rooms';
import { Wrapper, ButtonBack, Info, Content, Description, Container/* , Recording*/ } from './styled';
import NameDisplay from './NameDisplay';

const Header = ({ roomId, room }) => {
  // const showRecordings = useSelector(recordingsSelectors.getShow);
  const { client_id, client_name, client_lang, client_status } = room;
  const callStatus = useSelector(callStatusSelectors.getState);
  const canUseWiretapping = useSelector(wiretapperSelectors(client_id));
  const offlineCallIsActive = useSelector(offlineCallStatusSelectors.getState);
  const roomIdWithActiveCall = useSelector(roomIdWithActiveCallSelectors.getRoomIdWithActiveCall);
  const preloaderStatus = useSelector(preloaderSelectors.getPreloader);
  const recordingState = useSelector(recordingsSelectors.getShow);
  const microphoneStatus = useSelector(microphoneStatusSelectors.getMicrophoneStatus);
  const rooms = useSelector(roomsSelectors.getRooms);
  const dispatch = useDispatch();

  const unreadMsgCount = () => {
    const reducer = (previousValue, currentValue) => previousValue + currentValue;
    const count = Object.keys(rooms).map((item) => rooms[item].unread_msg_count).reduce(reducer);
    return count;
  };

  const dispatchSetActiveRoom = () => {
    dispatch(roomsActions.setActiveRoom(''));
  };

  const showRec = () => {
    dispatch(recordingsActions.setShow({
      leadId: client_id,
    }));
  };

  const handleBackBtn = () => {
    dispatchSetActiveRoom();
    dispatch(roomListActions.setState(true));
  };

  useEffect(() => {
    if (canUseWiretapping === false) wireTapper.hangUp();
  }, [canUseWiretapping]);

  useEffect(() => {
    if (!room.read_only && recordingState) showRec();
  }, [room]);

  const title = client_name;
  const subtitle = room.read_only ? 'Read Only Chat' : 'Chat for communication';
  const lang = client_lang.charAt(0).toUpperCase() + client_lang.slice(1);

  return (
    <Wrapper>
      <Wrapper.ButtonBack onClick={handleBackBtn}>
        <MdChevronLeft />
        {unreadMsgCount() > 0 && <ButtonBack.Unread />}
      </Wrapper.ButtonBack>
      <Wrapper.Info title={roomId}>
        <Info.Content>
          <Content.Name>
            <NameDisplay clientId={client_id} title={title} />
          </Content.Name>
          <Content.Description>
            {Boolean(client_status) && <Description.Status />}
            <Description.Container>
              <Container.SubTitle>{subtitle}</Container.SubTitle>
              <MdLanguage />
              <Container.Lang>{lang}</Container.Lang>
            </Description.Container>
          </Content.Description>
        </Info.Content>
      </Wrapper.Info>
      <IconContext.Provider value={{ size: '2em' }}>
        {/* {canUseWiretapping && <WireTapper clientId={client_id} />}*/}
        {(!room.read_only && room.call_permission !== 'not_allowed') && (
          <Call
            clientId={client_id}
            roomId={roomId}
            clientStatus={client_status}
            callStatus={callStatus}
            offlineCallIsActive={offlineCallIsActive}
            roomIdWithActiveCall={roomIdWithActiveCall}
            preloaderStatus={preloaderStatus}
            microphoneStatus={microphoneStatus}
            callPermission={room.call_permission}
          />
        )}
        {/* {room.read_only && (*/}
        {/*  <Recording visibility={showRecordings} onClick={showRec}>*/}
        {/*    <MdVoicemail />*/}
        {/*  </Recording>*/}
        {/* )}*/}
      </IconContext.Provider>

      {/* {isFramed ? ( */}
      {/*  <> */}
      {/*    <Wrapper.Desktop> */}
      {/*      <IconContext.Provider value={{ size: '2em' }}> */}
      {/*        {canUseWiretapping && <WireTapper clientId={client_id} />} */}
      {/*        {!room.read_only */}
      {/*            && ( */}
      {/*              <Call */}
      {/*                clientId={client_id} */}
      {/*                roomId={roomId} */}
      {/*                clientStatus={client_status} */}
      {/*                callStatus={callStatus} */}
      {/*                offlineCallIsActive={offlineCallIsActive} */}
      {/*                roomIdWithActiveCall={roomIdWithActiveCall} */}
      {/*                preloaderStatus={preloaderStatus} */}
      {/*                microphoneStatus={microphoneStatus} */}
      {/*              /> */}
      {/*            )} */}
      {/*        {room.read_only && <div className={recClass} onClick={showRec}><MdVoicemail /></div>} */}
      {/*      </IconContext.Provider> */}
      {/*    </Wrapper.Desktop> */}
      {/*    <div className="view-full-version-mobile"> */}
      {/*      <div className="exit-box-mobile" onClick={onClickGetViewFullVersion}> */}
      {/*        <div className="view-full-version">View full version</div> */}
      {/*      </div> */}
      {/*      <div> */}
      {/*        <IconContext.Provider value={{ size: '2em' }}> */}
      {/*          {canUseWiretapping && <WireTapper clientId={client_id} />} */}
      {/*          {!room.read_only */}
      {/*              && ( */}
      {/*                <Call */}
      {/*                  clientId={client_id} */}
      {/*                  roomId={roomId} */}
      {/*                  clientStatus={client_status} */}
      {/*                  callStatus={callStatus} */}
      {/*                  offlineCallIsActive={offlineCallIsActive} */}
      {/*                  roomIdWithActiveCall={roomIdWithActiveCall} */}
      {/*                  preloaderStatus={preloaderStatus} */}
      {/*                  microphoneStatus={microphoneStatus} */}
      {/*                /> */}
      {/*              )} */}
      {/*          {room.read_only && <div className={recClass} onClick={showRec}><MdVoicemail /></div>} */}
      {/*        </IconContext.Provider> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </> */}
      {/* ) : ( */}
      {/*  <IconContext.Provider value={{ size: '2em' }}> */}
      {/*    {canUseWiretapping && <WireTapper clientId={client_id} />} */}
      {/*    {!room.read_only */}
      {/*          && ( */}
      {/*            <Call */}
      {/*              clientId={client_id} */}
      {/*              roomId={roomId} */}
      {/*              clientStatus={client_status} */}
      {/*              callStatus={callStatus} */}
      {/*              offlineCallIsActive={offlineCallIsActive} */}
      {/*              roomIdWithActiveCall={roomIdWithActiveCall} */}
      {/*              preloaderStatus={preloaderStatus} */}
      {/*              microphoneStatus={microphoneStatus} */}
      {/*            /> */}
      {/*          )} */}
      {/*    {room.read_only && <div className={recClass} onClick={showRec}><MdVoicemail /></div>} */}
      {/*  </IconContext.Provider> */}
      {/* )} */}
    </Wrapper>
  );
};

Header.propTypes = {
  room: RoomType.isRequired,
  roomId: PropTypes.string.isRequired,
};

export default Header;
