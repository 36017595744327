/* eslint-disable */
export default {
  debuglog: window?.cfg?.DEBUG_LOG || process.env.DEBUG_LOG,
  backendWebSocket: window?.cfg?.BACKEND_WEBSOCKET || process.env.BACKEND_WEBSOCKET,
  backendManagerChatApi: window?.cfg?.BACKEND_API || process.env.BACKEND_API,
  backendRequestTimeout: window.cfg?.WS_REQ_TIME ? window.cfg.WS_REQ_TIME : 20000, /* ws response timeout  */
  // backendRegisterNewToken: window?.cfg?.REGISTER_NEW_TOKEN || process.env.REGISTER_NEW_TOKEN,
  userSearchMaxResultCount: 20, /* limited count users in request */
  userSearchInputDelay: 300, /* Delay before showing user list */
  scrollbarHideTimeout: 750, /* Scrollbars setting */
  scrollbarHideDuration: 150, /* Scrollbars setting */
  messageHistoryQueryCount: 50, /* number of requested messages */
  systemUsername: 'ede8c0ee-a08d-45fb-bb69-000bcf070c9e',
  sendingSymbolsCount: 2000,
  wsPingWait: 120, /*ping wait time in seconds (max - 120)*/
  wsPingMsg: '.', /*max - 4 byte*/
  wsPingInterval: 5000, /* time in milliseconds min - 1 */
  wsReconnectDelay: 10, /*reconnect delay in seconds, min - 1 */
  maxFileSize: 5242880, /*max file size in bytes.*/
  switchToLength: 100, /*change the maximum number of characters for an input in a modal window 'Switch to...'*/
  nodeEnv: window?.cfg?.NODE_ENV || process.env.NODE_ENV,
  offlineCallTimerMillSec: process.env.OFFLINE_CALL_TIMER || 60000,
  baseFileStorageURL: window?.cfg?.BASE_FILE_STORAGE_URL || process.env.BASE_FILE_STORAGE_URL,
  numberOfRoomsRequested: 20,
  // firebaseConfig: {
  //   apiKey: window?.cfg?.FB_API_KEY || process.env.FB_API_KEY,
  //   authDomain: window?.cfg?.FB_AUTH_DOMAIN || process.env.FB_AUTH_DOMAIN,
  //   projectId: window?.cfg?.FB_PROJECT_ID || process.env.FB_PROJECT_ID,
  //   storageBucket: window?.cfg?.FB_STORAGE_BUCKET || process.env.FB_STORAGE_BUCKET,
  //   messagingSenderId: window?.cfg?.FB_MESSAGING_SENDER_ID || process.env.FB_MESSAGING_SENDER_ID,
  //   appId: window?.cfg?.FB_APP_ID || process.env.FB_APP_ID,
  //   measurementId: window?.cfg?.FB_MEASUREMENT_ID || process.env.FB_MEASUREMENT_ID
  // }
};
