import styled from 'styled-components';

export const List = styled.ul`
  --spacing: 1.5rem;
  --radius: 10px;
  padding-left: 0px;
  margin: 0px;
  width: 100%;

  & li{
    display: block;
    position: relative;
    padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
  }
  & ul{
    margin-left: calc(var(--radius) - var(--spacing));
    padding-left: 0;
  }
  & ul li{
    border-left: 2px solid #ddd;
    summary{
      border-bottom: none;
    }
  }
  & ul li:last-child{
    border-bottom: none;
  }
  & ul li::before{
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--spacing) / -2);
    left: -2px;
    width: calc(var(--spacing) + 2px);
    height: calc(var(--spacing) + 1px);
    border: solid #ddd;
    border-width: 0 0 2px 2px;
  }
  & summary{
    cursor  : pointer;
  }
  & summary::marker,
  & summary::-webkit-details-marker{
    display : none;
  }
  
  & summary:focus{
    outline : none;
  }
  
  & summary:focus-visible{
    outline : 1px dotted #000;
  }
  & li::after,
  & summary::before{
    content       : '';
    display       : block;
    position      : absolute;
    top           : calc(var(--spacing) / 2 - var(--radius));
    left          : calc(var(--spacing) - var(--radius) - 1px);
    width         : calc(2 * var(--radius));
    height        : calc(2 * var(--radius));
    border-radius : 50%;
    background    : #ddd;
  }
  & summary::before{
    content     : '+';
    z-index     : 1;
    background  : #696;
    color       : #fff;
    // line-height : calc(2 * var(--radius) - 2px);
    text-align  : center;
  }
  & details{
    width: 100%;
  }
  & details > summary{
    border-bottom: none;
  }
  & details[open] > summary{
    border-bottom: 1px solid #202933;
  }
  & details[open] > summary::before{
    content : '−';
  }
  .odd{
    background: #74C6F3AA;
  }
  .even{
    background: #eeeeee;
  }
}
`;
export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 3%;
  border: 1px solid #202933;
  border-radius: 5px;
  overflow: hidden;
  > * {
    &:last-child {
      border-bottom: none;
    }
  }
`;
export const STBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding: ${({ padding }) => (padding)};
`;
