import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import config from '../../../../config';
import { Wrapper, Window, Header, Body, Footer } from './styled';

const ExitModalWindow = ({ changeStatePopUp }) => {
  const logOut = () => {
    Cookies.remove('manager');
    localStorage.removeItem('settings');
    localStorage.removeItem('activePage');
    fetch(`${config.backendManagerChatApi}/logout`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    }).then((r) => {
      if (r.status === 200) {
        window.location.href = '/';
      } else {
        console.log('logout response status: ', r.status);
      }
    });
  };

  return (
    <Wrapper>
      <Wrapper.Window>
        <Window.Header>
          <Header.Title>Exit</Header.Title>
        </Window.Header>
        <Window.Body>
          <Body.SubTitle>Are you sure you want to exit?</Body.SubTitle>
        </Window.Body>
        <Window.Footer>
          <Footer.ButtonClose onClick={changeStatePopUp}>No</Footer.ButtonClose>
          <Footer.ButtonExit onClick={logOut}>Yes</Footer.ButtonExit>
        </Window.Footer>
      </Wrapper.Window>
    </Wrapper>
  );
};

export default ExitModalWindow;

ExitModalWindow.propTypes = {
  changeStatePopUp: PropTypes.func.isRequired,
};
