import { createSlice } from '@reduxjs/toolkit';

const storeName = 'callStatus';

const initialState = {
  state: 'STAND_BY',
};

export const callStatusSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const callStatusActions = {
  setState: callStatusSlice.actions.setState,
};

export const callStatusReducer = callStatusSlice.reducer;
