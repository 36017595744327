import { createSlice } from '@reduxjs/toolkit';

const storeName = 'messages';

const initialState = {
  state: {},
};

export const emptyRoom = {
  messages: [],
  historyEndReached: false,
  unreadEndReached: false,
  chatScrollPosition: {
    scrollTop: 0,
    top: 0,
  },
  initialHistoryFetched: false,
};

export const messagesSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setMessages(state, action) {
      const { roomId, messages: newMessages, room_id, expirationTime, rooms } = action.payload;
      const activeRoomId = roomId || room_id;
      const room = rooms[activeRoomId] || { ...emptyRoom };
      const { messages: oldMessages = [], historyEndReached } = room;
      const oldMessageIds = oldMessages.map((oldMessage) => oldMessage.id);
      const filteredMessages = newMessages
        .filter((newMessage) => !oldMessageIds.includes(newMessage.id));
      // eslint-disable-next-line no-param-reassign
      state.state = {
        ...rooms,
        [activeRoomId]: {
          ...room,
          messages: [...oldMessages, ...filteredMessages],
          historyEndReached,
          expirationTime: rooms[activeRoomId]?.expirationTime || expirationTime,
        },
      };
    },
    setRoomUnreadMessages(state, action) {
      const rooms = state.state;
      const { roomId, messages: newMessages } = action.payload;
      const room = rooms[roomId] || { ...emptyRoom };
      const { messages: oldMessages = [], historyEndReached } = room;
      const oldMessageIds = oldMessages.map((oldMessage) => oldMessage.id);
      const filteredMessages = newMessages
        .filter((newMessage) => !oldMessageIds.includes(newMessage.id));
      // eslint-disable-next-line no-param-reassign
      state.state = {
        ...rooms,
        [roomId]: {
          ...room,
          messages: [...filteredMessages, ...oldMessages],
          historyEndReached,
        },
      };
    },
    setRoomScroll(state, action) {
      const rooms = state.state;
      const { chatScrollPosition, roomId } = action.payload;
      const room = state.state[roomId] || { ...emptyRoom };
      // eslint-disable-next-line no-param-reassign
      state.state = {
        ...rooms,
        [roomId]: {
          ...room,
          chatScrollPosition,
        },
      };
    },
    setSendMessage(state, action) {
      const rooms = state.state;
      const { id, sender, room_id, text, time, type, client_id, status, quoted_msg } = action.payload;
      const room = rooms[room_id] || { ...emptyRoom };
      const { messages: oldMessages = [] } = room;
      room.messages = [{ sender, text, time, id, client_id, status, type, quoted_msg }, ...oldMessages];
      // eslint-disable-next-line no-param-reassign
      state.state = {
        ...rooms,
        [room_id]: { ...room,
          chatScrollPosition: {
            ...room.chatScrollPosition,
            top: 1,
          },
        },
      };
    },
    setSendedMessage(state, action) {
      const rooms = state.state;
      const { id, sender, room_id, text, time, type, client_id, status, quoted_msg } = action.payload;
      const room = rooms[room_id] || { ...emptyRoom };
      room.messages = room.messages.map((msg) => {
        if (msg.time === time) {
          return { sender, text, time, id, client_id, status, type, quoted_msg };
        }
        return msg;
      });
      // eslint-disable-next-line no-param-reassign
      state.state = {
        ...rooms,
        [room_id]: { ...room,
          chatScrollPosition: {
            ...room.chatScrollPosition,
            top: 1,
          },
        },
      };
    },
    setMessageReceived(state, action) {
      const rooms = state.state;
      const { id, sender, room_id, text, time, client_id, type, status, quoted_msg } = action.payload;
      const room = rooms[room_id] || { ...emptyRoom };
      const { messages: oldMessages = [] } = room;
      // eslint-disable-next-line no-param-reassign
      state.state = { ...rooms,
        [room_id]: {
          ...room,
          messages: [{
            sender, text, time, id, client_id, type, status, quoted_msg,
          }, ...oldMessages],
        },
      };
    },
    setHistoryEndReached(state, action) {
      const rooms = state.state;
      const { roomId } = action.payload;
      const room = rooms[roomId] || { ...emptyRoom };
      let { historyEndReached } = room;
      historyEndReached = true;
      // eslint-disable-next-line no-param-reassign
      state.state = { ...rooms, [roomId]: { ...room, historyEndReached },
      };
    },
    setUnreadEndReached(state, action) {
      const rooms = state.state;
      const { roomId } = action.payload;
      const room = rooms[roomId] || { ...emptyRoom };
      let { unreadEndReached } = room;
      unreadEndReached = true;
      // eslint-disable-next-line no-param-reassign
      state.state = { ...rooms, [roomId]: { ...room, unreadEndReached } };
    },
    setInitialHistoryFetched(state, action) {
      const rooms = state.state;
      const { roomId, initialHistoryFetched } = action.payload;
      const room = rooms[roomId] || { ...emptyRoom };
      // eslint-disable-next-line no-param-reassign
      state.state = { ...rooms, [roomId]: { ...room, initialHistoryFetched } };
    },
    setUpdateRoomMessage(state, action) {
      const rooms = state.state;
      const { roomId, updatedMsg } = action.payload;
      const room = rooms[roomId] || { ...emptyRoom };
      const updateMessages = room.messages.map((msg) => (msg.id === updatedMsg.id ? updatedMsg : msg));
      // eslint-disable-next-line no-param-reassign
      state.state = { ...rooms, [roomId]: { ...room, messages: updateMessages } };
    },
    setMessagesStore(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const messagesActions = {
  setMessages: messagesSlice.actions.setMessages,
  setRoomUnreadMessages: messagesSlice.actions.setRoomUnreadMessages,
  setRoomScroll: messagesSlice.actions.setRoomScroll,
  setSendMessage: messagesSlice.actions.setSendMessage,
  setSendedMessage: messagesSlice.actions.setSendedMessage,
  setMessageReceived: messagesSlice.actions.setMessageReceived,
  setHistoryEndReached: messagesSlice.actions.setHistoryEndReached,
  setUnreadEndReached: messagesSlice.actions.setUnreadEndReached,
  setInitialHistoryFetched: messagesSlice.actions.setInitialHistoryFetched,
  setUpdateRoomMessage: messagesSlice.actions.setUpdateRoomMessage,
  setMessagesStore: messagesSlice.actions.setMessagesStore,
};

export const messagesReducer = messagesSlice.reducer;
