import styled from 'styled-components';

export const Wrapper = styled.li`
  position: relative;
  cursor: pointer;
  color: #fff;
  padding: 15px 10px 10px 10px;
  background: ${({ active }) => (active ? '#2b3643' : 'none')};
  
  &:hover {
    background: #354250;
    color: white;
    transition: .25s;
  }

  @media (max-width:480px) {
    &:hover {
      transition: none;
    }
  }
`;

export const User = styled.div``;

export const Icon = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
`;

export const Status = styled.div`
  ${({ online }) => (online && `
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: #4A9D45;
  `)}
`;

export const CountMsg = styled.div`
  position: absolute;
  left: 30px;
  top: 37px;
  display: flex;
  color: #293143;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border: 1px solid #79838A;
  border-radius: 50%;
  align-items: center;
  margin-left: 5px;
  background-color: #cacaca;
  font-weight: 900;
  justify-content: center;
`;

export const Info = styled.div`
  margin-top: -48px;
  padding-left: 40px;
`;

export const Details = styled.div`
  display: flex;
  color: #79838A;
  font-size: 12px;
  align-items: center;
  padding: 5px 10px;
`;

export const Name = styled.div`
  flex: 1;
  font-size: 14px;
  width: 20px;
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActiveCall = styled.span`
  position: relative;
`;

export const Image = styled.img`
  position: absolute;
  top: -1px;
  left: 5px;
`;

export const LastMessage = styled.div`
  display: flex;
  color: #79838A;
  font-size: 12px;
  align-items: center;
  padding: 5px 10px 0 10px;
`;

export const Text = styled.div`
  font-size: 12px;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 4px 0;
`;

export const Time = styled.div`
  display: flex;
  padding: 0 0 0 5px;
`;

export const Client = styled.span`
  display: grid;
  grid-template-columns: 10fr 2fr;
  gap: 5px;
`;

export const Content = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Span = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CopyButton = styled.span`
  margin-right: 3px;
  
  @media  (max-width:480px)  {
    display: none;
  }
`;

export const IconsBlock = styled.div`
  display: flex;
  gap: 8px;
`;

Wrapper.User = User;

User.Icon = Icon;
User.Info = Info;

Icon.Status = Status;
Icon.CountMsg = CountMsg;

Info.Details = Details;
Info.LastMessage = LastMessage;

Details.Name = Name;

Name.ActiveCall = ActiveCall;

ActiveCall.Image = Image;

LastMessage.Text = Text;
LastMessage.Time = Time;

Client.Span = Span;
Client.CopyButton = CopyButton;
Client.IconsBlock = IconsBlock;
Client.Content = Content;
