import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Wrapper, Expiration } from './styled';
import DefaultMessage from './DefaultMessage';
import SystemMessage from './SystemMessage';
import ImageMessage from './ImageMessage';
import FileMessage from './FileMessage';
import messagesSelectors from '../../../../redux/selectors/messages';

const MessageContainer = (props) => {
  const allRoomsMessages = useSelector(messagesSelectors.getStore);
  const { message: { type, time }, timeDivider, room } = props;
  const { id: roomId } = room;

  const { expirationTime } = allRoomsMessages[roomId];

  const isAfterExpirationTime = moment(expirationTime) > moment(time);

  const typeMessageSwitch = () => {
    switch (type) {
    case 0:
    case 4:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <DefaultMessage {...props} expirationTime={isAfterExpirationTime} />;
    // eslint-disable-next-line react/jsx-props-no-spreading
    case 1: return <FileMessage {...props} expirationTime={isAfterExpirationTime} />;
      // eslint-disable-next-line react/jsx-props-no-spreading
    case 2: return <ImageMessage {...props} expirationTime={isAfterExpirationTime} />;
      // eslint-disable-next-line react/jsx-props-no-spreading
    case 3: return <SystemMessage {...props} />;
      // eslint-disable-next-line react/jsx-props-no-spreading
    default: return null;
    }
  };

  return (
    <>
      {(timeDivider !== '') && (
        <Wrapper>
          <Wrapper.TimeDivider>{timeDivider}</Wrapper.TimeDivider>
        </Wrapper>
      )}
      {isAfterExpirationTime && (
        <Wrapper className="system_message_item expiration">
          <Wrapper.Expiration>
            <Expiration.Line />
            Message with time expiration
            <Expiration.Line />
          </Wrapper.Expiration>
        </Wrapper>
      )}
      {typeMessageSwitch()}
    </>
  );
};

export default MessageContainer;
