import styled from 'styled-components';
import { MdRefresh } from 'react-icons/md';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: .75em 0 .75em 0;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
`;

export const Icon = styled(MdRefresh)`
  height: 30px;
  width: 100%;
`;

export const Text = styled.p`
  font-size: 10px;
  margin: 0;
`;

Wrapper.Icon = Icon;
Wrapper.Text = Text;
