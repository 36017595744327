import styled from 'styled-components';

const Wrapper = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #4d565f;
`;

export default Wrapper;
