import { call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setManagerSettings } from '../../infra/api/ChatAPI';
import settingsSelectors from '../selectors/settings';
import { logError } from '../../helper/sentry';

export default function* sendManagerSettings() {
  try {
    const settings = yield select(settingsSelectors.getSettings);
    yield call(setManagerSettings, JSON.stringify(settings));
  } catch (error) {
    yield call(toast.error, `sendManagerSettings-saga: ${error.message}`);
    logError(error);
  }
}
