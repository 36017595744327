export default {
  typeYourMessage: {
    en: 'Type your message...',
    ru: 'Введите свое сообщение...',
    es: 'Escriba su mensaje ...',
  },
  searchByID: {
    en: 'Search by ID',
    ru: 'Поиск по ID',
    es: 'Buscar por ID',
  },
  sortByActivity: {
    en: 'Sort by activity',
    ru: 'Сортировать по активности',
    es: 'Ordenar por actividad',
  },
  filter: {
    en: 'Filter',
    ru: 'Фильтр',
    es: 'Filtro',
  },
  statuses: {
    en: 'Statuses',
    ru: 'Статусы',
    es: 'Estados',
  },
  tags: {
    en: 'Tags',
    ru: 'Теги',
    es: 'Etiquetas',
  },
  languages: {
    en: 'Languages',
    ru: 'Языки',
    es: 'Idiomas',
  },
  startDialog: {
    en: 'Start',
    ru: 'Начать',
    es: 'Iniciar',
  },
  finishDialog: {
    en: 'Finish',
    ru: 'Завершить',
    es: 'Terminar',
  },
  confirmationHeader: {
    en: 'Confirmation',
    ru: 'Подтверждение',
    es: 'Confirmación',
  },
  warningHeader: {
    en: 'Warning',
    ru: 'Предупреждение',
    es: 'Аdvertencia',
  },
  startDialogMsg: {
    en: 'Are you sure you want to start the dialog?',
    ru: 'Вы уверены, что хотите начать диалог?',
    es: '¿Usted está seguro de que quieres iniciar un diálogo?',
  },
  finishDialogMsg: {
    en: 'Are you sure you want to finish the dialog?',
    ru: 'Вы уверены, что хотите завершить диалог?',
    es: '¿Usted está seguro de que quieres terminar el diálogo?',
  },
  cancel: {
    en: 'Cancel',
    ru: 'Отмена',
    es: 'Cancelar',
  },
  exit: {
    en: 'Logout',
    ru: 'Выйти',
    es: 'Cerrar sesión',
  },
  selectDialogMsg: {
    en: 'Select a dialog to view it conversation.',
    ru: 'Выберите диалог, чтобы посмотреть его сообщения.',
    es: 'Seleccione un diálogo para ver los mensajes.',
  },
  withOutRoom: {
    en: 'The dialog will automatically close after 5 seconds.',
    ru: 'Диалог будет автоматически закрыт спустя 5 секунд.',
    es: 'El cuadro de diálogo se cerrará automáticamente después de 5 segundos.',
  },
  noRoomsMsg: {
    en: 'There is not any room yet.',
    ru: 'Еще нет ни одной комнаты.',
    es: 'No hay espacio todavía.',
  },
  numberOfAppeals: {
    en: 'User calls to AskMe',
    ru: 'Обращения пользователя к AskMe',
    es: 'El usuario llama a AskMe',
  },
  dialogAssigningWarning: {
    en: 'Please pay attention that the client has not chosen a tag yet.\n  '
        + 'You will be able to start conversation in',
    ru: 'Обратите внимание, что клиент все еще еще не выбрал тег.\n  '
        + 'Вы можете начать диалог через',
    es: 'Tenga en cuenta que el cliente aún no ha seleccionado la etiqueta.\n  '
        + 'Puedes iniciar un diálogo a través de',
  },
  noMessages: {
    en: 'no messages',
    ru: 'нет сообщений',
    es: 'ningún mensaje',
  },
};
