import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BiCopy } from 'react-icons/all';
import { FaComment, FaCommentSlash, FaPhone, FaPhoneSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import getEmojiWrap from '../../../../helper/emoji';
import { RoomType } from '../../../../types';
import InitialsAvatar from './InitialsAvatar';
import svcLang from '../../../../locales/svcMessages';
import { splitFileNameAndUrl } from '../../../../helper/links';
import { formatByDate } from '../../../../helper/date';
import roomIdWithActiveCallSelectors from '../../../../redux/selectors/roomIdWithActiveCall';
import phoneSvg from '../../../../images/phone.svg';
import { Wrapper, User, Icon, Info, Details, Name, ActiveCall, LastMessage, Client } from './styled';
import roomsSelectors from '../../../../redux/selectors/rooms';

const msgTypes = {
  Text: 0,
  File: 1,
  Image: 2,
  Svc: 3,
  Edit: 4,
};

const svcTypes = {
  1: 'SvcCallStarted',
  2: 'SvcCallEnded',
  3: 'SvcCallRejected',
  4: 'SvcCallMissed',
  5: 'SvcSwitchTo',
};

// eslint-disable-next-line no-unused-vars
const UserRoomItem = ({ room, roomId, setActiveRoom }) => {
  const { latest_msg, client_status, client_id, client_name, unread_msg_count, client_lang } = room;
  let lastsMsgText = 'no messages';
  let latestTimeDisplay = '';
  const activeRoomId = useSelector(roomsSelectors.getActiveRoomId);
  const roomIdWithActiveCall = useSelector(roomIdWithActiveCallSelectors.getRoomIdWithActiveCall);
  const unreadCountDisplay = (unread_msg_count > 999) ? `${Math.floor(unread_msg_count / 1000)}k` : unread_msg_count;

  let phoneIcon;
  let chatIcon;

  if (room.call_permission === 'allowed') {
    phoneIcon = <FaPhone color={'#58a84d'} />;
  } else if (room.call_permission === 'not_allowed') {
    phoneIcon = <FaPhoneSlash color={'#fc7676'} />;
  } else if (room.call_permission === 'unknown') {
    phoneIcon = <FaPhone />;
  }

  if (room.chat_permission === 'allowed') {
    chatIcon = <FaComment color={'#58a84d'} />;
  } else if (room.chat_permission === 'not_allowed') {
    chatIcon = <FaCommentSlash color={'#fc7676'} />;
  } else if (room.chat_permission === 'unknown') {
    chatIcon = <FaComment />;
  }

  const nameDisplay = () => (
    <Client>
      <Client.Content>
        <Client.Span>{`(${client_id} `}</Client.Span>
        <Client.CopyButton>
          <BiCopy color="#74c6f3" onClick={onCopyId} />
        </Client.CopyButton>
        <Client.Span title={client_name}>{`) ${client_name}`}</Client.Span>
      </Client.Content>

      <Client.IconsBlock>
        {phoneIcon}
        {chatIcon}
      </Client.IconsBlock>
    </Client>
  );

  const returnSystemMsg = (msg) => {
    switch (msg.text.split(';')[0]) {
    case '1':
    case '2':
    case '3':
    case '4':
      return svcLang[svcTypes[latest_msg.text]][client_lang];
    case '5':
      return `${latest_msg.text.split(';')[1]} ${svcLang[svcTypes['5']][client_lang]}`;
    default:
      return msg.text;
    }
  };

  const onCopyId = (e) => {
    navigator.clipboard.writeText(client_id);
    toast('ID copied.');
    e.stopPropagation();
  };

  if (latest_msg?.time) {
    switch (latest_msg.type) {
    case msgTypes.Text:
      lastsMsgText = latest_msg.text;
      break;
    case msgTypes.File:
    case msgTypes.Image:
      lastsMsgText = splitFileNameAndUrl(latest_msg.text).name;
      break;
    case msgTypes.Svc:
      lastsMsgText = returnSystemMsg(latest_msg);
      break;
    case msgTypes.Edit:
      lastsMsgText = latest_msg.text;
      break;
    default:
      break;
    }

    const timeDate = formatByDate(room.latest_msg.time);
    latestTimeDisplay = `${timeDate}`;
  }

  return (
    <Wrapper active={roomId === activeRoomId}>
      <Wrapper.User onClick={() => setActiveRoom(roomId)}>
        <User.Icon>
          <InitialsAvatar name={client_name} />
          <Icon.Status online={client_status} />
          {(unread_msg_count !== 0) && <Icon.CountMsg>{unreadCountDisplay}</Icon.CountMsg>}
        </User.Icon>
        <User.Info>
          <Info.Details>
            <Details.Name>
              {nameDisplay()}
              {roomId === roomIdWithActiveCall && (
                <Name.ActiveCall>
                  <ActiveCall.Image src={phoneSvg} alt="phone" />
                </Name.ActiveCall>
              )}
            </Details.Name>
          </Info.Details>
          <Info.LastMessage>
            <LastMessage.Text>{getEmojiWrap(lastsMsgText)}</LastMessage.Text>
            <LastMessage.Time>{latestTimeDisplay}</LastMessage.Time>
          </Info.LastMessage>
        </User.Info>
      </Wrapper.User>
    </Wrapper>
  );
};

UserRoomItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // intl: PropTypes.any.isRequired,
  room: RoomType.isRequired,
  roomId: PropTypes.string.isRequired,
  setActiveRoom: PropTypes.func.isRequired,
};

export default UserRoomItem;
