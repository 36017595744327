import styled from 'styled-components';

export const HeadersContainer = styled.div`
  display: flex;
`;

export const HeaderCell = styled.div`
    width: ${({ width }) => (width || '200px')};
    border-bottom: 1px solid #202933;
    overflow:hidden;
    ${({ withoutAlign, right }) => (!withoutAlign && `
      display: flex;
      justify-content: ${right ? 'flex-end' : 'center'}; 
      align-items: center;
    `)};
    ${({ right }) => (right && `
      padding-right: 10px;
    `)};
`;

export const AnsweredHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AnsweredHeaderText = styled.div`
  align-self: center;
`;
export const AnsweredHeaderContainers = styled.div`
  display: flex;
  justify-content: space-around;

  & div{
    width: 25%;
    text-align: center;
  }
`;
