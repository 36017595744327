import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  width: 35%;
  justify-content:space-between;
  margin: 0 3%;
  padding: 10px 0;
`;
export const DatePickerContainer = styled.div`
  .react-datepicker-popper{
    z-index: 2;
  }
`;
