import { createSlice } from '@reduxjs/toolkit';

const storeName = 'preloader';

const initialState = {
  state: false,
};

export const preloaderSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const preloaderActions = {
  setState: preloaderSlice.actions.setState,
};

export const preloaderReducer = preloaderSlice.reducer;
