import styled from 'styled-components';
import { ContextMenu, MenuItem } from 'react-contextmenu';

const isMyMsgIconStyle = `
  margin-left: 5px;
  margin-top: 3px;
  color: #737373;
`;

const isClientMsgIconStyle = `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 5px;
`;

const isClientDeleteMsgIconStyle = `
  margin-top: 3px;
  color: #737373;
`;

export const Wrapper = styled.li`
  display: flex;
  max-width: ${({ isMyMsg }) => (isMyMsg ? 100 : 80)}%;
  white-space: pre-line;
  user-select: text;
  align-items: end;
  padding-left: 20px;

  ${({ isMyMsg }) => (isMyMsg && `
    text-align: end;
    flex-direction: row-reverse;
    padding-right: 20px;
  `)}
`;

export const Select = styled.div`
  margin: 0 0 1em 20px;
`;

export const Input = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: none;

  &:checked+label::before {
    border-color: #0b76ef;
    background-color: #0b76ef;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  /* стили при наведении курсора на checkbox */
  &:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
  }
  /* стили для активного состояния чекбокса (при нажатии на него) */
  &:not(:disabled):active+label::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }
  /* стили для чекбокса, находящегося в фокусе */
  &:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  &:focus:not(:checked)+label::before {
    border-color: #80bdff;
  }
  /* стили для чекбокса, находящегося в состоянии disabled */
  &:disabled+label::before {
    background-color: #e9ecef;
  }
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-top: 5px;
  
  &::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
`;

export const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1em 0.5em;
  justify-content: center;
  align-items: center;
  align-self: start;
`;

export const Container = styled.div``;

export const Icon = styled.img`
  ${({ isMyMsg, msgDelete }) => {
    if (isMyMsg) return isMyMsgIconStyle;
    if (!isMyMsg && msgDelete) return isClientDeleteMsgIconStyle;
    if (!isMyMsg && !msgDelete) return isClientMsgIconStyle;
    return '';
  }}
`;

export const Time = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 0.75em;
  color: #79838A;
  font-weight: bold;
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: ${({ isMyMsg }) => (isMyMsg ? 'row' : 'row-reverse')};
  align-self: baseline;
  margin-left: 0.5em;
  max-width: 80%;
  margin-bottom: 0.5em;
`;

export const Edit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
`;

export const Menu = styled(ContextMenu)`
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  box-shadow: 0 3px 7px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%);
  min-width: 100px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
  
  &.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }
`;

export const Item = styled(MenuItem)`
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
  
  &:hover {
    background: #ebebeb;
  }
  
  &.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
  }
  
  &.react-contextmenu-item--divider:hover{
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
  }
`;

Wrapper.Select = Select;
Wrapper.UserBox = UserBox;
Wrapper.MessageBox = MessageBox;

Select.Input = Input;
Select.Label = Label;

UserBox.Container = Container;
UserBox.Time = Time;

Container.Icon = Icon;

MessageBox.Edit = Edit;

Menu.Item = Item;
