import { put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fileListActions } from '../reducers/fileList';
import fileListSelectors from '../selectors/fileList';
import { logError } from '../../helper/sentry';

export default function* changeFileList(action) {
  if (!action || !action.payload) return;
  try {
    const fileList = yield select(fileListSelectors.getFileList);
    const clone = [...fileList];
    clone.splice(action.payload, 1);
    yield put(fileListActions.setState(clone));
  } catch (error) {
    yield call(toast.error, `changeFileList-saga: ${error.message}`);
    logError(error);
  }
}
