import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import createActions from '../reducers/createActions';
import { logError } from '../../helper/sentry';

export default function* getUserRooms(action) {
  if (!action || !action.payload) return;
  try {
    const { active_client_room_id, count, room_id, created_at, is_with_subordinates, sorting, tryBind = true } = action.payload;
    const clientId = new URLSearchParams(window.location.search).get('clientId');
    const roomsStore = yield select(roomsSelectors.getRooms);
    const activeRoomId = yield select(roomsSelectors.getActiveRoomId);
    const args = yield call(chatAPI.getUserRooms, {
      count,
      room_id,
      is_with_subordinates,
      last_msg_time: created_at,
      sorting,
    });
    if (!Array.isArray(args.rooms)) args.rooms = [];
    let activeRoomID = '';
    const rooms = {};
    args.rooms.forEach((room) => {
      const { id, client_id } = room;
      rooms[id] = { ...room };
      if (client_id === active_client_room_id) activeRoomID = id;
    });
    const allRooms = Object.assign(JSON.parse(JSON.stringify(roomsStore)), rooms);
    yield put(roomsActions.setUserRooms({
      rooms: { ...allRooms },
      active_room_id: activeRoomID || activeRoomId,
    }));
    if (clientId && tryBind) yield put(createActions.onTryBindClient(clientId));
  } catch (error) {
    yield call(toast.error, `getUserRooms-saga: ${error.message}`);
    logError(error);
  }
}
