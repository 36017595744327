import { createSlice } from '@reduxjs/toolkit';

const storeName = 'modals';

const initialState = {
  confirm: {
    msg: '',
    enabled: false,
    resultCb: () => false,
    negativeButton: '',
    positiveButton: '',
  },
  warning: {
    title: '',
    msg: '',
    enabled: false,
  },
  logout: false,
  settings: false,
};

export const modalsSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    openConfirmModal(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.confirm = { enabled: true, ...action.payload };
    },
    openWarningModal(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.warning = { enabled: true, ...action.payload };
    },
    closeConfirmModal(state) {
      // eslint-disable-next-line no-param-reassign
      state.confirm = initialState.confirm;
    },
    closeWarningModal(state) {
      // eslint-disable-next-line no-param-reassign
      state.warning = initialState.warning;
    },
    setLogoutModalWindow(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.logout = action.payload;
    },
    setSettingsModalWindow(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.settings = action.payload;
    },
  },
});

export const modalsActions = {
  openConfirmModal: modalsSlice.actions.openConfirmModal,
  openWarningModal: modalsSlice.actions.openWarningModal,
  closeConfirmModal: modalsSlice.actions.closeConfirmModal,
  closeWarningModal: modalsSlice.actions.closeWarningModal,
  setLogoutModalWindow: modalsSlice.actions.setLogoutModalWindow,
  setSettingsModalWindow: modalsSlice.actions.setSettingsModalWindow,
};

export const modalsReducer = modalsSlice.reducer;
