import { put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { editedMessageActions } from '../reducers/editedMessage';
import editedMessageSelectors from '../selectors/editedMessage';
import { logError } from '../../helper/sentry';

export default function* checkingEditedMessage(action) {
  if (!action || !action.payload) return;
  try {
    const { text } = action.payload;
    const editedMessage = yield select(editedMessageSelectors.getStore);
    switch (true) {
    case editedMessage.text === text:
      yield put(editedMessageActions.setEdited(false));
      break;
    case editedMessage.text !== text && text.length > 0:
      yield put(editedMessageActions.setEdited(true));
      break;
    case editedMessage.text !== text && text.length === 0:
      yield put(editedMessageActions.setEdited(false));
      break;
    default: break;
    }
  } catch (error) {
    yield call(toast.error, `checkingEditedMessage-saga: ${error.message}`);
    logError(error);
  }
}
