import React from 'react';
import userPic from '../../images/manager.svg';
import { Wrapper, Container, User, Preloader, Content } from './styled';

const Loading = () => (
  <Wrapper>
    <Wrapper.Container>
      <Container.User>
        <User.Image src={userPic} alt="failed to load avatar" />
      </Container.User>
      <Container.Preloader>
        <Preloader.Content>
          <Content.First />
          <Content.Second />
          <Content.Third />
        </Preloader.Content>
      </Container.Preloader>
    </Wrapper.Container>
  </Wrapper>
);

export default Loading;
