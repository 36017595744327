import { createGlobalStyle } from 'styled-components';
import RobotoWoff from './fonts/Roboto/Roboto.woff';
import RobotoTtf from './fonts/Roboto/Roboto-Regular.ttf';
import RobotoWoff2 from './fonts/Roboto/Roboto_cyrillic.woff2';

export default createGlobalStyle`
  #root {
    display: flex;
    flex: 1;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    display: flex;
    user-select: none;
    font-weight: normal;
  }

  .Toastify__toast-body {
    white-space: pre-line;
  }

  .Toastify__toast-container--top-right {
    top: 4em;
  }

  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
    url(${RobotoWoff}) format('woff'),
    url(${RobotoTtf}) format('truetype'),
    url(${RobotoWoff2}) format('woff2');
    font-style: normal;
  }
`;
