import React, { useEffect, useState } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import Placeholder from '../Placeholder';
import { Wrapper } from './styled';

const InactiveRoom = ({ intl, activeRoomID, handleBackBtn, roomListVisibility }) => {
  const [seconds, setSeconds] = useState(0);

  const text = activeRoomID === 'none'
    ? intl.formatMessage({ id: 'selectDialogMsg' }) : intl.formatMessage({ id: 'withOutRoom' });

  if (seconds === 5 && !roomListVisibility) handleBackBtn();

  useEffect(() => {
    if (seconds === 5) return;
    const interval = setInterval(() => {
      setSeconds((state) => state + 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <>
      {activeRoomID !== 'none' && (
        <Wrapper>
          <Wrapper.Button onClick={handleBackBtn}>
            <MdChevronLeft />
          </Wrapper.Button>
        </Wrapper>
      )}
      <Placeholder text={text} />
    </>
  );
};

export default InactiveRoom;
