import { call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setNotificationToken } from '../../infra/api/ChatAPI';
import { logError } from '../../helper/sentry';

export default function* sendFireBaseToken(action) {
  if (!action || !action.payload) return;
  try {
    const { data: { firebaseToken } } = action.payload;
    yield call(setNotificationToken, firebaseToken);
  } catch (error) {
    yield call(toast.error, `sendFireBaseToken-saga: ${error.message}`);
    logError(error);
  }
}
