import styled from 'styled-components';
import { VscClose } from 'react-icons/all';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Window = styled.div`
  background: #fff;
  color: black;
  max-width: 360px;
  width: 100%;
  border-radius: .3rem;
  
  @media (max-width: 400px) {
    max-width: 320px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

export const Title = styled.h5`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
`;

export const IconClose = styled(VscClose)``;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

export const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 10px;
`;

export const Volume = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: fit-content;
  margin-bottom: 5px;
`;

export const Image = styled.img`
  width: 30px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;

  filter: ${({ active }) => (active
    ? 'invert(64%) sepia(95%) saturate(302%) hue-rotate(172deg) brightness(96%) contrast(98%)'
    : 'none')};
`;

export const Notification = styled.div``;

Wrapper.Window = Window;

Window.Header = Header;
Window.Body = Body;

Header.Title = Title;
Header.IconClose = IconClose;

Body.SubTitle = SubTitle;
Body.Volume = Volume;
Body.Notification = Notification;

Volume.Image = Image;
