import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { FiltersContainer, DatePickerContainer } from './styled';
import { getStatisticsFilters } from '../../../redux/selectors/statistics';
import { statisticActions } from '../../../redux/reducers/statistics';

const Filters = () => {
  const dispatch = useDispatch();

  const filters = useSelector(getStatisticsFilters);

  const getStatisticDebounced = useRef(debounce((val) => dispatch(statisticActions.getStatisticsRequest(val)), 700)).current;
  const handleChangeFrom = (val) => {
    const start = new Date(val);
    start.setHours(0, 0, 0, 0);
    if (start.getTime() <= filters.to.getTime()) {
      dispatch(statisticActions.setFilters({ ...filters, from: start, isLoading: true }));
      dispatch(statisticActions.getStatisticsRequest());
    }
  };
  const handleChangeTo = (val) => {
    const end = new Date(val);
    end.setHours(23, 59, 59, 999);
    if (end.getTime() >= filters.from.getTime()) {
      dispatch(statisticActions.setFilters({ ...filters, to: end, isLoading: true }));
      dispatch(statisticActions.getStatisticsRequest());
    }
  };
  const handleChangeInput = (e) => {
    dispatch(statisticActions.setFilters({ ...filters, manager_id: e.target.value, isLoading: true }));
    getStatisticDebounced(e.target.value);
  };
  return (
    <FiltersContainer>
      <DatePickerContainer>
        <DatePicker
          placeholderText="From"
          id="from"
          onChange={handleChangeFrom}
          selected={filters.from || null}
          maxDate={filters.to || new Date()}
        />
      </DatePickerContainer>
      <DatePickerContainer>
        <DatePicker
          placeholderText="To"
          id="to"
          onChange={handleChangeTo}
          selected={filters.to || null}
          minDate={filters.from || new Date()}
        />
      </DatePickerContainer>
      <input placeholder="Manager ID" onChange={handleChangeInput} value={filters.manager_id || ''} />
    </FiltersContainer>
  );
};

export default Filters;
