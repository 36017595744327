import styled from 'styled-components';

export const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Window = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  color: black;
  width: 270px;
  height: 179px;
  border-radius: 20px;
  justify-content: space-between;
`;

export const Header = styled.div`
  font-size: 17px;
  padding-top: 22px;
  font-weight: bold;
`;

export const Message = styled.div`
  display: flex;
  font-size: 15px;
  padding: 0 60px;
  text-align: center;
  color: #75808D;
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  height: 68px;
  border-top: 1px solid #bbc5d0;
`;

export const Cancel = styled.div`
  display: flex;
  width: 165px;
  padding: 7px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #75808D;
`;

export const Line = styled.div`
  border-left: 1px solid #bbc5d0;
`;

export const Confirm = styled.div`
  display: flex;
  width: 165px;
  padding: 7px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #81a1d0;
`;

Modal.Window = Window;

Window.Header = Header;
Window.Message = Message;
Window.Buttons = Buttons;

Buttons.Cancel = Cancel;
Buttons.Line = Line;
Buttons.Confirm = Confirm;
