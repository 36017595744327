import { createSlice } from '@reduxjs/toolkit';

const storeName = 'rooms';

const initialState = {
  active_room_id: 'none',
  prev_active_room_id: '',
  rooms: {},
};

export const roomsSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setActiveRoom(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.active_room_id = action.payload || '';
    },
    setPrevActiveRoom(state) {
      // eslint-disable-next-line no-param-reassign
      state.prev_active_room_id = '';
    },
    setUserRooms(state, action) {
      const { rooms, active_room_id } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.rooms = rooms;
      // eslint-disable-next-line no-param-reassign
      state.active_room_id = active_room_id;
    },
    setUpdateRoom(state, action) {
      const { rooms } = state;
      const { id } = action.payload;
      rooms[id] = { ...rooms[id], ...action.payload };
      // eslint-disable-next-line no-param-reassign
      state.rooms = { ...rooms };
    },
    setUpdateRoomSettings(state, action) {
      const { rooms } = state;
      const { id, room } = action.payload;
      rooms[id] = {
        ...rooms[id],
        ...room,
      };
      // eslint-disable-next-line no-param-reassign
      state.rooms = { ...rooms };
    },
    setRoomLatestMessage(state, action) {
      const { rooms } = state;
      const { room_id, latest_msg } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.rooms = {
        ...rooms,
        [room_id]: {
          ...rooms[room_id],
          latest_msg,
        },
      };
    },
    setClientStatus(state, action) {
      const { id, client_status, client_version } = action.payload;
      const { rooms } = state;
      const clientRoom = rooms[id];
      clientRoom.client_status = client_status;
      clientRoom.client_version = client_version;
      // eslint-disable-next-line no-param-reassign
      state.rooms = {
        ...rooms,
        [id]: {
          ...clientRoom,
        },
      };
    },
  },
});

export const roomsActions = {
  setActiveRoom: roomsSlice.actions.setActiveRoom,
  setPrevActiveRoom: roomsSlice.actions.setPrevActiveRoom,
  setUserRooms: roomsSlice.actions.setUserRooms,
  setUpdateRoom: roomsSlice.actions.setUpdateRoom,
  setUpdateRoomSettings: roomsSlice.actions.setUpdateRoomSettings,
  setRoomLatestMessage: roomsSlice.actions.setRoomLatestMessage,
  setClientStatus: roomsSlice.actions.setClientStatus,
};

export const roomsReducer = roomsSlice.reducer;
