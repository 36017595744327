import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import createActions from '../../../redux/reducers/createActions';
import { Wrapper } from './styled';

const RefreshButton = ({ clientId }) => {
  const dispatch = useDispatch();

  const refreshRecordings = () => {
    dispatch(createActions.onGetRecordings({ leadId: clientId }));
  };

  return (
    <Wrapper onClick={refreshRecordings}>
      <Wrapper.Icon />
      <Wrapper.Text>Refresh</Wrapper.Text>
    </Wrapper>
  );
};

RefreshButton.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default RefreshButton;
