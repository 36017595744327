import { createSelector } from 'reselect';

const quotedMessageState = (state) => state.quotedMessage;

const getVisibility = createSelector(quotedMessageState, (s) => s.visibility);

const getMessage = createSelector(quotedMessageState, (s) => s.message);

const quotedMessageSelectors = { getVisibility, getMessage };

export default quotedMessageSelectors;
