import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import searchIcon from '../../../../images/search.svg';
import cancelIcon from '../../../../images/cancel.svg';
import { searchListActions } from '../../../../redux/reducers/searchList';
import searchListSelectors from '../../../../redux/selectors/searchList';
import { searchStringActions } from '../../../../redux/reducers/searchString';
import createActions from '../../../../redux/reducers/createActions';
import { Wrapper, Search, Items } from './styled';
import searchStringSelectors from '../../../../redux/selectors/searchString';

const ToolBar = ({ intl }) => {
  const searchPlaceholder = intl.formatMessage({ id: 'searchByID' });
  const searchList = useSelector(searchListSelectors.getSearchList);
  const searchString = useSelector(searchStringSelectors.getState);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const onClick = (id) => {
    dispatch(searchStringActions.setState(id));
    dispatch(searchListActions.setState([]));
    dispatch(createActions.onGetUserRoom({ client_id: id }));
  };

  const onChange = React.useCallback((e) => {
    dispatch(searchStringActions.setState(e.target.value));
    dispatch(createActions.onSearchClient(e.target.value));
  }, []);

  const handleClear = React.useCallback(() => {
    dispatch(searchStringActions.setState(''));
    dispatch(searchListActions.setState([]));
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        dispatch(searchListActions.setState([]));
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [searchList]);

  return (
    <Wrapper ref={ref}>
      <Wrapper.Search>
        <Search.Icon src={searchIcon} alt="Search by Name" />
        <Search.Input
          className="sidebar__search-input"
          value={searchString}
          onChange={onChange}
          placeholder={searchPlaceholder}
        />
        {searchString && <Search.Icon src={cancelIcon} alt="" onClick={() => handleClear()} />}
        {searchList.length > 0 && (
          <Search.Items>
            {searchList.map((id) => (
              <Items.Item onClick={() => onClick(id)}>{id}</Items.Item>
            ))}
          </Search.Items>
        )}
      </Wrapper.Search>
      <Wrapper.Toolbar />
    </Wrapper>
  );
};

ToolBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  intl: PropTypes.object.isRequired,
};

export default ToolBar;
