import { put, select, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { switcherMicrophone } from '../../infra/webrtc/caller';
import { microphoneStatusActions } from '../reducers/microphoneStatus';
import microphoneStatusSelectors from '../selectors/microphoneStatus';
import { logError } from '../../helper/sentry';

export default function* microphoneStatus() {
  try {
    const status = yield select(microphoneStatusSelectors.getMicrophoneStatus);
    yield call(switcherMicrophone, !status);
    yield put(microphoneStatusActions.setState(!status));
  } catch (e) {
    yield call(toast.error, `changeFileList-saga: microphoneStatus failed due to - ${e.message}`);
    logError(e);
  }
}
