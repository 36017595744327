import styled from 'styled-components';

export const RawContainer = styled.summary`
  display: flex;
  border-bottom: 1px solid #202933;
`;
export const RawContainerLi = styled.li`
  display: flex!important;
  border-bottom: 1px solid #202933;
`;
export const RawContainerSpan = styled.span`
  width: 90px;
`;
export const RawCell = styled.div`
    width: ${({ width }) => (width || '200px')};
    padding: ${({ paddingLeft }) => (`5px 0 5px ${paddingLeft || '0'}px`)};
    text-align: ${({ right }) => (right ? 'right' : 'center')};
    overflow:hidden;
    ${({ right }) => (right && `
      padding-right: 10px;
    `)};
`;

export const AnsweredHeaderContainers = styled.div`
  display: flex;
  justify-content: space-around;

  & div{
    width: 25%;
    text-align: center;
  }
`;
