import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import getUserRooms from './getUserRooms';
import getUserRoom from './getUserRoom';
import getHistoryMessages from './getHistoryMessages';
import sendMessage from './sendMessage';
import getRoomUnreadMessages from './getRoomUnreadMessages';
import messageReceived from './receivedMessage';
import nextUnreadMessage from './nextUnreadMessage';
import nextUnreadId from './nextUnreadId';
import initChat from './initChat';
import getRoomImageLinks from './getRoomImageLinks';
import sendFiles from './sendFiles';
import clientMsgRead from './clientMsgRead';
import tryBind from './tryBindClient';
import startOfflineCall from './startOfflineCall';
import changeCallStatus from './changeCallStatus';
import { clientConnected, clientDisconnected, changeClientStatuses } from './changeClientStatus';
import getRecordings from './getRecordings';
import searchClient from './searchClient';
import auth from './auth';
import changeFileList from './changeFileList';
import messageSelectionVisibility from './messageSelectionVisibility';
import deleteMessagesSaga from './deleteMessages';
import quotedMessage from './quotedMessage';
import microphoneStatus from './microphoneStatus';
import editingMessage from './editingMessage';
import closeEditedMessage from './closeEditedMessage';
import checkingEditedMessage from './checkingEditedMessage';
import sendEditMessage from './sendEditMessage';
import receivedEditedMessage from './receivedEditedMessage';
import onGetUserRooms from './onGetUserRooms';
import checkUserRooms from './checkUserRooms';
import deleteHistoryMessagesSaga from './deleteHistoryMessages';
import sendFireBaseToken from './sendFireBaseToken';
import sendManagerSettings from './sendManagerSettings';
import getStatistics from './statistics';
import { settingsActions } from '../reducers/settings';
import createActions from '../reducers/createActions';
import { statisticActions } from '../reducers/statistics';
// import { watchClientConnectedForCalling } from './watchClientConnectedForCalling';

export default function* rootSaga() {
  yield all([
    takeLatest(createActions.onGetUserRooms.type, getUserRooms),
    takeLatest(createActions.onCheckUserRooms.type, checkUserRooms),
    takeEvery(createActions.onGetUserRoom.type, getUserRoom),
    takeEvery(createActions.onGetHistoryMessage.type, getHistoryMessages),
    takeEvery(createActions.onSendMessage.type, sendMessage),
    takeEvery(createActions.onGetRoomUnreadMessages.type, getRoomUnreadMessages),
    takeEvery(createActions.onReceivedMessage.type, messageReceived),
    takeEvery(createActions.onNextUnreadMessage.type, nextUnreadMessage),
    takeEvery(createActions.onNextUnreadId.type, nextUnreadId),
    takeLatest(createActions.onInitChat.type, initChat),
    takeEvery(createActions.onGetRoomImageLinks.type, getRoomImageLinks),
    takeEvery(createActions.onClientMsgRead.type, clientMsgRead),
    takeEvery(createActions.onSendFiles.type, sendFiles),
    takeEvery(createActions.onTryBindClient.type, tryBind),
    takeEvery(createActions.onClientConnected.type, clientConnected),
    takeEvery(createActions.onClientDisconnected.type, clientDisconnected),
    takeEvery(createActions.onGetRecordings.type, getRecordings),
    takeLatest(createActions.onStartOfflineCall.type, startOfflineCall),
    // takeEvery(createActions.onClientConnected.type, watchClientConnectedForCalling),
    takeEvery(createActions.onChangeCallStatus.type, changeCallStatus),
    takeEvery(createActions.onChangeClientStatuses.type, changeClientStatuses),
    takeLatest(createActions.onSearchClient.type, searchClient),
    takeLatest(createActions.onAuth.type, auth),
    takeLatest(createActions.onChangeFileList.type, changeFileList),
    takeLatest(createActions.onMessageSelectionVisibility.type, messageSelectionVisibility),
    takeLatest(createActions.onDeleteMessages.type, deleteMessagesSaga),
    takeLatest(createActions.onDeleteHistoryMessages.type, deleteHistoryMessagesSaga),
    takeLatest(createActions.onQuotedMessage.type, quotedMessage),
    takeLatest(createActions.onMicrophoneStatus.type, microphoneStatus),
    takeLatest(createActions.onEditingMessage.type, editingMessage),
    takeLatest(createActions.onCloseEditedMessage.type, closeEditedMessage),
    takeLatest(createActions.onCheckingEditedMessage.type, checkingEditedMessage),
    takeLatest(createActions.onSendEditMessage.type, sendEditMessage),
    takeEvery(createActions.onReceivedEditedMessage.type, receivedEditedMessage),
    takeEvery(createActions.onGetUserRoomsSaga.type, onGetUserRooms),
    takeEvery(createActions.onSendFireBaseToken.type, sendFireBaseToken),
    takeEvery(settingsActions.onSendSettings.type, sendManagerSettings),
    takeEvery(statisticActions.getStatisticsRequest.type, getStatistics),
  ]);
}
