import React from 'react';
import { FaFileDownload } from 'react-icons/fa';
import { Wrapper, Link, Container } from './styled';
import CompositionMessage from '../CompositionMessage';
import { splitFileNameAndUrl } from '../../../../../helper/links';

const FileMessage = (props) => {
  const { message: { text, status }, isMyMsg, expirationTime } = props;

  const { name = '', url = '' } = splitFileNameAndUrl(text) || {};

  const deleteMessage = status === 3;

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <CompositionMessage {...props}>
      <Wrapper isMyMsg={isMyMsg} msgDelete={deleteMessage} expiration={expirationTime}>
        <Wrapper.Link href={url} download={name} target="iframe_a">
          <Link.IFrame src="about:blank" name="iframe_a" title="iframe" />
          <Link.Container>
            <Container.Icon>
              <FaFileDownload />
            </Container.Icon>
            <Container.Text msgDelete={deleteMessage}>{splitFileNameAndUrl(text) ? name : 'Sending...'}</Container.Text>
          </Link.Container>
        </Wrapper.Link>
      </Wrapper>
    </CompositionMessage>
  );
};

export default FileMessage;
