import { createSelector } from 'reselect';

const managerInfoState = (state) => state.managerInfo;

const getManagerId = createSelector(managerInfoState, (s) => s.manager_id);

const getIsGuest = createSelector(managerInfoState, (s) => s.isGuest);

const managerInfoSelectors = { getManagerId, getIsGuest };

export default managerInfoSelectors;
