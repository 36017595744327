import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 20px;
`;

export const Content = styled.div`
  height: 25px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
`;

export const Menu = styled.div`
  flex: 1;
  font-size: 14px;
  width: 20px;
  color: #74c6f3;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

Wrapper.Content = Content;

Content.Menu = Menu;
