import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #eceefa;
  display: flex;
  flex: 1;
  justify-content: center;
  border-bottom: 1px solid rgb(222, 224, 236);
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Messages = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 .5em 0 0;
  padding: 0;
`;

export const MessageNewDay = styled.li``;

export const Text = styled.span`
  display: flex;
  justify-content: center;
  color: #79838A;
  font-size: 1em;
  font-weight: 600;
  margin: 10px
`;

Wrapper.Container = Container;

Container.Messages = Messages;

MessageNewDay.Text = Text;
