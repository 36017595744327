const call = {};

Object.defineProperties(call, {
  STAND_BY: { value: 'STAND_BY', writable: false },
  CALLING: { value: 'CALLING', writable: false },
  ANSWERING: { value: 'ANSWERING', writable: false },
  TALKING: { value: 'TALKING', writable: false },
  DECLINED: { value: 'DECLINED', writable: false },
  FINISHED: { value: 'FINISHED', writable: false },
});

export default call;
