import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import constants from '../../../../../../config';
import checkValidation from './validation';
import createActions from '../../../../../../redux/reducers/createActions';
import { Wrapper, Window, Header, Body, Results, Footer } from './styled';

const SwitchToMsgModalWindow = ({ roomId, closeCB }) => {
  const [dropdownActive, setDropdownActive] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [isValidationInput, setIsValidationInput] = useState(false);
  const [stateInputDef, setStateInputDef] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const errorMsg = `Please enter 1 to ${constants.switchToLength} characters`;
  const dispatch = useDispatch();
  let resultsArray;

  const dataLS = localStorage.getItem('suggestedSwitchToNames');

  if (dataLS) {
    resultsArray = JSON.parse(dataLS);
  } else {
    localStorage.setItem('suggestedSwitchToNames', []);
    resultsArray = [];
  }

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    setIsValidationInput(checkValidation(e.target.value));
    setDropdownActive(true);
    setStateInputDef(false);
  };

  const handleOnKeyDown = (event) => {
    if (event.keyCode === 13 && isValidationInput) {
      event.preventDefault();
      sendMsg(searchTerm);
    }
  };

  const sendMsg = (text) => {
    if (!resultsArray.includes(`${text}`)) {
      localStorage.setItem('suggestedSwitchToNames', JSON.stringify([...resultsArray, text]));
    }
    dispatch(createActions.onSendMessage({
      roomId,
      text: `5;${text}`,
      type: 3,
    }));
    setSearchTerm('');
    closeCB();
  };

  useEffect(() => {
    const results = resultsArray.filter((person) => person.toLowerCase().includes(searchTerm.toLowerCase()));
    setSearchResults(results);
  }, [searchTerm]);

  const showDropDown = searchResults.length !== 0 && searchTerm !== '' && dropdownActive;

  return (
    <Wrapper>
      <Wrapper.Window>
        <Window.Header>
          <Header.Title>Enter name</Header.Title>
          <Header.IconClose onClick={closeCB} />
        </Window.Header>
        <Window.Body>
          <Body.Input
            type="text"
            placeholder="Name"
            value={searchTerm}
            onChange={handleChange}
            onKeyDown={handleOnKeyDown}
            state={stateInputDef}
            validation={isValidationInput}
          />
          {(!isValidationInput && !stateInputDef) && <Body.ErrorMessage>{errorMsg}</Body.ErrorMessage>}
          {showDropDown && (
            <Body.Results>
              {searchResults.slice(0, 5).map((item) => (
                <Results.Item
                  onClick={() => {
                    setDropdownActive(false);
                    setSearchTerm(item);
                  }}
                  key={item.toString()}
                >
                  {item}
                </Results.Item>
              ))}
            </Body.Results>
          )}
        </Window.Body>
        <Window.Footer>
          <Footer.ButtonClose onClick={closeCB}>Close</Footer.ButtonClose>
          <Footer.ButtonSend
            onClick={() => sendMsg(searchTerm)}
            disabled={!isValidationInput}
          >
            Send
          </Footer.ButtonSend>
        </Window.Footer>
      </Wrapper.Window>
    </Wrapper>
  );
};

SwitchToMsgModalWindow.propTypes = {
  roomId: PropTypes.string.isRequired,
  closeCB: PropTypes.func.isRequired,
};

export default SwitchToMsgModalWindow;
