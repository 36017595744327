import { createSlice } from '@reduxjs/toolkit';

const storeName = 'imageLinks';

export const initialRoom = {
  links: [],
  isRequested: true,
};

const initialState = {
  isOpen: false,
  currentImageUrl: '',
  rooms: {},
};

export const imageLinksSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setLightBoxOpen(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.isOpen = true;
      // eslint-disable-next-line no-param-reassign
      state.currentImageUrl = action.payload;
    },
    setLightBoxClose(state) {
      // eslint-disable-next-line no-param-reassign
      state.isOpen = false;
      // eslint-disable-next-line no-param-reassign
      state.currentImageUrl = '';
    },
    setImageLinkStore(state, action) {
      const { roomId, link } = action.payload;
      if (state.rooms[roomId]) {
        state.rooms[roomId].push(link);
      } else {
        // eslint-disable-next-line no-param-reassign
        state.rooms[roomId] = [link];
      }
    },
    setImageLinks(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.rooms = action.payload;
    },
  },
});

export const imageLinksActions = {
  setImageLinks: imageLinksSlice.actions.setImageLinks,
  setLightBoxOpen: imageLinksSlice.actions.setLightBoxOpen,
  setLightBoxClose: imageLinksSlice.actions.setLightBoxClose,
  setImageLinkStore: imageLinksSlice.actions.setImageLinkStore,
};

export const imageLinksReducer = imageLinksSlice.reducer;
