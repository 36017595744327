import styled from 'styled-components';
import { MdChevronLeft } from 'react-icons/md';

export const Wrapper = styled.div`
  width: 280px;
  background: #ECEEFA;
  border-left: 1px solid #b7b7b7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const NoRecording = styled.div`
  display: flex;
  justify-content: center;
  padding: .75em 1.5em .75em 1.5em;
  background-color: #CFD2DE;
  height: 2.6em;
  align-items: center;
  position: relative;
`;

export const Close = styled(MdChevronLeft)`
  display: none;
  font-size: 40px;
  color: #81a1d0;
  z-index: 1;

  @media (max-width: 480px) {
    display: block;
  }
`;

export const Title = styled.p`
  @media (max-width: 480px) {
    width: 100%;
    text-align: center;
    margin-left: -30px;
  }
`;

Wrapper.NoRecording = NoRecording;

NoRecording.Close = Close;
NoRecording.Title = Title;
