import React, { useState } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import { Wrapper, Form } from './styled';
import { logError } from '../../helper/sentry';

const initialCredentials = {
  login: '',
  password: '',
};

const Login = ({ successLoginCb }) => {
  const [credentials, setCredentials] = useState(initialCredentials);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setErrMsg('');
    setCredentials((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onSubmit = async () => {
    const { login, password } = credentials;
    setIsLoading(true);
    await fetch(`${config.backendManagerChatApi}/login`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login,
        password,
      }),
    })
      .then(processResp)
      .catch((e) => {
        setErrMsg('Something went wrong. Try again later please.');
        setIsLoading(false);
        logError(`Login error${e}`);
      });
  };

  const processResp = (r) => {
    switch (r.status) {
    case 200:
      successLoginCb();
      setErrMsg('');
      break;
    case 401:
    case 403:
      setErrMsg('Invalid login or password.');
      break;
    default:
      setErrMsg('Something went wrong. Please, try again later.');
    }
    setIsLoading(false);
  };

  const { login, password } = credentials;
  return (
    <Wrapper>
      <Wrapper.Form>
        <Form.Input id="login" type="text" placeholder="login" value={login} onChange={handleChange} />
        <Form.Input id="password" type="password" placeholder="password" value={password} onChange={handleChange} />
        <Form.Button onClick={onSubmit} disabled={isLoading}>Login</Form.Button>
        <Form.ErrorMsg>{errMsg}</Form.ErrorMsg>
      </Wrapper.Form>
    </Wrapper>
  );
};

Login.propTypes = {
  successLoginCb: PropTypes.func.isRequired,
};

export default Login;
