import { createSlice } from '@reduxjs/toolkit';

const storeName = 'quotedMessage';

const initialState = {
  visibility: false,
  message: null,
};

export const quotedMessageSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setVisibility(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.visibility = action.payload;
    },
    setMessage(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.message = action.payload;
    },
  },
});

export const quotedMessageActions = {
  setVisibility: quotedMessageSlice.actions.setVisibility,
  setMessage: quotedMessageSlice.actions.setMessage,
};

export const quotedMessageReducer = quotedMessageSlice.reducer;
