import styled from 'styled-components';

const WarningMessage = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 73px;
  background-color: #e8aa3b;
  border-bottom: 1px solid gray;
  
  & > svg {
    margin-right: 10px;
  }
`;

export default WarningMessage;
