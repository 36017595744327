import styled from 'styled-components';

export const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Window = styled.div`
  background: #fff;
  color: black;
  max-width: 360px;
  width: 100%;
  border-radius: .3rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  
  & > svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`;

export const Title = styled.h5`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: left;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: center;
  cursor: pointer;
`;

export const Button = styled.div`
  color: #fff;
  background-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: all .3s cubic-bezier(.47,0,.745,.715);
  
  &:hover {
    background: #228cdb;
  }
`;

Modal.Window = Window;

Window.Header = Header;
Window.Body = Body;
Window.Footer = Footer;

Header.Title = Title;

Body.SubTitle = Text;

Footer.Button = Button;
