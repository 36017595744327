import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

export const Wrapper = styled.div`
  display: flex;
  color: white;
  justify-content: space-between;
  border-bottom: 1px solid #293143;
  gap: 0 10px;
  align-items: center;
  padding: 0 10px 10px 10px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  font-size: 16px;
  color: #6c757d;
`;

export const Sort = styled.div`
  position: relative;
  display: flex;
  font-size: 16px;
  color: #6c757d;
  cursor: pointer;
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  font-size: 16px;
  color: #6c757d;
  cursor: pointer;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  clip: rect(0 0 0 0);
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  margin-right: 5px;
  cursor: pointer;
`;

export const Icon = styled.div`
  font-size: 22px;
  color: #81a1d0;
  height: 0;
`;

export const LoadRoomImage = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  top: -4px;
  left: calc(100% + 60px);
  cursor: pointer;
`;

export const SubTitle = styled.span`
  color: #81a1d0;
  font-size: 10px;
  position: absolute;
  left: calc(100% + 43px);
  top: 13px;
  cursor: pointer;
  width: fit-content;
`;

export const Rooms = styled.div`
  flex: 1;
`;

export const Scroll = styled(Scrollbars)`
  & > div {
    margin-right: 0 !important;
  }

  & > div::-webkit-scrollbar { width: 0; }

  & > div:nth-child(3) > div {
    background-color: #4d565f !important;
  }
`;

export const List = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

export const EmptySearchList = styled.div`
  color: #fff;
  text-align: center;
`;

Wrapper.Content = Content;
Wrapper.Checkbox = Checkbox;
Wrapper.Label = Label;
Wrapper.Sort = Sort;

Content.Icon = Icon;
Content.LoadRoomImage = LoadRoomImage;
Content.SubTitle = SubTitle;

Rooms.Scroll = Scroll;

Scroll.List = List;
