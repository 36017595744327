import { createSlice } from '@reduxjs/toolkit';

const storeName = 'recordings';

const initialState = {
  show: false,
  recMap: {},
};

export const recordingsSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setRecording(state, action) {
      const { recordings, leadId } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.recMap = { ...state.recMap, [leadId]: recordings };
    },
    setShow(state) {
      // eslint-disable-next-line no-param-reassign
      state.show = !state.show;
    },
  },
});

export const recordingsActions = {
  setRecording: recordingsSlice.actions.setRecording,
  setShow: recordingsSlice.actions.setShow,
};

export const recordingsReducer = recordingsSlice.reducer;
