import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .75em 1.5em .75em 1.5em;
  background-color: #CFD2DE;
  height: 2.6em;
  align-items: center;
  position: relative;

  @media (max-width: 480px) {
    background-color: #202933;
    padding: 0.75em 1em .75em 1em;
  }
`;

export const Button = styled.div`
  display: none;
  color: #202933;
  font-size: 40px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 480px) {
    display: flex;
    color: #81a1d0;
  }
`;

Wrapper.Button = Button;
