import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import messagesSelectors from '../selectors/messages';
import { messagesActions } from '../reducers/messages';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* getRoomUnreadMessages(a) {
  const { roomId, msgId, count } = a.payload;
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.error(new Error('getRoomUnreadMessages saga: invalid action argument "roomId"'));
    return;
  }
  if (typeof msgId !== 'number' || msgId <= 0) {
    console.error(new Error('getRoomUnreadMessages saga: invalid action argument "msgId"'));
    return;
  }
  if (typeof count !== 'number' || count <= 0) {
    console.error(new Error('getRoomUnreadMessages saga: invalid action argument "count"'));
    return;
  }
  try {
    const messagesStore = yield select(messagesSelectors.getStore);
    const rooms = yield select(roomsSelectors.getRooms);

    let isRequest = true;
    const roomMsgs = messagesStore[roomId];
    const room = rooms[roomId];
    const { messages: msgs = [] } = roomMsgs || {};
    const { id: lastMessageId = 0 } = room?.lates_msg || {};
    if (msgs.length === 0 && lastMessageId === 0) {
      isRequest = false;
    }
    if (msgs.length > 0 && msgs[0].id === lastMessageId) {
      isRequest = false;
    }
    if (!isRequest) {
      return;
    }

    const responseArgs = yield call(chatAPI.getRoomUnreadMessages, roomId, msgId, count);
    const { room_id: roomIdResponse, messages } = responseArgs;
    if (typeof roomIdResponse !== 'string' || roomIdResponse.length === 0 || roomIdResponse !== roomId) {
      console.error(new Error('getRoomUnreadMessages saga: invalid response argument "roomId"'));
      return;
    }
    if (Array.isArray(messages) && messages.length > 0) {
      yield put(messagesActions.setRoomUnreadMessages({
        messages,
        roomId,
      }));
    }
    if (!Array.isArray(messages) || messages.length < count) {
      yield put(messagesActions.setUnreadEndReached({ roomId }));
    }
  } catch (error) {
    yield call(toast.error, `getRoomUnreadMessages-saga: ${error.message}`);
    logError(error);
  }
}
