import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Main from '../Main';
import * as chatAPI from '../../infra/api/ChatAPI';
import Loading from '../Loading';
import Login from '../Login';
import actions from '../../constants/actions';
import config from '../../config';
import ConfirmModalWindow from '../Common/ModalWindow/ConfirmModalWindow';
import WarningModalWindow from '../Common/ModalWindow/WarningModalWindow';
import SideBar from '../SideBar';
import RightSideBar from '../RightSidebar';
import ErrorPage from '../ErrorPage';
import 'react-toastify/dist/ReactToastify.css';
import FireBase from '../FireBase';
import ContainerStatistics from '../ContainerStatistics';
import { visibleComponentsActions } from '../../redux/reducers/visibleComponents';
import visibleComponentsSelectors from '../../redux/selectors/visibleComponents';
import roomListSelectors from '../../redux/selectors/roomList';
import bindClientSelectors from '../../redux/selectors/bindClient';
import { wiretapperActions } from '../../redux/reducers/wiretapper';
import recordingsSelectors from '../../redux/selectors/recordings';
import searchStringSelectors from '../../redux/selectors/searchString';
import createActions from '../../redux/reducers/createActions';
import { SideBarContainer, Content } from './styled';

window.chatAPI = chatAPI;

export default () => {
  const [isLoadingVisible, setLoadingVisibility] = useState(true);
  const searchClientID = useSelector(searchStringSelectors.getState);
  const showRecordings = useSelector(recordingsSelectors.getShow);
  const isBindClient = useSelector(bindClientSelectors.getState);
  const isRoomListVisible = useSelector(roomListSelectors.getRoomList);
  const { login: isLoginVisible } = useSelector(visibleComponentsSelectors.getVisibleComponents);

  const dispatch = useDispatch();

  const onNewMessage = (data) => {
    dispatch(createActions.onReceivedMessage(data.args));
  };

  const onClientEditedMessage = (data) => {
    dispatch(createActions.onReceivedEditedMessage(data.args));
  };

  const onNextUnreadId = (data) => {
    dispatch(createActions.onNextUnreadId(data.args));
  };

  const onUserRoomUpdated = (data) => {
    dispatchData(actions.UPDATE_ROOM_SETTINGS, data);
  };

  const onClientMsgRead = (data) => {
    dispatch(createActions.onClientMsgRead(data.args));
  };

  const onUnauthorized = () => {
    dispatch(visibleComponentsActions.setLoginVisibility(true));
  };

  const onClientConnected = (data) => {
    dispatch(createActions.onClientConnected(data.args));
  };

  const onClientDisconnected = (data) => {
    dispatch(createActions.onClientDisconnected(data.args));
  };

  const changedClientStatuses = (data) => {
    dispatch(createActions.onChangeClientStatuses(data.args));
  };

  const onGetUserRoomResponse = (data) => {
    dispatch(createActions.onGetUserRoomsSaga(data.args));
  };

  const onTeamWiretapEnable = (data) => {
    dispatch(wiretapperActions.setState({
      clientId: data.args.client_id,
      active: true,
    }));
  };

  const onTeamWiretapDisable = (data) => {
    dispatch(wiretapperActions.setState({
      clientId: data.args.client_id,
      active: false,
    }));
  };

  const dispatchData = (actionType, data) => {
    const { args } = data;
    if (!args) {
      console.error(new Error('invalid args'));
      return;
    }
    dispatch({
      type: actionType,
      payload: args,
    });
  };

  const setup = (token) => {
    chatAPI.onConnected(() => {
      dispatch(createActions.onInitChat(setLoadingVisibility));
    });
    chatAPI.onMsg(onNewMessage);
    chatAPI.onClientEditedMsg(onClientEditedMessage);
    chatAPI.onNextUnreadId(onNextUnreadId);
    chatAPI.onUserRoomUpdated(onUserRoomUpdated);
    chatAPI.onClientMsgRead(onClientMsgRead);
    chatAPI.onClientConnected(onClientConnected);
    chatAPI.onClientDisconnected(onClientDisconnected);
    chatAPI.changedClientStatuses(changedClientStatuses);
    chatAPI.onUnauthorized(onUnauthorized);
    chatAPI.onManagerCallEstablished(onTeamWiretapEnable);
    chatAPI.onManagerCallEnded(onTeamWiretapDisable);
    chatAPI.onGetUserRoomResponse(onGetUserRoomResponse);
    chatAPI.start({
      url: config.backendWebSocket,
      reconnectDelay: config.wsReconnectDelay,
      queryParam: {
        token,
        pingMsg: config.wsPingMsg,
        pingWait: config.wsPingWait,
        pingInterval: config.wsPingInterval,
      },
    });
  };

  const onSuccessLogin = () => {
    dispatch(visibleComponentsActions.setLoginVisibility(false));
    setup();
  };

  const ChatComponent = () => (
    <>
      <SideBarContainer visibility={isRoomListVisible}>
        <SideBar />
      </SideBarContainer>
      <Content visibility={showRecordings}>
        {isBindClient ? <Main /> : <ErrorPage clientId={searchClientID} />}
      </Content>
      {showRecordings && <RightSideBar />}
    </>
  );

  useEffect(() => {
    dispatch(createActions.onAuth(setup));
  }, []);

  return (
    <Router>
      {isLoginVisible && <Login successLoginCb={onSuccessLogin} />}
      {!isLoginVisible && isLoadingVisible && <Loading />}
      {!isLoginVisible && !isLoadingVisible && (
        <>
          <Routes>
            <Route exact path="/" element={<ChatComponent />} />
            <Route exact path="/statistics" element={<ContainerStatistics />} />
          </Routes>
          <ConfirmModalWindow />
          <WarningModalWindow />
          <ToastContainer />
          <FireBase />
        </>
      )}
    </Router>
  );
};
