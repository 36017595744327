import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as caller from '../../infra/webrtc/caller';
import { microphoneStatusActions } from '../reducers/microphoneStatus';
import { roomIdWithActiveCallActions } from '../reducers/roomIdWithActiveCall';
import { callStatusActions } from '../reducers/callStatus';
import { logError } from '../../helper/sentry';

export default function* changeCallStatus(action) {
  if (!action || !action.payload) {
    return;
  }

  try {
    if (action.payload === 'FINISHED') {
      yield call(caller.stopCall);
      yield put(microphoneStatusActions.setState(true));
      yield put(roomIdWithActiveCallActions.setState(''));
    }
    yield put(callStatusActions.setState(action.payload));
  } catch (e) {
    yield call(toast.error, 'ChangeCallStatus-saga: An error occurred during the call.');
    logError(e);
  }
}
