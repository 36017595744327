import { createSlice } from '@reduxjs/toolkit';

const storeName = 'searchString';

const initialState = {
  state: '',
};

export const searchStringSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const searchStringActions = {
  setState: searchStringSlice.actions.setState,
};

export const searchStringReducer = searchStringSlice.reducer;
