import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { quotedMessageActions } from '../reducers/quotedMessage';
import { logError } from '../../helper/sentry';

export default function* quotedMessage(action) {
  if (!action || !action.payload) return;
  try {
    const { visibility, message } = action.payload;
    yield put(quotedMessageActions.setVisibility(visibility));
    yield put(quotedMessageActions.setMessage(message));
  } catch (error) {
    yield call(toast.error, `quotedMessage-saga: ${error.message}`);
    logError(error);
  }
}
