import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 10px 20px;
  background: #202933;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 20px;
`;

export const Body = styled.div`
  height: 100%;
  position: absolute;
  top: 84px;
  padding: 30px;
  width: calc(100% - 60px);
`;

Wrapper.Header = Header;
Wrapper.Body = Body;

Header.Title = Title;
