import React from 'react';
import { AiOutlineWarning } from 'react-icons/all';
import WarningMessage from './styled';

const EditedWarningMessage = () => (
  <WarningMessage>
    <AiOutlineWarning />
    Client v1.0 (Mobile client): Inappropriate rendering
  </WarningMessage>
);

export default EditedWarningMessage;
