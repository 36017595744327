import React from 'react';
import {
  HeadersContainer,
  HeaderCell,
  AnsweredHeaderContainers,
  AnsweredHeaderText,
  AnsweredHeaderContainer,
} from './styled';

const Headers = () => (
  <HeadersContainer>
    <HeaderCell width="356px" right>
      Manager ID
    </HeaderCell>
    <HeaderCell width="185px">
      Total Calls
    </HeaderCell>
    <HeaderCell width="185px">
      Unique Clients
    </HeaderCell>
    <HeaderCell width="185px">
      Decline Calls
    </HeaderCell>
    <HeaderCell width="185px">
      Missed Calls
    </HeaderCell>
    <HeaderCell width="372px" withoutAlign>
      <AnsweredHeaderContainer>
        <AnsweredHeaderText>
          Answered
        </AnsweredHeaderText>
        <AnsweredHeaderContainers>
          <div>
            15s
          </div>
          <div>
            60s
          </div>
          <div>
            90s
          </div>
          <div>
            Total
          </div>
        </AnsweredHeaderContainers>
      </AnsweredHeaderContainer>
    </HeaderCell>
    <HeaderCell width="250px">
      Total Calls Duration
    </HeaderCell>
  </HeadersContainer>
);

export default Headers;
