import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HamburgerMenu from '../SideBar/HeadBar/hamburgerMenu';
import PersonalStatistics from '../PersonalStatistics';
import SettingsModalWindow from '../SideBar/HeadBar/settingsModalWindow';
import ExitModalWindow from '../SideBar/HeadBar/ExitModalWindow';
import settingsSelectors from '../../redux/selectors/settings';
import { settingsActions } from '../../redux/reducers/settings';
import { modalsActions } from '../../redux/reducers/modals';
import modalsSelectors from '../../redux/selectors/modals';
import { Wrapper, Header } from './styled';

const ContainerStatistics = () => {
  const { settings: stateSettings, logout: stateLogout } = useSelector(modalsSelectors.getModals);
  const { volume, visibilityNotification, visibilityPushNotification } = useSelector(settingsSelectors.getSettings);
  const dispatch = useDispatch();

  const onClickSettings = () => {
    dispatch(modalsActions.setSettingsModalWindow(!stateSettings));
  };

  const onClickLogout = () => {
    dispatch(modalsActions.setLogoutModalWindow(!stateLogout));
  };

  const onClickVolume = (value) => {
    dispatch(settingsActions.setVolume(value));
    dispatch(settingsActions.onSendSettings());
  };

  const onClickNotification = () => {
    dispatch(settingsActions.setVisibilityNotification(!visibilityNotification));
    dispatch(settingsActions.onSendSettings());
  };

  const onClickPushNotification = () => {
    dispatch(settingsActions.setVisibilityPushNotification(!visibilityPushNotification));
    dispatch(settingsActions.onSendSettings());
  };

  const onClickGetViewFullVersion = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    params.delete('clientId');
    window.open(`${window.location.origin}?${params}`, 'CRM Chat', {
      menubar: 'no',
      toolbar: 'no',
      location: 'no',
      status: 'no',
    });
  };

  return (
    <Wrapper>
      <Wrapper.Header>
        <Header.Title>Statistics</Header.Title>
        <HamburgerMenu
          onClickSettings={onClickSettings}
          onClickLogOut={onClickLogout}
          onClickGetViewFullVersion={onClickGetViewFullVersion}
        />
      </Wrapper.Header>
      <Wrapper.Body>
        <PersonalStatistics />
      </Wrapper.Body>
      {stateLogout && <ExitModalWindow changeStatePopUp={onClickLogout} />}
      {stateSettings && (
        <SettingsModalWindow
          toggle={onClickSettings}
          volume={volume}
          notification={visibilityNotification}
          pushNotification={visibilityPushNotification}
          onClickVolume={onClickVolume}
          onClickNotification={onClickNotification}
          onClickPushNotification={onClickPushNotification}
        />
      )}
    </Wrapper>
  );
};

export default ContainerStatistics;
