import { createSlice } from '@reduxjs/toolkit';

const storeName = 'roomList';

const initialState = {
  state: true,
};

export const roomListSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const roomListActions = {
  setState: roomListSlice.actions.setState,
};

export const roomListReducer = roomListSlice.reducer;
