import React from 'react';
import Switch from 'react-switch';
import volumeFullIcon from '../../../../images/volumeFull.svg';
import volumeMiddleIcon from '../../../../images/volumeMiddle.svg';
import volumeNoneIcon from '../../../../images/volumeNone.svg';
import { Wrapper, Window, Header, Body, Volume } from './styled';

const SettingsModalWindow = ({
  volume,
  onClickVolume,
  notification,
  onClickNotification,
  toggle,
  pushNotification,
  onClickPushNotification,
}) => (
  <Wrapper>
    <Wrapper.Window>
      <Window.Header>
        <Header.Title>Settings</Header.Title>
        <Header.IconClose onClick={toggle} />
      </Window.Header>
      <Window.Body>
        <Body.SubTitle>Volume</Body.SubTitle>
        <Body.Volume>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <Volume.Image
            src={volumeNoneIcon}
            alt="none"
            active={volume === 0}
            onClick={() => onClickVolume(0)}
          />
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <Volume.Image
            src={volumeMiddleIcon}
            alt="middle"
            active={volume === 0.3}
            onClick={() => onClickVolume(0.3)}
          />
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <Volume.Image
            src={volumeFullIcon}
            alt="full"
            active={volume === 1}
            onClick={() => onClickVolume(1.0)}
          />
        </Body.Volume>
        <Body.SubTitle>Notification</Body.SubTitle>
        <Body.Notification>
          <Switch
            onChange={onClickNotification}
            checked={notification}
            onColor="#74c6f3"
            offColor="#888"
            uncheckedIcon=""
            checkedIcon=""
            height={22}
            width={45}
          />
        </Body.Notification>
        <Body.SubTitle>Push-notification</Body.SubTitle>
        <Body.Notification>
          <Switch
            onChange={onClickPushNotification}
            checked={pushNotification}
            onColor="#74c6f3"
            offColor="#888"
            uncheckedIcon=""
            checkedIcon=""
            height={22}
            width={45}
          />
        </Body.Notification>
      </Window.Body>
    </Wrapper.Window>
  </Wrapper>
);

export default SettingsModalWindow;
