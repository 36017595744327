import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatApi from '../../infra/api/ChatAPI/index';
import unreadMsgCountSaga from './unreadMsgCount';
import config from '../../config';
import { settingsActions } from '../reducers/settings';
import { unreadMsgCountActions } from '../reducers/unreadMsgCount';
import { roomListActions } from '../reducers/roomList';
import { managerInfoActions } from '../reducers/managerInfo';
import createActions from '../reducers/createActions';
import { logError } from '../../helper/sentry';

export default function* initChat(a) {
  try {
    const setLoadingVisibility = a.payload;

    const clientId = new URLSearchParams(window.location.search).get('clientId');

    yield put(roomListActions.setState(!clientId));
    setLoadingVisibility(false);
    yield put(createActions.onGetUserRooms({
      clientId,
      count: config.numberOfRoomsRequested,
      room_id: null,
      created_at: null,
      is_with_subordinates: false,
      sorting: {
        fieldName: 'lastMsgTime',
        orderBy: 'desc',
      },
    }));
    const { count, manager_id } = yield call(chatApi.getUnreadMsgCount);
    const { settings } = yield call(chatApi.setInitManagerSettings);
    yield put(settingsActions.setSettings(JSON.parse(settings)));
    yield put(unreadMsgCountActions.setState(count));
    yield put(managerInfoActions.setManagerId(manager_id));
    yield call(unreadMsgCountSaga, 0);
  } catch (error) {
    yield call(toast.error, `initChat-saga: ${error.message}`);
    logError(error);
  }
}
