import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import HeadBar from './HeadBar';
import ErrorBoundary from '../Common/ErrorBoundary';
import UserRoomsList from './UserRoomsList';

const SideBar = ({ intl }) => (
  <ErrorBoundary>
    <HeadBar intl={intl} />
    <UserRoomsList intl={intl} />
  </ErrorBoundary>
);

SideBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  intl: PropTypes.object.isRequired,
};

export default injectIntl(SideBar);
