import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { messagesMarkedDeletionFromMsgId } from '../../infra/api/ChatAPI';
import { messagesActions } from '../reducers/messages';
import messagesSelectors from '../selectors/messages';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* deleteHistoryMessagesSaga(action) {
  if (!action || !action.payload) return;
  try {
    const rooms = yield select(roomsSelectors.getRooms);
    const activeRoomId = yield select(roomsSelectors.getActiveRoomId);
    const messagesReducer = JSON.parse(JSON.stringify(yield select(messagesSelectors.getStore)));
    const { messages } = messagesReducer[activeRoomId];
    const room = rooms[activeRoomId];
    yield call(messagesMarkedDeletionFromMsgId, `${action.payload}`, room.client_id);
    messages.forEach((msg) => {
      if (msg.id <= action.payload) {
        // eslint-disable-next-line no-param-reassign
        msg.status = 3;
      }
    });
    yield put(messagesActions.setMessages({ messages, room_id: activeRoomId, rooms: messagesReducer }));
  } catch (error) {
    yield call(toast.error, `deleteHistoryMessages-saga: ${error.message}`);
    logError(error);
  }
}
