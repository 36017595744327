import styled from 'styled-components';

export const SideBarContainer = styled.div`
  flex: 0 0 350px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #202933;

  @media (max-width: 480px) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    visibility: ${({ visibility }) => (visibility ? 'visible' : 'hidden')};
    ${({ visibility }) => (visibility ? 'z-index: 1' : '')};
  }
`;

export const Content = styled.div`
  height: 100vh;
  flex: 1;
  min-width: 300px;
  @media (max-width: 1600px) {
    max-width: 1296px;
  }
  @media (max-width: 1536px) {
    max-width: 1230px;
  }
  @media (max-width: 1440px) {
    max-width: 1135px;
  }
  @media (max-width: 480px) {
    ${({ visibility }) => (visibility && `
        visibility: hidden;
        z-index: 1;
        min-width: 0;
        width: 0;
    `)};
  }
`;
