import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import unreadMsgCountSaga from './unreadMsgCount';
import managerInfoSelectors from '../selectors/managerInfo';
import messagesSelectors from '../selectors/messages';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* nextUnreadMessage(action) {
  try {
    // eslint-disable-next-line prefer-const
    let { roomId, msgId } = action.payload;
    if (roomId === '' || msgId < 0) return;
    const roomsState = yield select(roomsSelectors.getRooms);
    const messagesState = yield select(messagesSelectors.getStore);
    const { messages = [] } = messagesState[roomId] || {};
    const room = roomsState[roomId] || {};
    const { oldest_msg_id = 0, latest_msg = { id: 0 }, read_only: readOnly } = room;
    if (readOnly) return;
    const allMsgRead = oldest_msg_id === 0;
    const viewportBeforeUnreadMsg = msgId > 0 && oldest_msg_id > 0 && oldest_msg_id > msgId;
    const unreadMsgOld = room.unread_msg_count || 0;
    if (allMsgRead || viewportBeforeUnreadMsg) return;

    yield call(chatAPI.nextUnreadMessage, roomId, msgId);
    const managerID = yield select(managerInfoSelectors.getManagerId);

    let unreadMsgCount = 0;
    if (msgId > 0) {
      const allNewMessages = messages.filter((msg) => msg.id > msgId && msg.id <= latest_msg.id);
      const newMessages = allNewMessages.filter((msg) => msg.sender !== managerID);
      unreadMsgCount = newMessages.length;
    }

    yield put(roomsActions.setUpdateRoomSettings({
      id: roomId,
      room: {
        oldest_msg_id: msgId,
        unread_msg_count: unreadMsgCount,
      },
    }));
    const value = unreadMsgCount - unreadMsgOld;
    yield call(unreadMsgCountSaga, value);
  } catch (error) {
    yield call(toast.error, `nextUnreadMessage-saga: ${error.message}`);
    logError(error);
  }
}
