import { put, select, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from '../../constants/actions';
import messagesSelectors from '../selectors/messages';
import { messagesActions } from '../reducers/messages';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export function* readRoomMsgs(roomId, msgs) {
  for (let i = 0; i < msgs.length; i++) {
    const readMsg = { ...msgs[i], status: actions.MSG_STATUS_READ };
    yield put(messagesActions.setUpdateRoomMessage({ roomId, updatedMsg: readMsg }));
  }
}

export default function* clientMsgRead(action) {
  if (!action || !action.payload) return;
  try {
    const { client_id: clientId, messages } = action.payload;
    const rooms = yield select(roomsSelectors.getRooms);
    const roomsSlice = Object.values(rooms);
    const clientRoom = roomsSlice.filter((room) => room.client_id === clientId)[0];
    if (!clientRoom) return;
    const roomsMessages = yield select(messagesSelectors.getStore);
    const roomMessages = roomsMessages[clientRoom.id];
    if (!roomMessages) return;
    const readMessages = roomMessages.messages.filter((msg) => messages.includes(msg.id.toString()));
    yield call(readRoomMsgs, clientRoom.id, readMessages);
  } catch (error) {
    yield call(toast.error, `clientMsgRead-saga: ${error.message}`);
    logError(error);
  }
}
