import * as chatAPI from '../api/ChatAPI';
import { logError } from '../../helper/sentry';

let clientID;
let managerID;
let peerConnWiretapper;
let busyCB;
let firstICECandidate = true;

export const setClientID = (cID) => {
  clientID = cID;
};

export const setManagerID = (mID) => {
  managerID = mID;
};

export const setBusyCB = (fn) => {
  busyCB = fn;
};

const errorHandler = (error) => {
  console.log(error);
  logError(error);
};

export const hangUp = () => {
  if (peerConnWiretapper) {
    chatAPI.teamEndedWiretapper(managerID).catch(errorHandler);
    peerConnWiretapper.close();
    peerConnWiretapper = undefined;
    firstICECandidate = true;
  }
};

const peerStateChangeCB = () => {
  console.log('Peer con state changed: ', peerConnWiretapper.iceConnectionState);
  switch (peerConnWiretapper.iceConnectionState) {
  case 'failed':
  case 'disconnected':
    hangUp();
    break;
  case 'connected':
    break;
  default:
    break;
  }
};

const gotRemoteStream = (event) => {
  const remoteAudioStreamContainer = new Audio();
  // eslint-disable-next-line prefer-destructuring
  remoteAudioStreamContainer.srcObject = event.streams[0];
  remoteAudioStreamContainer.play().catch(errorHandler);
};

const gotIceCandidate = (event) => {
  if (event.candidate) {
    if (firstICECandidate) {
      firstICECandidate = false;
    } else {
      setTimeout(() => {
        chatAPI.teamIceWiretapper(managerID, event.candidate.toJSON()).catch(errorHandler);
      }, 1000);
    }
  }
};

const initWiretapperPeer = (settings) => {
  peerConnWiretapper = new RTCPeerConnection({ iceServers: [settings] });
  peerConnWiretapper.ontrack = gotRemoteStream;
  peerConnWiretapper.oniceconnectionstatechange = peerStateChangeCB;
  peerConnWiretapper.onicecandidate = gotIceCandidate;
  peerConnWiretapper.createOffer({ offerToReceiveAudio: true })
    .then((data) => {
      peerConnWiretapper.setLocalDescription(data).then(() => {
        chatAPI.teamManagerOffer(clientID, data.sdp).catch(errorHandler);
      });
    });
};

export const getIceServer = () => {
  chatAPI.teamGetIceServer().then((res) => {
    initWiretapperPeer(res.ice_server);
  }).catch(errorHandler);
};

chatAPI.onManagerAnswerWiretapper((data) => {
  if (!data.args.busy) {
    peerConnWiretapper.setRemoteDescription(new RTCSessionDescription({
      sdp: data.args.sdp,
      type: 'answer',
    })).catch(errorHandler);
  } else {
    hangUp();
    busyCB();
  }
});

chatAPI.onManagerIceWiretapper((data) => {
  if (peerConnWiretapper) {
    const iceCandidate = new RTCIceCandidate(data.args.ice);
    peerConnWiretapper.addIceCandidate(iceCandidate).catch(errorHandler);
  }
});
