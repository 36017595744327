import { createSlice } from '@reduxjs/toolkit';

const storeName = 'searchList';

const initialState = {
  state: [],
};

export const searchListSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const searchListActions = {
  setState: searchListSlice.actions.setState,
};

export const searchListReducer = searchListSlice.reducer;
