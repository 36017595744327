import React, { useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { notifyActions } from '../../../redux/reducers/notify';
import notifySelectors from '../../../redux/selectors/notify';

const NotifyWrap = () => {
  const dispatch = useDispatch();
  const notify = useSelector(notifySelectors.getState);
  const clearNotify = () => {
    dispatch(notifyActions.clear());
  };
  const createNotification = (type, message) => {
    switch (type) {
    case 'info':
      NotificationManager.info(message, 'Info', 5000, null, true);
      break;
    case 'success':
      NotificationManager.success({ id: message }, 'Success', 5000, null, true);
      break;
    default:
      NotificationManager.error(message, 'Error', 5000, null, true);
    }
  };

  useEffect(() => {
    const { type, message } = notify;
    if (type && message) {
      createNotification(type, 'Server error, please try again later.');
      clearNotify();
    }
  }, [notify]);

  return <NotificationContainer />;
};

export default NotifyWrap;
