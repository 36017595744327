import { put, call } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import config from '../../config';
import { visibleComponentsActions } from '../reducers/visibleComponents';
import { managerInfoActions } from '../reducers/managerInfo';
import { logError } from '../../helper/sentry';

export default function* auth(action) {
  try {
    if (!action || !action.payload) {
      return;
    }

    const setupFn = action.payload;

    // try auth with token
    const token = new URLSearchParams(document.location.search).get('token');
    if (token) {
      yield call(setupFn, token);
      return;
    }

    const resp = yield call(fetch, `${config.backendManagerChatApi}/login`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    });

    if (resp.status === 200) {
      yield call(setupFn);
    } else {
      yield put(visibleComponentsActions.setLoginVisibility(true));
    }

    const managerID = Cookies.get('manager') || '';
    console.log(managerID);
    yield put(managerInfoActions.setState({ managerID, isGuest: false }));
  } catch (e) {
    yield call(toast.error, `Auth-saga: ${e.message}`);
    logError(e);
  }
}
