import React from 'react';
import PropTypes from 'prop-types';
import { Container, Wrapper } from './styled';

const Placeholder = ({ text }) => (
  <Wrapper>
    <Wrapper.Container>
      <Container.Text>{text}</Container.Text>
    </Wrapper.Container>
  </Wrapper>
);

Placeholder.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Placeholder;
