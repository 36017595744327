import styled, { keyframes } from 'styled-components';

const size = '50px';
const thickness = '4px';
const duration = '1s';

const first = keyframes`
  0% { 
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); 
  }
  100% { 
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); 
  }
`;

const second = keyframes`
  0% { 
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); 
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
`;

const third = keyframes`
  0% { 
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); 
  }
  100% { 
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); 
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: linear-gradient(to bottom, #4caefb, #f9ffe4);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #dbeeff
`;

export const Preloader = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: relative;
  width: ${size};
  height: ${size};
`;

export const First = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${duration} linear infinite;
  border-bottom: ${thickness} solid #3b99f8;
  animation-name: ${first};
`;

export const Second = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${duration} linear infinite;
  border-right: ${thickness} solid #d28908;
  animation-name: ${second};
`;

export const Third = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${duration} linear infinite;
  border-top: ${thickness} solid #0ab405;
  animation-name: ${third};
`;

Wrapper.Container = Container;

Container.User = User;
Container.Preloader = Preloader;

User.Image = Image;

Preloader.Content = Content;

Content.First = First;
Content.Second = Second;
Content.Third = Third;
