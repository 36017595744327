import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import managerPic from '../../../../images/managerMenu.svg';
import arrowPic from '../../../../images/arrowLeft.svg';
import managerInfoSelectors from '../../../../redux/selectors/managerInfo';
import { Menu, Wrapper, Content, Header, ManagerInfo, ButtonHide, Items, Block } from './styled';

const HamburgerMenu = ({ onClickSettings, onClickLogOut, onClickGetViewFullVersion }) => {
  const managerId = useSelector(managerInfoSelectors.getManagerId);
  const [state, setState] = useState(false);

  const onClickItem = (fn) => {
    setState(false);
    fn();
  };

  const onClickChat = () => {
    setState(false);
  };

  const onClickPersonalStatistics = () => {
    setState(false);
  };

  // eslint-disable-next-line
  const isFramed = window !== window.top || document !== top.document || self.location !== top.location;
  const isActiveStatistics = document.location.href === '/statistics';

  return (
    <>
      <Menu onClick={() => setState(!state)}>
        <Menu.Dot />
        <Menu.Dot />
        <Menu.Dot />
      </Menu>
      <Wrapper active={state}>
        <Wrapper.Background onClick={() => setState(!state)} />
        <Wrapper.Content active={state}>
          <Content.Header>
            <Header.ManagerInfo>
              <ManagerInfo.ImageManager src={managerPic} alt="manager" />
              <ManagerInfo.Id>{managerId}</ManagerInfo.Id>
            </Header.ManagerInfo>
            <Header.ButtonHide onClick={() => setState(!state)}>
              <ButtonHide.ImageArrow src={arrowPic} alt="close" />
            </Header.ButtonHide>
          </Content.Header>
          <Content.Items>
            <Items.Block>
              <Block.Item onClick={onClickChat} active={!isActiveStatistics} to="/">Chat</Block.Item>
              <Block.Item onClick={onClickPersonalStatistics} active={isActiveStatistics} to="/statistics">
                Statistics
              </Block.Item>
            </Items.Block>
            <Items.Block>
              {isFramed && (
                <Block.Item onClick={() => onClickItem(onClickGetViewFullVersion)}>View full version</Block.Item>
              )}
              <Block.Item onClick={() => onClickItem(onClickSettings)}>Settings</Block.Item>
              {!isFramed && <Block.Item onClick={() => onClickItem(onClickLogOut)}>Log out</Block.Item>}
            </Items.Block>
          </Content.Items>
        </Wrapper.Content>
      </Wrapper>
    </>
  );
};

export default HamburgerMenu;
