import WsConnector from './wsConnector';

// ChatAPI is used for data interaction between BE and FE over websocket.
// If response is valid, it should return response args (!!!) which contains appropriate data
// It uses custom event protocol. To figure out details about the protocol, follow the link below.
// https://maxiproject.atlassian.net/wiki/spaces/GOP/pages/1517420603/Protocol+STChat+iFrame+-+BE

const wsConnector = new WsConnector();

export const start = (params) => {
  wsConnector.start(params);
};

export const onConnected = (connectedCb) => {
  wsConnector.onConnected(connectedCb);
};

export const onDisconnected = (disconnectedCb) => {
  wsConnector.onDisconnected(disconnectedCb);
};

export const onUnauthorized = (unauthorizedCb) => {
  wsConnector.onUnauthorized(unauthorizedCb);
};

// getUserRooms sends message with get_user_rooms command to BE
export const getUserRooms = (requestArgs = {}) => wsConnector.requestProcessing({
  requestCmd: 'get_user_rooms',
  requestArgs,
  responseCmd: 'get_user_rooms_response',
});

export const getUserRoom = (requestArgs = {}) => wsConnector.requestProcessing({
  requestCmd: 'get_user_room',
  requestArgs,
  responseCmd: 'get_user_room_response',
});

export const getRecordings = (lead_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_get_recordings',
  requestArgs: {
    lead_id,
  },
  responseCmd: 'manager_get_recordings_response',
});

// getRoomMessages sends message with get_room_messages command,
// count, room id and time in args to BE
export const getRoomMessages = (room_id, msg_id, count) => wsConnector.requestProcessing({
  requestCmd: 'get_room_messages',
  requestArgs: {
    room_id,
    msg_id,
    count,
  },
  responseCmd: 'get_room_messages_response',
});

// sendMessage sends message with new_message command to BE
export const sendMessage = (message) => wsConnector.requestProcessing({
  requestCmd: 'new_message',
  isIdPresent: true,
  requestArgs: message,
  responseCmd: 'new_message_response',
});

export const sendEditMessage = (message) => wsConnector.requestProcessing({
  requestCmd: 'manager_edited_msg',
  isIdPresent: true,
  requestArgs: message,
  responseCmd: 'manager_edited_msg_response',
});

// nextUnreadMessage sends message with next_unread_message command
// and message id with room_id in args to BE
export const nextUnreadMessage = (room_id, message_id) => wsConnector.requestProcessing({
  requestCmd: 'next_unread_message',
  requestArgs: {
    room_id,
    message_id,
  },
  responseCmd: 'next_unread_message_response',
});

// getUnreadMessages sends message with get_room_unread_messages command
// and roomId, fromMessageId, count args to BE
export const getRoomUnreadMessages = (room_id, from_message_id, count) => wsConnector.requestProcessing({
  requestCmd: 'get_room_unread_messages',
  requestArgs: {
    room_id,
    from_message_id,
    count,
  },
  responseCmd: 'get_room_unread_messages_response',
});

// deleteRoom sends message with delete_room command
// and room id args to BE
export const closeRoom = (room_id) => wsConnector.requestProcessing({
  requestCmd: 'close_room',
  requestArgs: {
    room_id,
  },
  responseCmd: 'close_room_response',
});

export const updateUserRooms = (tags) => wsConnector.requestProcessing({
  requestCmd: 'update_user_rooms',
  requestArgs: {
    tags,
  },
  responseCmd: 'update_user_rooms_response',
});

export const getRoomImageLinks = (room_id) => wsConnector.requestProcessing({
  requestCmd: 'get_room_images',
  requestArgs: {
    room_id,
  },
  responseCmd: 'get_room_images_response',
});

export const getClientsList = (search_string) => wsConnector.requestProcessing({
  requestCmd: 'get_clients_list',
  requestArgs: {
    search_string,
  },
  responseCmd: 'get_clients_list_response',
});

export const managerOffer = (sdp, client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_offer',
  isIdPresent: true,
  requestArgs: {
    sdp,
    client_id,
  },
  responseCmd: 'manager_offer_response',
});

export const managerIce = (ice, client_id, priority) => wsConnector.requestProcessing({
  requestCmd: 'manager_ice',
  isIdPresent: true,
  requestArgs: {
    ice,
    client_id,
    priority,
  },
  responseCmd: 'manager_ice_response',
});

export const managerStopCall = (client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_stop_call',
  requestArgs: {
    client_id,
  },
  responseCmd: 'manager_stop_call_response',
});

export const managerStopOfflineCall = (client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_stop_offline_call',
  requestArgs: {
    client_id,
  },
  responseCmd: 'manager_stop_offline_call_response',
});

export const managerStartRecord = (sdp, client_id, iceServer) => wsConnector.requestProcessing({
  requestCmd: 'manager_start_record',
  requestArgs: {
    sdp,
    client_id,
    iceServer,
  },
  responseCmd: 'manager_start_record_response',
});

export const managerIceRecord = (ice, client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_ice_record',
  isIdPresent: true,
  requestArgs: {
    ice,
    client_id,
  },
  responseCmd: 'manager_ice_record_response',
});

export const managerStopRecord = (client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_stop_record',
  requestArgs: {
    client_id,
  },
  responseCmd: 'manager_stop_record_response',
});

export const managerGetIceServer = () => wsConnector.requestProcessing({
  requestCmd: 'manager_get_ice_server',
  responseCmd: 'manager_get_ice_server_response',
});

export const managerCallEstablished = (client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_call_established',
  requestArgs: {
    client_id,
  },
  responseCmd: 'manager_call_established_response',
});

export const managerCallEnded = (client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_call_ended',
  requestArgs: {
    client_id,
  },
  responseCmd: 'manager_call_ended_response',
});

export const tryBindClient = (search_client_id) => wsConnector.requestProcessing({
  requestCmd: 'try_bind_client',
  requestArgs: {
    search_client_id,
  },
  responseCmd: 'try_bind_client_response',
  isIdPresent: true,
});

export const teamGetIceServer = () => wsConnector.requestProcessing({
  requestCmd: 'team_get_ice_server',
  responseCmd: 'team_get_ice_server_response',
});

export const teamManagerOffer = (client_id, sdp) => wsConnector.requestProcessing({
  requestCmd: 'team_offer_wiretapper',
  requestArgs: {
    client_id,
    sdp,
  },
  responseCmd: 'team_offer_wiretapper_response',
});

export const managerAnswerWiretapper = (team_id, sdp, busy) => wsConnector.requestProcessing({
  requestCmd: 'manager_answer_wiretapper',
  requestArgs: {
    team_id,
    sdp,
    busy,
  },
  responseCmd: 'manager_answer_wiretapper_response',
});

export const managerIceWiretapper = (team_id, ice) => wsConnector.requestProcessing({
  requestCmd: 'manager_ice_wiretapper',
  requestArgs: {
    team_id,
    ice,
  },
  responseCmd: 'manager_ice_wiretapper_response',
});

export const teamIceWiretapper = (manager_id, ice) => wsConnector.requestProcessing({
  requestCmd: 'team_ice_wiretapper',
  requestArgs: {
    manager_id,
    ice,
  },
  responseCmd: 'team_ice_wiretapper_response',
});

export const teamEndedWiretapper = (manager_id) => wsConnector.requestProcessing({
  requestCmd: 'team_ended_wiretapper',
  requestArgs: {
    manager_id,
  },
  responseCmd: 'team_ended_wiretapper_response',
});

export const managerStartOfflineCall = (client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_start_offline_call',
  isIdPresent: true,
  requestArgs: {
    client_id,
  },
  responseCmd: 'manager_start_offline_call_response',
});

export const getUnreadMsgCount = () => wsConnector.requestProcessing({
  requestCmd: 'get_unread_msg_count',
  responseCmd: 'get_unread_msg_count_response',
});

export const messagesMarkedDeletion = (msgs_ids, client_id, manager_id) => wsConnector.requestProcessing({
  requestCmd: 'messages_marked_deletion',
  requestArgs: {
    msgs_ids,
    client_id,
    manager_id,
  },
  responseCmd: 'messages_marked_deletion_response',
});

export const messagesMarkedDeletionFromMsgId = (msg_id, client_id) => wsConnector.requestProcessing({
  requestCmd: 'manager_marked_deletion_from_msg_id',
  requestArgs: {
    msg_id,
    client_id,
  },
  responseCmd: 'manager_marked_deletion_from_msg_id_response',
});

export const setManagerSettings = (settings) => wsConnector.requestProcessing({
  requestCmd: 'set_manager_settings',
  requestArgs: {
    settings,
  },
  responseCmd: 'set_manager_settings_response',
});

export const setNotificationToken = (token) => wsConnector.requestProcessing({
  requestCmd: 'set_notification_token',
  requestArgs: {
    token,
  },
  responseCmd: 'set_notification_token_response',
});

export const setInitManagerSettings = () => wsConnector.requestProcessing({
  requestCmd: 'manager_settings',
  responseCmd: 'manager_settings_response',
});

export const changedClientStatuses = (callback) => {
  wsConnector.register('changed_client_statuses', callback);
};

// onMsg registers callback for new_message message
export const onMsg = (callback) => {
  wsConnector.register('new_message', callback);
};

export const onClientEditedMsg = (callback) => {
  wsConnector.register('client_edited_msg', callback);
};

// onUsersDeletedFromRoom registers callback for room_settings_updated message
export const onRoomSettingsUpdated = (callback) => {
  wsConnector.register('room_settings_updated', callback);
};

// onNextUnreadId registers callback for next_unread_id_event message
export const onNextUnreadId = (callback) => {
  wsConnector.register('next_unread_id_event', callback);
};

// onRoomDeleted registers callback for close_room message
export const onRoomClosed = (callback) => {
  wsConnector.register('close_room', callback);
};

// onRoomDeleted registers callback for start_dialog message
export const onDialogStarted = (callback) => {
  wsConnector.register('start_dialog', callback);
};

export const onUserRoomUpdated = (callback) => {
  wsConnector.register('update_room', callback);
};

export const onHiddenTags = (callback) => {
  wsConnector.register('hidden_tags', callback);
};

export const onClientCallAnswer = (callback) => {
  wsConnector.register('client_answer', callback);
};

export const onClientCallDelcine = (callback) => {
  wsConnector.register('client_offer_decline', callback);
};

export const onClientCallDeclineNotification = (callback) => {
  wsConnector.register('client_notification_decline_call', callback);
};

export const onClientCallStop = (callback) => {
  wsConnector.register('client_stop_call', callback);
};

export const onClientIce = (callback) => {
  wsConnector.register('client_ice', callback);
};

export const onRecorderIce = (callback) => {
  wsConnector.register('recorder_ice', callback);
};

export const onClientMsgRead = (callback) => {
  wsConnector.register('client_msg_read', callback);
};

export const onClientConnected = (callback) => {
  wsConnector.register('client_connected', callback);
};

export const onClientDisconnected = (callback) => {
  wsConnector.register('client_disconnected', callback);
};

export const onManagerCallEstablished = (callback) => {
  wsConnector.register('manager_call_established', callback);
};

export const onManagerCallEnded = (callback) => {
  wsConnector.register('manager_call_ended', callback);
};

export const onTeamOfferWireTapper = (callback) => {
  wsConnector.register('team_offer_wiretapper', callback);
};

export const onManagerIceWiretapper = (callback) => {
  wsConnector.register('manager_ice_wiretapper', callback);
};

export const onTeamIceWiretapper = (callback) => {
  wsConnector.register('team_ice_wiretapper', callback);
};

export const onManagerAnswerWiretapper = (callback) => {
  wsConnector.register('manager_answer_wiretapper', callback);
};

export const onTeamEndedWiretapper = (callback) => {
  wsConnector.register('team_ended_wiretapper', callback);
};

export const onGetUserRoomResponse = (callback) => {
  wsConnector.register('get_user_assigned_rooms', callback);
};
