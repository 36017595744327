import styled from 'styled-components';
import { VscClose, AiOutlineFile } from 'react-icons/all';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Window = styled.div`
  background: #fff;
  color: black;
  max-width: 360px;
  width: 100%;
  border-radius: .3rem;
  
  @media (max-width: 400px) {
    max-width: 320px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

export const Title = styled.h5`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
`;

export const IconClose = styled(VscClose)`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  position: relative;
  max-height: 200px;
  overflow-y: auto;
`;

export const File = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Icon = styled(AiOutlineFile)`
  background: #007bff;
  border-radius: 50%;
  padding: 7px;
  width: 20px;
  height: 20px;
  fill: white;
`;

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  width: 75%;
  padding-left: 10px;
`;

export const Delete = styled(VscClose)`
  margin-left: 20px;
  cursor: pointer;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ButtonClose = styled.div`
  color: #ffffff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: all .3s cubic-bezier(.47,0,.745,.715);
  margin-left: 10px;
  margin-right: 1px;
  background-color: #6c757d;
  
  &:hover {
    background-color: #5a6268;
  }
`;

export const ButtonSend = styled.button`
  color: #ffffff;
  background-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: all .3s cubic-bezier(.47,0,.745,.715);
  margin-left: 10px;
  margin-right: 1px;
  
  &:hover {
    background-color: #228cdb;
  }
`;

Wrapper.Window = Window;

Window.Header = Header;
Window.Body = Body;
Window.Footer = Footer;

Header.Title = Title;
Header.IconClose = IconClose;

Body.File = File;

File.Icon = Icon;
File.Name = Name;
File.Delete = Delete;

Footer.ButtonClose = ButtonClose;
Footer.ButtonSend = ButtonSend;
