import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #81a1d0;
  font-size: 12px;
  width: 100%;
`;

export const Text = styled.span`
  padding: 7px 5px;
  width: 50%;
  text-align: center;
  word-break: break-word;
`;

Wrapper.Text = Text;
