import React from 'react';
import ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { VscClose } from 'react-icons/all';
import { modalsActions } from '../../../../redux/reducers/modals';
import modalsSelectors from '../../../../redux/selectors/modals';
import { Modal, Window, Header, Body, Footer } from './styled';

const WarningModalWindow = ({ intl }) => {
  const {
    warning: { msg, enabled, title },
  } = useSelector(modalsSelectors.getModals);
  const dispatch = useDispatch();

  const onOk = () => {
    dispatch(modalsActions.closeWarningModal());
  };

  const warningText = intl.formatMessage({ id: 'warningHeader' });

  if (!enabled) {
    return <></>;
  }
  return (
    ReactDOM.createPortal(
      <Modal>
        <Modal.Window>
          <Window.Header>
            <Header.Title>{warningText}</Header.Title>
            <VscClose onClick={onOk} />
          </Window.Header>
          <Window.Body>
            <Body.Text>{title}</Body.Text>
            <Body.Text>{msg}</Body.Text>
          </Window.Body>
          <Window.Footer>
            <Footer.Button onClick={onOk}>Close</Footer.Button>
          </Window.Footer>
        </Modal.Window>
      </Modal>,
      document.getElementById('modal'),
    )
  );
};

export default injectIntl(WarningModalWindow);
