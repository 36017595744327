import React from 'react';
import { BiCopy } from 'react-icons/all';
import { toast } from 'react-toastify';
import { Wrapper } from './styled';

const NameDisplay = ({ clientId, title }) => {
  const onClick = (e) => {
    navigator.clipboard.writeText(clientId);
    toast('ID copied.');
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <Wrapper.Text>{`(${clientId} `}</Wrapper.Text>
      <Wrapper.Copy>
        <BiCopy onClick={onClick} color="#000" />
      </Wrapper.Copy>
      <Wrapper.Text>{` ) ${title}`}</Wrapper.Text>
    </Wrapper>
  );
};

export default NameDisplay;
