import { createSlice } from '@reduxjs/toolkit';

const storeName = 'chatMessagesUpdater';

const initialState = {
  room_id: '',
  client_id: '',
};

export const chatMessagesUpdaterSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      const { room_id, client_id } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state = { ...state, room_id, client_id };
    },
  },
});

export const chatMessagesUpdaterActions = {
  setState: chatMessagesUpdaterSlice.actions.setState,
};

export const chatMessagesUpdaterReducer = chatMessagesUpdaterSlice.reducer;
