import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* onGetUserRooms(a) {
  if (!a || !a.payload) return;

  try {
    const roomsStore = yield select(roomsSelectors.getRooms);
    const args = { ...a.payload };
    if (!Array.isArray(args.rooms)) {
      args.rooms = [];
    }
    const rooms = {};
    args.rooms.forEach((room) => {
      const { id } = room;
      rooms[id] = { ...room };
    });
    const allRooms = Object.assign(roomsStore, rooms);
    yield put(roomsActions.setUserRooms({
      rooms: { ...allRooms },
      active_room_id: '',
    }));
  } catch (error) {
    yield call(toast.error, `onGetUserRooms-saga: ${error.message}`);
    logError(error);
  }
}
