import { createSlice } from '@reduxjs/toolkit';

const storeName = 'roomIdWithActiveCall';

const initialState = {
  state: '',
};

export const roomIdWithActiveCallSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const roomIdWithActiveCallActions = {
  setState: roomIdWithActiveCallSlice.actions.setState,
};

export const roomIdWithActiveCallReducer = roomIdWithActiveCallSlice.reducer;
