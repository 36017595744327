import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import messagesSelectors from '../selectors/messages';
import { messagesActions } from '../reducers/messages';
import { imageLinksActions } from '../reducers/imageLinks';
import imageLinksSelectors from '../selectors/imageLinks';
import config from '../../config';
import { logError } from '../../helper/sentry';

export default function* getHistoryMessages(action) {
  if (!action || !action.payload) return;
  const { roomId, msgId, count } = action.payload;

  if (typeof roomId !== 'string' || roomId.length === 0) return;

  if (typeof msgId !== 'number' || msgId <= 0) return;

  if (typeof count !== 'number' || count <= 0) return;

  try {
    const messagesStore = yield select(messagesSelectors.getStore);
    const { rooms } = JSON.parse(JSON.stringify(yield select(imageLinksSelectors.getImageLinks)));
    const reached = messagesStore[roomId]?.historyEndReached;

    if (reached) return;

    const response = yield call(chatAPI.getRoomMessages, roomId, msgId, count);
    const { images } = yield call(chatAPI.getRoomImageLinks, roomId);

    const { room_id: respRoomID, messages, expiration_time } = response;

    if (respRoomID !== roomId) return;

    if (!Array.isArray(messages) || messages.length < count) {
      yield put(messagesActions.setHistoryEndReached({ roomId }));
    }

    if (Array.isArray(messages)) {
      yield put(messagesActions.setMessages({
        messages,
        roomId,
        expirationTime: expiration_time,
        rooms: messagesStore,
      }));
    }

    if (Array.isArray(images)) {
      rooms[roomId] = images.map((item) => `${config.baseFileStorageURL}/${item}`);
      yield put(imageLinksActions.setImageLinks(rooms));
    }
  } catch (error) {
    yield call(toast.error, `getHistoryMessages-saga: ${error.message}`);
    logError(error);
  }
}
