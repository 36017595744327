import emoji from 'react-easy-emoji';
import { logError } from './sentry';

const getEmojiWrap = (text) => {
  if (typeof text === 'string') {
    try {
      return emoji(text);
    } catch (e) {
      console.error(e.message);
      logError(e);
    }
  }
  return text;
};

export default getEmojiWrap;
