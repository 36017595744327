import { call, put, select, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export function* clientConnected(action) {
  const room = action.payload;
  const rooms = yield select(roomsSelectors.getRooms);
  if (rooms[room.id] === undefined) {
    yield put(roomsActions.setUpdateRoomSettings({ id: room.id, room }));
    return;
  }
  yield put(roomsActions.setClientStatus(room));
}

export function* clientDisconnected(action) {
  const room = action.payload;
  const rooms = yield select(roomsSelectors.getRooms);
  if (rooms[room.id] === undefined) return;
  yield put(roomsActions.setClientStatus(room));
}

export function* changeClientStatuses(action) {
  if (!action || !action.payload) {
    return;
  }
  try {
    const clients = action.payload;
    const rooms = yield select(roomsSelectors.getRooms);
    // eslint-disable-next-line array-callback-return,consistent-return
    yield all(clients.map((client) => {
      if (rooms[client.room_id].client_status !== client.status) {
        return put(roomsActions.setClientStatus({
          id: client.room_id,
          clientStatus: client.status,
        }));
      }
    }));
  } catch (e) {
    yield call(toast.error, 'changeClientStatus-saga: Client status determination error');
    logError(e);
  }
}
