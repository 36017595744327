import styled from 'styled-components';
import { VscClose } from 'react-icons/all';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Window = styled.div`
  background: #fff;
  color: black;
  max-width: 360px;
  width: 100%;
  border-radius: .3rem;
  
  @media (max-width: 400px) {
    max-width: 320px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

export const Title = styled.h5`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
`;

export const IconClose = styled(VscClose)`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  position: relative;
`;

export const Input = styled.input`
  width: 320px;
  height: 25px;
  border: 1px solid ${({ state, validation }) => {
    if (state) return '#e9ecef';
    return validation ? '#4A9D45' : '#d40a0a';
  }};
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  outline: none;
  padding-left: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
`;

export const ErrorMessage = styled.p`
  padding: 0;
  margin: 5px 0 0 0;
  font-size: 14px;
  text-align: center;
  color: #d40a0a;
`;

export const Results = styled.div`
  width: 325px;
  border: 1px solid #e9ecef;
  border-top: 0;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
  position: absolute;
  top: calc(100% - 1rem);
  background: #fff;
  
  @media (max-width: 400px) {
    width: 285px;
  }
`;

export const Item = styled.div`
  padding: 5px;
  cursor: pointer;
  word-break: break-word;
  
  &:hover {
    transition: all .1s cubic-bezier(.47,0,.745,.715);
    color: white;
    background-color: #007bff;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ButtonClose = styled.div`
  color: #ffffff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: all .3s cubic-bezier(.47,0,.745,.715);
  margin-left: 10px;
  margin-right: 1px;
  background-color: #6c757d;
  
  &:hover {
    background-color: #5a6268;
  }
`;

export const ButtonSend = styled.button`
  color: #ffffff;
  background-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: all .3s cubic-bezier(.47,0,.745,.715);
  margin-left: 10px;
  margin-right: 1px;
  
  &:hover {
    background-color: #228cdb;
  }
`;

Wrapper.Window = Window;

Window.Header = Header;
Window.Body = Body;
Window.Footer = Footer;

Header.Title = Title;
Header.IconClose = IconClose;

Body.Input = Input;
Body.ErrorMessage = ErrorMessage;
Body.Results = Results;

Results.Item = Item;

Footer.ButtonClose = ButtonClose;
Footer.ButtonSend = ButtonSend;
