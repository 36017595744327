import { createSlice } from '@reduxjs/toolkit';

const storeName = 'notify';

const initialState = {
  state: {},
};

export const notifySlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
    // eslint-disable-next-line no-unused-vars
    clear(state) {
      // eslint-disable-next-line no-param-reassign
      state.state = initialState;
    },
  },
});

export const notifyActions = {
  setState: notifySlice.actions.setState,
  clear: notifySlice.actions.clear,
};

export const notifyReducer = notifySlice.reducer;
