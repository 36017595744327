import React from 'react';
import moment from 'moment';
// eslint-disable-next-line
import { ContextMenuTrigger } from 'react-contextmenu';
import { useSelector } from 'react-redux';
import { MdEdit } from 'react-icons/md';
import deliveredSvg from '../../../../../images/delivered.svg';
import clientSvg from '../../../../../images/client.svg';
import readSvg from '../../../../../images/read.svg';
import basketSvg from '../../../../../images/basket.svg';
import { Container, UserBox, Wrapper, Menu, Select, MessageBox } from './styled';
import messageSelectionSelectors from '../../../../../redux/selectors/messageSelection';

const CompositionMessage = (props) => {
  // eslint-disable-next-line
  const { message, isMyMsg, timeDivider, room, clientId, action, expirationState, children } = props;
  // eslint-disable-next-line
  const { id, time, status, type } = message;
  const { selectedMessages, visibility } = useSelector(messageSelectionSelectors.getMessageSelection);
  // eslint-disable-next-line
  const { read_only: readOnly, id: roomId } = room;

  const getUserBoxIcon = () => {
    switch (status) {
    case 1: return isMyMsg ? deliveredSvg : clientSvg;
    case 2: return isMyMsg ? readSvg : clientSvg;
    case 3: return basketSvg;
    default: return null;
    }
  };

  const deleteMessage = status === 3;
  const isVisibilitySelectMessage = visibility && isMyMsg && status !== 3;
  const isVisibilityEditMenuItem = message.type === 0 || message.type === 4;
  const isVisibilityClientContextMenu = !visibility && status !== 3 && !readOnly && !isMyMsg;
  const isVisibilityManagerContextMenu = !visibility && status !== 3 && !readOnly && isMyMsg;
  const isVisibilityReadOnlyRoomContextMenu = !visibility && status !== 3 && readOnly && isMyMsg;

  return (
    <Wrapper isMyMsg={isMyMsg}>
      {isVisibilitySelectMessage && (
        <Wrapper.Select>
          <Select.Input
            type="checkbox"
            id={id}
            checked={selectedMessages.includes(id)}
            onClick={() => action.visibility(id)}
          />
          <Select.Label htmlFor={id} />
        </Wrapper.Select>
      )}
      <Wrapper.UserBox>
        <UserBox.Container>
          <Container.Icon src={getUserBoxIcon()} alt="" isMyMsg={isMyMsg} msgDelete={deleteMessage} />
        </UserBox.Container>
        <UserBox.Time>{moment(time).format('hh:mm')}</UserBox.Time>
      </Wrapper.UserBox>
      <Wrapper.MessageBox isMyMsg={isMyMsg}>
        {type === 4 && (
          <MessageBox.Edit>
            <MdEdit />
          </MessageBox.Edit>
        )}
        <ContextMenuTrigger id={`${id}`}>{children}</ContextMenuTrigger>
        {isVisibilityReadOnlyRoomContextMenu && (
          <Menu id={`${id}`}>
            <Menu.Item onClick={() => action.deleteMsgs([id])}>Delete Message</Menu.Item>
          </Menu>
        )}
        {isVisibilityClientContextMenu && (
          <Menu id={`${id}`}>
            <Menu.Item onClick={() => action.setQuotedMessage(message)}>Quote</Menu.Item>
            <Menu.Item onClick={() => action.deleteHistory(id)}>Archive from current message</Menu.Item>
          </Menu>
        )}
        {isVisibilityManagerContextMenu && (
          <Menu id={`${id}`}>
            <Menu.Item onClick={() => action.setQuotedMessage(message)}>Quote</Menu.Item>
            {isVisibilityEditMenuItem && (
              <Menu.Item onClick={() => action.editMsg(id, message.text)}>Edit</Menu.Item>
            )}
            <Menu.Item onClick={() => action.deleteMsgs([id])}>Delete Message</Menu.Item>
            <Menu.Item onClick={() => action.deleteHistory(id)}>Archive from current message</Menu.Item>
            <Menu.Item divider />
            <Menu.Item onClick={() => action.visibility(id)}>Select Message</Menu.Item>
          </Menu>
        )}
      </Wrapper.MessageBox>
    </Wrapper>
  );
};

export default CompositionMessage;
