import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Menu = styled.div`
  height: 22px;
  width: 30px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 10px;
`;

export const Dot = styled.div`
  display: block;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: #74C6F3FF;
`;

export const Wrapper = styled.div`
  transform: translate(${({ active }) => (active ? 0 : '-150')}%);
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: visibility 350ms ease-in-out;
  transition: opacity 350ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
`;

export const Background = styled.div`
  background-color: rgba(14,17,24,.7);
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-width: 350px;
  width: 100%;
  transform: translate(${({ active }) => (active ? 0 : '-150')}%);
  transition: transform 350ms ease-in-out;
  display: flex;
  flex-direction: column;
  background: #202933;

  @media (max-width:480px)  {
      max-width: 480px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

export const ManagerInfo = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageManager = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 4px;
  filter: invert(69%) sepia(16%) saturate(1221%) hue-rotate(164deg) brightness(105%) contrast(91%);
`;

export const Id = styled.div`
  color: #75c6f5;
`;

export const ButtonHide = styled.div``;

export const ImageArrow = styled.img`
  width: 20px;
  filter: invert(69%) sepia(16%) saturate(1221%) hue-rotate(164deg) brightness(105%) contrast(91%);
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: all 200ms linear;
  }
`;

export const Items = styled.div`
  height: 100%;
  margin-top: 25px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled(Link)`
  font-size: 20px;
  margin-bottom: 20px;
  color: ${({ active }) => (active ? '#74c6f3' : '#fff')};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #74c6f3;
    transition: all 200ms linear;
  }
`;

Menu.Dot = Dot;

Wrapper.Background = Background;
Wrapper.Content = Content;

Content.Header = Header;
Content.Items = Items;

Header.ManagerInfo = ManagerInfo;
Header.ButtonHide = ButtonHide;

ManagerInfo.ImageManager = ImageManager;
ManagerInfo.Id = Id;

ButtonHide.ImageArrow = ImageArrow;

Items.Block = Block;

Block.Item = Item;
