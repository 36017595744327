import firebase from 'firebase';
// import config from './config';

if (!('serviceWorker' in navigator) && !('PushManager' in window)) {
  throw new Error('Service worker or push notification isn`t supported on this browser');
}

firebase.initializeApp({
  apiKey: 'AIzaSyDkDgrCYJ19GP-HSnJIr7XfQxf9kdXYrWg',
  authDomain: 'crmchat-eeed9.firebaseapp.com',
  projectId: 'crmchat-eeed9',
  storageBucket: 'crmchat-eeed9.appspot.com',
  messagingSenderId: '619001850685',
  appId: '1:619001850685:web:5fdf6923bf2c9a5fac3354',
  measurementId: 'G-ZR5S32WSM5',
});

const messaging = firebase.messaging();

const getToken = () => messaging.getToken();

export default getToken;
