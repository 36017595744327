export default {
  SvcCallStarted: {
    en: 'Call started',
    ru: 'Звонок начат',
    es: 'Llamada iniciada',
    pl: 'Rozpoczęto rozmowę',
    de: 'Anruf gestartet',
  },
  SvcCallEnded: {
    en: 'Call ended',
    ru: 'Звонок завершен',
    es: 'Llamada terminada',
    pl: 'Połączenie zakończone',
    de: 'Anruf beendet',
  },
  SvcCallRejected: {
    en: 'Call rejected',
    ru: 'Звонок отклонен',
    es: 'Llamada rechazada',
    pl: 'Połączenie odrzucone',
    de: 'Anruf abgelehnt',
  },
  SvcCallMissed: {
    en: 'Call missed',
    ru: 'Несостоявшийся звонок',
    es: 'Llamada perdida',
    pl: 'Połączenie nieodebrane',
    de: 'Anruf verpasst',
  },
  SvcSwitchTo: {
    en: 'now lead manager',
    ru: 'теперь менеджер лида',
    es: 'ahora gerente líder',
    pl: 'teraz główny menedżer',
    de: 'jetzt leitender Manager',
  },
};
