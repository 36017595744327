import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import ErrorBoundary from '../Common/ErrorBoundary';
import RefreshButton from './RefreshButton';
import Recordings from './Recordings';
import { recordingsActions } from '../../redux/reducers/recordings';
import recordingsSelectors from '../../redux/selectors/recordings';
import roomsSelectors from '../../redux/selectors/rooms';
import createActions from '../../redux/reducers/createActions';
import { Wrapper, NoRecording } from './styled';

const RightSideBar = () => {
  const recMap = useSelector(recordingsSelectors.getMap);
  const rooms = useSelector(roomsSelectors.getRooms);
  const active_room_id = useSelector(roomsSelectors.getActiveRoomId);
  const dispatch = useDispatch();
  const { client_id } = rooms[active_room_id];
  let recordings;
  if (recMap[client_id] === undefined) {
    dispatch(createActions.onGetRecordings({ leadId: client_id }));
  } else {
    recordings = recMap[client_id];
  }

  const showRec = () => {
    dispatch(recordingsActions.setShow());
  };

  return (
    <ErrorBoundary>
      <Wrapper>
        {recordings && <Recordings recordings={recordings} showRec={showRec} />}
        {!recordings && (
          <Wrapper.NoRecording>
            <NoRecording.Close onClick={showRec} />
            <NoRecording.Title>No recordings</NoRecording.Title>
          </Wrapper.NoRecording>
        )}
        <RefreshButton clientId={client_id} />
      </Wrapper>
    </ErrorBoundary>
  );
};

export default RightSideBar;
