import { createSlice } from '@reduxjs/toolkit';

const storeName = 'visibleComponents';

const initialState = {
  login: false,
};

export const visibleComponentsSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setLoginVisibility(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.login = action.payload;
    },
  },
});

export const visibleComponentsActions = {
  setLoginVisibility: visibleComponentsSlice.actions.setLoginVisibility,
};

export const visibleComponentsReducer = visibleComponentsSlice.reducer;
