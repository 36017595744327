import React, { /* useCallback,*/ useEffect, useRef } from 'react';
import {
  MdCall,
  MdCallEnd,
  MdRingVolume,
  MdMic,
  MdMicOff,
  /*
  MdCastConnected,
  MdHelpOutline,
  MdInfoOutline,
  MdLabelOutline,
  MdPhoneInTalk, MdPhoneForwarded, MdPhoneMissed, MdPhonePaused, MdPermPhoneMsg, */MdSettingsPhone,

} from 'react-icons/md';

import { useDispatch, useStore } from 'react-redux';
import { IconContext } from 'react-icons';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import * as caller from '../../../../infra/webrtc/caller';
import dialToneFile from '../../../../sounds/dial_tone.ogg';
import hangUp from '../../../../sounds/hang_up.ogg';
import audioConnecting from '../../../../sounds/connecting.ogg';
import call from '../../../../constants/call';

import { modalsActions } from '../../../../redux/reducers/modals';
import createActions from '../../../../redux/reducers/createActions';
import { MicrophoneContainer, Wrapper, Container, Audio } from './styled';
import { roomIdWithActiveCallActions } from '../../../../redux/reducers/roomIdWithActiveCall';

import { offlineCallStatusActions } from '../../../../redux/reducers/offlineCallStatus';
import config from '../../../../config';
import roomsSelectors from '../../../../redux/selectors/rooms';

const Call = (
  {
    clientId,
    roomId,
    clientStatus,
    callStatus,
    offlineCallIsActive,
    roomIdWithActiveCall,
    preloaderStatus,
    microphoneStatus,
    callPermission,
  },
) => {
  let btn;
  const callTimeoutRef = useRef();
  const toneRef = useRef(null);
  const hangUpRef = useRef(null);
  const connectingRef = useRef(null);
  const dispatch = useDispatch();
  const store = useStore();

  if (callTimeoutRef.current) clearTimeout(callTimeoutRef.current);

  const sendMsg = (text) => {
    dispatch(createActions.onSendMessage({
      roomId: roomIdWithActiveCall,
      text,
      type: 3,
    }));
  };

  const stopCall = () => {
    if (callStatus === call.CALLING) sendMsg('4');
    else sendMsg('2');
    hangUpRef.current.play();
    toneRef.current.pause();
    toneRef.current.currentTime = 0;
    dispatch(createActions.onChangeCallStatus(call.FINISHED));
  };

  const initCall = () => {
    caller.checkUserMicrophone(showMicWarning, startCall);
  };

  const startCall = (skipOfflineCall = false) => {
    if (skipOfflineCall) sendMsg('4');
    dispatch(createActions.onStartOfflineCall({ clientStatus, clientId, roomId, skipOfflineCall }));
    if (clientStatus) {
      toneRef.current.play();
      dispatch(createActions.onChangeCallStatus(call.CALLING));
    }
  };

  const switcherMicrophone = () => {
    dispatch(createActions.onMicrophoneStatus());
  };

  const onSkipOfflineCall = () => {
    startCall(true);
    dispatch(roomIdWithActiveCallActions.setState(''));
    connectingRef.current.pause();
    hangUpRef.current.play();
  };

  const showMicWarning = () => {
    const title = 'Can\'t use microphone!';
    const msg = 'Please check whether a microphone plugged in properly and browser has access to the microphone.';
    dispatch(modalsActions.openWarningModal({ msg, title }));
  };

  const onClickCallBtn = () => {
    if (clientStatus === 0) {
      connectingRef.current.play();

      let timeout;

      const interval = setInterval(() => {
        const room = roomsSelectors.getRooms(store.getState())[roomId];

        if (room.client_status !== 0) {
          dispatch(createActions.onStartOfflineCall({
            clientStatus: room.client_status,
            clientId: room.client_id,
            roomId,
            skipOfflineCall: false,
          }));

          toneRef.current.play();
          dispatch(createActions.onChangeCallStatus(call.CALLING));

          connectingRef.current.pause();
          clearInterval(interval);
          clearTimeout(timeout);
        }
      }, 500);

      timeout = setTimeout(() => {
        onSkipOfflineCall();
        clearTimeout(timeout);
        clearInterval(interval);
      }, config.offlineCallTimerMillSec);
    }

    if (offlineCallIsActive) return;
    switch (callStatus) {
    case call.STAND_BY:
    case call.FINISHED:
      initCall(); break;
    case call.TALKING:
    case call.CALLING:
      stopCall(); break;
    default: break;
    }
  };

  caller.setGotClientAnswerCb(() => {
    toneRef.current.pause();
    toneRef.current.currentTime = 0;
    dispatch(createActions.onChangeCallStatus(call.TALKING));
  });

  caller.setGotClientStopCb(() => {
    sendMsg('2');
    hangUpRef.current.play();
    toneRef.current.pause();
    toneRef.current.currentTime = 0;
    dispatch(createActions.onChangeCallStatus(call.FINISHED));
  });

  caller.setGotClientDeclineCb(() => {
    dispatch(offlineCallStatusActions.setState(false));
    toast.info('Client decline call');
    hangUpRef.current.play();
    toneRef.current.pause();
    toneRef.current.currentTime = 0;
    dispatch(createActions.onChangeCallStatus(call.FINISHED));
  });

  useEffect(() => {
    window.addEventListener('beforeunload', stopCall);
    return () => {
      clearTimeout(callTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    // if (offlineCallIsActive && clientStatus && roomId === roomIdWithActiveCall) startCall();
    if (roomId === roomIdWithActiveCall
      && !clientStatus && (callStatus === call.CALLING || callStatus === call.TALKING)) {
      stopCall();
    }
  }, [clientStatus, offlineCallIsActive]);

  switch (callStatus) {
  case call.STAND_BY:
  case call.FINISHED:
    btn = <MdCall title={callPermission === 'unknown' ? 'The call acceptance has neither been confirmed nor denied by the client.' : ''} color={callPermission === 'allowed' ? '#58a84d' : ''} />;
    break;
  case call.TALKING:
    btn = <MdCallEnd />;
    break;
  case call.CALLING:
    btn = <MdRingVolume />;
    callTimeoutRef.current = setTimeout(stopCall, 40000);
    break;
  default: break;
  }

  return (
    <>
      <IconContext.Provider value={{ size: '2em' }}>
        {callStatus === call.TALKING && (
          <MicrophoneContainer>
            {microphoneStatus ? (<MdMic onClick={switcherMicrophone} />) : (<MdMicOff onClick={switcherMicrophone} />)}
          </MicrophoneContainer>
        )}
        <Wrapper onClick={onClickCallBtn}>
          { !offlineCallIsActive && (callStatus !== call.FINISHED || callStatus !== call.STAND_BY) && (
            <>
              {(roomId === roomIdWithActiveCall || roomIdWithActiveCall === '') && btn}
            </>
          )}
          {
            offlineCallIsActive && (roomId === roomIdWithActiveCall) && (
              <MdSettingsPhone onClick={(event) => {
                event.stopPropagation();
                onSkipOfflineCall();
              }}
              />
            )
          }
          {preloaderStatus && (
            <Wrapper.Container>
              <Container.Preloader />
            </Wrapper.Container>
          )}
        </Wrapper>
      </IconContext.Provider>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <Audio ref={toneRef} src={dialToneFile} hidden loop />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <Audio ref={hangUpRef} src={hangUp} hidden />
      <Audio ref={connectingRef} src={audioConnecting} hidden loop />
      {/* {offlineCallIsActive && <CallModalWindow endOfflineCall={startCall}/>}*/}
    </>
  );
};

Call.propTypes = {
  clientId: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  clientStatus: PropTypes.number.isRequired,
  callStatus: PropTypes.string.isRequired,
  offlineCallIsActive: PropTypes.bool.isRequired,
  roomIdWithActiveCall: PropTypes.string.isRequired,
  preloaderStatus: PropTypes.bool.isRequired,
  callPermission: PropTypes.oneOf(['allowed', 'not_allowed', 'unknown']).isRequired,
};

export default Call;
