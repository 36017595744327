import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import PropTypes from 'prop-types';
import actions from '../../../../../constants/actions';
import SwitchToMsgModalWindow from './SwitchToMsgModalWindow';
import ConfirmationFilesModalWindow from './ConfirmationFilesModalWindow';
import createActions from '../../../../../redux/reducers/createActions';
import { fileListActions } from '../../../../../redux/reducers/fileList';
import { Wrapper } from './styled';

const AddBtn = (
  <Wrapper>
    <Wrapper.Icon title="Send..." />
  </Wrapper>
);

const AddMenu = ({ roomId }) => {
  const fileRef = useRef(null);
  const imageRef = useRef(null);
  const [showSwitchToMsgModal, setVisibleSwitchToMsgModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fileType, setFileType] = useState();
  const dispatch = useDispatch();

  const setFileList = (ev) => {
    const files = Array.from(ev.target.files);
    dispatch(fileListActions.setState(files));
    changeShowConfirmationModal();
  };

  const changeListFile = (key) => {
    dispatch(createActions.onChangeFileList(key));
  };

  const onSendImages = (files) => {
    dispatch(createActions.onSendFiles({
      roomId,
      files,
      type: actions.MSG_TYPE_IMAGE,
    }));
    imageRef.current.value = null;
    changeShowConfirmationModal();
  };

  const onSendFiles = (files) => {
    dispatch(createActions.onSendFiles({
      roomId,
      files,
      type: actions.MSG_TYPE_FILE,
    }));
    fileRef.current.value = null;
    changeShowConfirmationModal();
  };

  const changeShowConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
    clearFilesValue();
  };

  const clearFilesValue = () => {
    imageRef.current.value = null;
    fileRef.current.value = null;
  };

  return (
    <>
      <Menu menuButton={AddBtn}>
        <MenuItem onClick={() => {
          fileRef.current.click();
          setFileType(0);
        }}
        >
          Files
        </MenuItem>
        <MenuItem onClick={() => {
          imageRef.current.click();
          setFileType(1);
        }}
        >
          Images
        </MenuItem>
        <MenuItem onClick={() => setVisibleSwitchToMsgModal(true)}>Switched to ...</MenuItem>
      </Menu>
      <input
        hidden
        ref={fileRef}
        type="file"
        accept="*"
        onChange={setFileList}
        multiple
      />
      <input
        hidden
        ref={imageRef}
        type="file"
        accept={'image/*'}
        onChange={setFileList}
        multiple
      />
      {showConfirmationModal
      && (
        <ConfirmationFilesModalWindow
          changeShowConfirmationModal={changeShowConfirmationModal}
          onSend={fileType === 0 ? onSendFiles : onSendImages}
          changeListFile={changeListFile}
          clearFilesValue={clearFilesValue}
        />
      )}
      {showSwitchToMsgModal
      && <SwitchToMsgModalWindow roomId={roomId} closeCB={() => setVisibleSwitchToMsgModal(false)} />}
    </>
  );
};

AddMenu.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default AddMenu;
