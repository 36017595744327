import React, { useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import DatePicker from 'react-datepicker';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import { Filter, Content, Line, Switch, Wrapper, Record, GlobalStyle } from './styled';

const sameDay = (d1, d2) => d1.getFullYear() === d2.getFullYear()
    && d1.getMonth() === d2.getMonth()
    && d1.getDate() === d2.getDate();

const Recordings = ({ recordings, showRec }) => {
  const [dateFilterEnabled, setDateFilterEnabled] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  const onFilterCheck = () => {
    setDateFilterEnabled(!dateFilterEnabled);
  };
  const filteredRecs = [];
  if (dateFilterEnabled) {
    recordings.forEach((r) => {
      const date = new Date(r.created_at);
      if (sameDay(date, startDate)) {
        filteredRecs.push(r);
      }
    });
  } else {
    filteredRecs.push(...recordings);
  }

  const recJsx = filteredRecs.map((r, i) => {
    const date = new Date(r.created_at);
    const cn = i % 2 === 0;
    return (
      <Record even={cn} key={r.id}>
        <Record.Time>{date.toLocaleString()}</Record.Time>
        <ReactAudioPlayer src={r.file_path} controls />
      </Record>
    );
  });

  return (
    <>
      <Filter>
        <Filter.Close onClick={showRec} />
        <Filter.Content>
          <Content.Title>Filter by date: </Content.Title>
          <Content.Line>
            <Line.Switch>
              <Switch.Input
                type="checkbox"
                checked={dateFilterEnabled}
                onClick={onFilterCheck}
              />
            </Line.Switch>
            <Line.Date>
              <DatePicker
                disabled={!dateFilterEnabled}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Line.Date>
          </Content.Line>
        </Filter.Content>
      </Filter>
      <Scrollbars autoHide>
        <Wrapper>
          {recJsx.reverse()}
        </Wrapper>
      </Scrollbars>
      <GlobalStyle />
    </>
  );
};

Recordings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recordings: PropTypes.array.isRequired,
  showRec: PropTypes.func.isRequired,
};

export default Recordings;
