import { createSlice } from '@reduxjs/toolkit';

const storeName = 'bindClient';

const initialState = {
  state: true,
};

export const bindClientSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const bindClientActions = {
  setState: bindClientSlice.actions.setState,
};

export const bindClientReducer = bindClientSlice.reducer;
