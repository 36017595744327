import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* getUserRoom(a) {
  if (!a || !a.payload) return;
  const { client_id } = a.payload;

  try {
    const roomsStore = yield select(roomsSelectors.getRooms);
    const { room } = yield call(chatAPI.getUserRoom, {
      client_id,
    });
    const check = Object.keys(roomsStore).indexOf(room.id);
    if (check > 0) return;
    const rooms = {
      [room.id]: room,
    };
    const allRooms = Object.assign(roomsStore, rooms);
    yield put(roomsActions.setUserRooms({
      rooms: { ...allRooms },
      active_room_id: room.id,
    }));
  } catch (error) {
    yield call(toast.error, `getUserRoom-saga: ${error.message}`);
    logError(error);
  }
}
