import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em 0.7em;
  border-radius: 5px;
  word-break: break-word;
  color: #fff;
  background-color: ${({ isMyMsg, msgDelete, expiration }) => {
    if (expiration) return '#494949';
    // eslint-disable-next-line no-nested-ternary
    const result = msgDelete ? '#cfd2de' : isMyMsg ? '#75808d' : '#387ce2e6';
    return result;
  }};
  text-align: ${({ isMyMsg }) => (isMyMsg ? 'left' : 'inherit')};
`;

export const Link = styled.a`
  display: flex;
  float: left;
  padding: 0.75em;
  text-decoration: none;

  &:any-link {
    color: white;
  }
  
  &:hover {
    color: #ffc0c0;
    cursor: pointer;
    transition-duration: 0.2s;
  }
`;

export const IFrame = styled.iframe`
  display: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 20em;
  text-align: start;
  cursor: pointer;
  word-break: break-word;
`;

export const Icon = styled.div`
  display: flex;
  font-size: 2em;
  align-items: center;
`;

export const Text = styled.div`
  margin-left: 0.5em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  font-weight: bold;
  vertical-align: middle;
  text-decoration: ${({ msgDelete }) => (msgDelete ? 'line-through #75808D' : 'none')};
`;

Wrapper.Link = Link;

Link.IFrame = IFrame;
Link.Container = Container;

Container.Icon = Icon;
Container.Text = Text;
