import { put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { messagesMarkedDeletion } from '../../infra/api/ChatAPI';
import { messageSelectionActions } from '../reducers/messageSelection';
import { messagesActions } from '../reducers/messages';
import messagesSelectors from '../selectors/messages';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* deleteMessagesSaga(action) {
  if (!action || !action.payload) return;
  try {
    if (action.payload.length === 0) throw Error('Select the message to delete.');
    const rooms = yield select(roomsSelectors.getRooms);
    const activeRoomId = yield select(roomsSelectors.getActiveRoomId);
    const messagesReducer = JSON.parse(JSON.stringify(yield select(messagesSelectors.getStore)));
    const { messages } = messagesReducer[activeRoomId];
    const room = rooms[activeRoomId];
    const args = yield call(messagesMarkedDeletion, action.payload, room.client_id, room.manager_id);
    args.msgs_ids.forEach((id) => {
      const msg = Object.keys(messages).filter((key) => messages[key].id === id);
      messages[msg].status = 3;
    });
    console.log(messagesReducer);
    yield put(messageSelectionActions.setVisibility(false));
    yield put(messageSelectionActions.clear());
    yield put(messagesActions.setMessages({ messages, room_id: activeRoomId, rooms: messagesReducer }));
  } catch (error) {
    yield call(toast.error, `deleteMessages-saga: ${error.message}`);
    logError(error);
  }
}
