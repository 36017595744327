import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Chat from './Chat';
import Header from './Header';
import Footer from './Footer';
import Placeholder from './Placeholder';
import ErrorBoundary from '../Common/ErrorBoundary';
import LightBoxModal from '../Common/LightboxModal';
import InactiveRoom from './inactiveRoom';
import { roomListActions } from '../../redux/reducers/roomList';
import managerInfoSelectors from '../../redux/selectors/managerInfo';
import { roomsActions } from '../../redux/reducers/rooms';
import roomsSelectors from '../../redux/selectors/rooms';
import roomListSelectors from '../../redux/selectors/roomList';
import Wrapper from './styled';

const Main = ({ intl }) => {
  const rooms = useSelector(roomsSelectors.getRooms);
  let activeRoomID = useSelector(roomsSelectors.getActiveRoomId);
  const managerID = useSelector(managerInfoSelectors.getManagerId);
  const roomListVisibility = useSelector(roomListSelectors.getRoomList);
  const dispatch = useDispatch();

  const activeRoom = rooms[activeRoomID];

  if (!activeRoom) {
    activeRoomID = 'none';
  }

  if (Object.keys(rooms).length === 0) {
    const text = intl.formatMessage({ id: 'noRoomsMsg' });
    return <Placeholder text={text} />;
  }

  const dispatchSetActiveRoom = () => {
    dispatch(roomsActions.setActiveRoom(''));
  };

  const handleBackBtn = () => {
    dispatchSetActiveRoom();
    dispatch(roomListActions.setState(true));
  };

  if (activeRoomID === '' || activeRoomID === 'none') {
    return (
      <InactiveRoom
        intl={intl}
        activeRoomID={activeRoomID}
        handleBackBtn={handleBackBtn}
        roomListVisibility={roomListVisibility}
      />
    );
  }

  return (
    <>
      {activeRoom && (
        <Wrapper>
          <Header
            roomId={activeRoomID}
            room={activeRoom}
          />
          <ErrorBoundary>
            <>
              <Chat
                intl={intl}
                activeRoom={activeRoom}
                activeRoomID={activeRoomID}
                managerID={managerID}
              />
              {((rooms[activeRoomID] && !rooms[activeRoomID].read_only) && (activeRoom.chat_permission !== 'not_allowed')) && <Footer intl={intl} roomId={activeRoomID} />}
            </>
          </ErrorBoundary>
          <LightBoxModal />
        </Wrapper>
      )}
    </>
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Main);
