import React from 'react';
import PropTypes from 'prop-types';
import { BsFillExclamationCircleFill } from 'react-icons/all';
import { Wrapper, Container } from './styled';

const ErrorPage = ({ clientId }) => {
  const text = `Can't reach  lead ${clientId} via CRM public API`;
  return (
    <Wrapper>
      <Wrapper.Container>
        <BsFillExclamationCircleFill />
        <Container.Text>{text}</Container.Text>
      </Wrapper.Container>
    </Wrapper>
  );
};

ErrorPage.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default ErrorPage;
