import ResponseRouter from './ResponseRouter';
import config from '../../../config';
import { logError } from '../../../helper/sentry';

// eslint-disable-next-line import/no-mutable-exports
export let callId = null;

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-bitwise,no-mixed-operators
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// WsConnector is used for sending messages to BE by websocket
export default class WsConnector {
  constructor() {
    this.url = null;
    this.ws = null;
    this.connectLoop = null;
    this.isUnauthorized = false;
    this.connected = () => {};
    this.disconnected = () => {};
    this.unauthorized = () => {};
    this.responseRouter = new ResponseRouter();
  }

  connect(params) {
    const {
      url,
      queryParam,
    } = params;

    const newUrl = new URL(url);
    Object.keys(queryParam).forEach((key) => {
      if (queryParam[key]) {
        newUrl.searchParams.append(key, queryParam[key]);
      }
    });

    return new Promise((resolve, reject) => {
      try {
        this.ws = new WebSocket(newUrl.toString());
        window.ws = this.ws;
        window.addEventListener('beforeunload', () => { this.ws.close(); });
      } catch (e) {
        reject(e);
        logError(e);
      }

      this.ws.onerror = (e) => {
        reject(e);
        this.ws.onerror = undefined;
        logError(e);
      };

      this.ws.onopen = () => {
        console.log('connected');
        this.pingInterval = setInterval(() => {
          this.ws.send(queryParam.pingMsg);
        }, queryParam.pingInterval);

        this.ws.onmessage = (event) => {
          if (event.data === 'unauthorized') {
            this.unauthorized();
            console.warn('unauthorized');
            reject(new Error('unauthorized'));
            logError(new Error('unauthorized'));
            return;
          }
          if (event.data === 'connected') {
            this.connected();
            resolve();
            return;
          }
          this.responseRouter.onMessage(event.data);
        };

        this.ws.onclose = (e) => {
          clearInterval(this.pingInterval);
          if (e.code) {
            console.log('disconnected with code: ', e.code);
          }
          this.disconnected();
          if (!this.isUnauthorized) {
            this.start(params);
          }
        };
      };
    });
  }

  start(params) {
    const { reconnectDelay } = params;
    const interval = Math.floor(Math.random() * (1001) + reconnectDelay * 1000);
    this.connect(params).catch((e) => {
      console.error('WebSocket error observed:', e);
      logError(e);
      this.connectLoop = setInterval(() => {
        this.connect(params)
          .then(() => {
            clearInterval(this.connectLoop);
          }).catch((ev) => {
            logError(ev);
            if (ev.message && ev.message === 'unauthorized') {
              clearInterval(this.connectLoop);
            }
          });
      }, interval);
    });
  }

  onConnected(connectedCb) {
    this.connected = connectedCb;
  }

  onDisconnected(disconnectedCb) {
    this.disconnected = disconnectedCb;
  }

  onUnauthorized(onUnauthorizedCb) {
    this.unauthorized = () => {
      this.isUnauthorized = true;
      onUnauthorizedCb();
    };
  }

  // requestProcessing serializes message and sends it to BE
  // returns 2 promises
  // should reject if responseRouter or BE returns error or processing is finished by timeout
  // should resolve if BE returns valid response
  // params = { requestCmd, requestArgs, responseCmd }
  requestProcessing(params) {
    const { requestCmd, requestArgs, responseCmd, isIdPresent } = params;
    if (this.ws.readyState !== 1) {
      logError(new Error('Websocket isn\'t connected'));
      return Promise.reject(new Error('Websocket isn\'t connected'));
    }
    const id = isIdPresent ? uuidv4() : '';
    if (requestCmd === 'manager_start_offline_call') {
      callId = id;
      requestArgs.callId = callId;
    }
    if (requestCmd === 'manager_offer') { // TODO REWRITE
      callId = callId || id;
      requestArgs.callId = callId;
    }
    if (requestCmd === 'manager_ice') { // TODO REWRITE
      requestArgs.callId = callId;
    }
    if (requestCmd === 'manager_stop_call') { // TODO REWRITE
      requestArgs.callId = callId;
      callId = '';
    }
    if (requestCmd === 'manager_stop_offline_call') { // TODO REWRITE
      requestArgs.callId = callId;
      callId = '';
    }
    const request = new Promise((resolve, reject) => {
      try {
        this.responseRouter.register(responseCmd, (data) => {
          if (data.error) {
            reject(new Error(`message with cmd: ${data.cmd} contains error: ${data.error}`));
            this.responseRouter.unregister(responseCmd, id);
            return;
          }
          resolve(data.args);
          this.responseRouter.unregister(responseCmd, id);
        }, id);

        const req = JSON.stringify({
          id,
          cmd: requestCmd,
          args: requestArgs,
        });
        this.ws.send(req);
      } catch (e) {
        logError(e);
        reject(new Error(`cmd "${requestCmd}" processing has been finished with error ${e}`));
        this.responseRouter.unregister(responseCmd);
      }
    });

    const timer = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error(`cmd "${requestCmd}" processing has been finished by timeout`));
      }, config.backendRequestTimeout);
    });
    return Promise.race([request, timer]);
  }

  register(cmd, callback) {
    this.responseRouter.register(cmd, callback);
  }
}
