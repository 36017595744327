import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { initialRoom, imageLinksActions } from '../../../redux/reducers/imageLinks';

import imageLinksSelectors from '../../../redux/selectors/imageLinks';
import roomsSelectors from '../../../redux/selectors/rooms';

const LightBoxModal = () => {
  const activeRoomID = useSelector(roomsSelectors.getActiveRoomId);
  const { rooms, isOpen, currentImageUrl } = useSelector(imageLinksSelectors.getImageLinks);
  const room = rooms[activeRoomID] || initialRoom;
  const [photoIndex, setPhotoIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      const i = room.indexOf(currentImageUrl);
      setPhotoIndex(i);
    }
  }, [isOpen]);

  if (!room.length) {
    return <></>;
  }

  const onCancel = () => {
    dispatch(imageLinksActions.setLightBoxClose());
  };

  const nextPhotoIndex = (photoIndex + 1) % room.length;
  const prevPhotoIndex = (photoIndex + room.length - 1) % room.length;

  return isOpen ? (
    <Lightbox
      mainSrc={room[photoIndex]}
      onCloseRequest={onCancel}
      nextSrc={room[nextPhotoIndex]}
      prevSrc={room[prevPhotoIndex]}
      onMoveNextRequest={() => setPhotoIndex(nextPhotoIndex)}
      onMovePrevRequest={() => setPhotoIndex(prevPhotoIndex)}
    />
  ) : <></>;
};

export default React.memo(LightBoxModal);
