import { createSelector } from 'reselect';

const recordingsState = (state) => state.recordings;

const getShow = createSelector(recordingsState, (s) => s.show);

const getMap = createSelector(recordingsState, (s) => s.recMap);

const recordingsSelectors = { getShow, getMap };

export default recordingsSelectors;
