import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styled';

const getClientInitials = (clientName) => {
  const parts = clientName.split(' ');
  const maxInitialsLength = 2;
  const t = (parts.length <= maxInitialsLength ? parts.length : maxInitialsLength);
  let initials = '';
  for (let i = 0; i < t; i++) {
    initials += parts[i].charAt(0);
  }
  return initials;
};

const InitialsAvatar = ({ name }) => (
  <Wrapper>{getClientInitials(name)}</Wrapper>
);

InitialsAvatar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
};

export default InitialsAvatar;
