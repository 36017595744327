import { combineReducers } from 'redux';
import { roomsReducer } from './rooms';
import { messagesReducer } from './messages';
import { managerInfoReducer } from './managerInfo';
import { recordingsReducer } from './recordings';
import { searchStringReducer } from './searchString';
import { settingsReducer } from './settings';
import { searchListReducer } from './searchList';
import { editedMessageReducer } from './editedMessage';
import { microphoneStatusReducer } from './microphoneStatus';
import { quotedMessageReducer } from './quotedMessage';
import { messageSelectionReducer } from './messageSelection';
import { fileListReducer } from './fileList';
import { unreadMsgCountReducer } from './unreadMsgCount';
import { visibleComponentsReducer } from './visibleComponents';
import { roomListReducer } from './roomList';
import { preloaderReducer } from './preloader';
import { roomIdWithActiveCallReducer } from './roomIdWithActiveCall';
import { offlineCallStatusReducer } from './offlineCallStatus';
import { bindClientReducer } from './bindClient';
import { callStatusReducer } from './callStatus';
import { wiretapperReducer } from './wiretapper';
import { imageLinksReducer } from './imageLinks';
import { notifyReducer } from './notify';
import { modalsReducer } from './modals';
import { chatMessagesUpdaterReducer } from './chatMessagesUpdater';
import { statisticsReducer } from './statistics';

export default combineReducers({
  roomsReducer,
  messagesReducer,
  managerInfo: managerInfoReducer,
  chatMessagesUpdater: chatMessagesUpdaterReducer,
  modals: modalsReducer,
  notify: notifyReducer,
  imageLinks: imageLinksReducer,
  searchString: searchStringReducer,
  recordings: recordingsReducer,
  wiretapper: wiretapperReducer,
  callStatus: callStatusReducer,
  bindClient: bindClientReducer,
  offlineCallStatus: offlineCallStatusReducer,
  roomIdWithActiveCall: roomIdWithActiveCallReducer,
  preloader: preloaderReducer,
  roomList: roomListReducer,
  visibleComponents: visibleComponentsReducer,
  unreadMsgCount: unreadMsgCountReducer,
  fileList: fileListReducer,
  messageSelection: messageSelectionReducer,
  quotedMessage: quotedMessageReducer,
  microphoneStatus: microphoneStatusReducer,
  editedMessage: editedMessageReducer,
  searchList: searchListReducer,
  settings: settingsReducer,
  statistics: statisticsReducer,
});
