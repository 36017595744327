import styled from 'styled-components';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import Linkify from 'react-linkify';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em 0.7em;
  border-radius: 5px;
  word-break: break-word;
  color: #fff;
  background-color: ${({ isMyMsg, msgDelete, expiration }) => {
    if (expiration) return '#494949';
    // eslint-disable-next-line no-nested-ternary
    const result = msgDelete ? '#cfd2de' : isMyMsg ? '#75808d' : '#387ce2e6';
    return result;
  }};
  text-align: ${({ isMyMsg }) => (isMyMsg ? 'left' : 'inherit')};
  text-decoration: ${({ msgDelete }) => (msgDelete ? 'line-through #75808D' : 'none')};

  & > a {
    display: contents;
    color: #bcd6ff;
    transition-duration: 0.2s;
  }
`;

export const Preview = styled(LinkPreview)`
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
`;

export const Content = styled(Linkify)``;

export const Quote = styled.div`
  position: relative;
  background-color: rgba(0,0,0,0);
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  margin-bottom: 5px;
  display: block;
  transform: translateZ(0);
`;

export const Icon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
`;

export const Text = styled.div`
  padding-left: 20px;
`;

export const Description = styled.div`
  padding-left: 20px;
  font-size: 13px;
  margin-top: 3px;
`;

Wrapper.Quote = Quote;
Wrapper.Preview = Preview;
Wrapper.Content = Content;

Quote.Icon = Icon;
Quote.Text = Text;
Quote.Description = Description;
