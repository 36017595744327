import { select, call, put, delay, cancelled } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import * as caller from '../../infra/webrtc/caller';
import config from '../../config';
import sendMessage from './sendMessage';
import { roomIdWithActiveCallActions } from '../reducers/roomIdWithActiveCall';
import offlineCallStatusSelectors from '../selectors/offlineCallStatus';
import { offlineCallStatusActions } from '../reducers/offlineCallStatus';
import { preloaderActions } from '../reducers/preloader';

export default function* startOfflineCall(action) {
  if (!action || !action.payload) return;
  try {
    const { clientStatus, clientId, roomId, skipOfflineCall } = action.payload;
    if (clientStatus === undefined || clientStatus === null || roomId === undefined || !clientId) throw new Error();
    if (skipOfflineCall) {
      yield put(offlineCallStatusActions.setState(false));
      yield call(chatAPI.managerStopOfflineCall, clientId);
      return;
    }
    if (clientStatus) {
      yield call(caller.initCall, clientId);
      yield put(offlineCallStatusActions.setState(false));
      yield put(roomIdWithActiveCallActions.setState(roomId));
    } else {
      yield put(preloaderActions.setState(true));
      yield call(chatAPI.managerStartOfflineCall, clientId);
      yield put(preloaderActions.setState(false));
      // yield call(toast.info, 'Making a call attempt');
      yield put(roomIdWithActiveCallActions.setState(roomId));
      yield put(offlineCallStatusActions.setState(true));
      yield delay(config.offlineCallTimerMillSec);
      const statusOfflineCall = yield select(offlineCallStatusSelectors.getState);
      if (statusOfflineCall) {
        yield call(toast.info, 'No available devices');
        const args = {
          roomId,
          text: '4',
          type: 3,
        };
        yield call(sendMessage, { payload: args });
        yield put(offlineCallStatusActions.setState(false));
      }
    }
  } catch (error) {
    yield call(toast.error, 'Offline call is not available for this client');
    yield put(preloaderActions.setState(false));
  } finally {
    yield cancelled();
  }
}
