import { put, select, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatApi from '../../infra/api/ChatAPI';
import { unreadMsgCountActions } from '../reducers/unreadMsgCount';
import unreadMsgCountSelectors from '../selectors/unreadMsgCount';
import { logError } from '../../helper/sentry';

export default function* unreadMsgCountSaga(action) {
  try {
    const allUnreadMsg = yield select(unreadMsgCountSelectors.getUnreadMsgCount);
    const unreadMessageCount = allUnreadMsg + action;
    if (unreadMessageCount < 0) {
      const { count } = yield call(chatApi.getUnreadMsgCount);
      yield put(unreadMsgCountActions.setState(count));
    } else {
      yield put(unreadMsgCountActions.setState(unreadMessageCount));
    }
    window.postMessage(JSON.stringify({
      unreadMessageCount,
    }), '*');
  } catch (error) {
    yield call(toast.error, `unreadMsgCount-saga: ${error.message}`);
    logError(error);
  }
}
