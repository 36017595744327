import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
`;

export const Form = styled.div`
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px 45px 27px 45px;
  text-align: center;
  border-radius: .3rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
`;

export const Input = styled.input`
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: .3rem;
`;

export const Button = styled.button`
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all .3s cubic-bezier(.47,0,.745,.715);
  cursor: pointer;
  border-radius: .3rem;
  background-color: ${({ disabled }) => (disabled ? '#f2f2f2' : '#007bff')};
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#f2f2f2' : '#228cdb')}; 
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')}; 
  }
`;

export const ErrorMsg = styled.div`
  margin: 18px 0 0;
  font-size: 14px;
  color: #EF3B3A;
  height: 16px;
`;

Wrapper.Form = Form;

Form.Input = Input;
Form.Button = Button;
Form.ErrorMsg = ErrorMsg;
