import React from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper, Container, Preloader, Content } from './styled';
import { splitFileNameAndUrl } from '../../../../../helper/links';
import { imageLinksActions } from '../../../../../redux/reducers/imageLinks';
import CompositionMessage from '../CompositionMessage';

const ImageMessage = (props) => {
  const dispatch = useDispatch();
  const { message: { text, status }, isMyMsg, expirationTime } = props;

  const { url = '' } = splitFileNameAndUrl(text) || {};

  const deleteMessage = status === 3;

  const openLightBox = () => {
    dispatch(imageLinksActions.setLightBoxOpen(url));
  };

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <CompositionMessage {...props}>
      <Wrapper isMyMsg={isMyMsg} msgDelete={deleteMessage} expiration={expirationTime}>
        <Wrapper.Container onClick={openLightBox}>
          {splitFileNameAndUrl(text)
            ? (
              <Container.Image
                down
                src={url}
                unloader="Ops... Failed to download image"
                loader={(
                  <Preloader>
                    <Preloader.Content>
                      <Content.First />
                      <Content.Second />
                      <Content.Third />
                    </Preloader.Content>
                  </Preloader>
                )}
              />
            )
            : (
              <Preloader>
                <Preloader.Content>
                  <Content.First />
                  <Content.Second />
                  <Content.Third />
                </Preloader.Content>
              </Preloader>
            )}
        </Wrapper.Container>
      </Wrapper>
    </CompositionMessage>
  );
};

export default ImageMessage;
