import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import App from './components/App';
// import config from './config';
import locales from './locales';
import NotificationsWrap from './components/Common/NotificationsWrap';
import 'react-notifications/lib/notifications.css';
import configureStore from './redux/store';
import CreateGlobalStyle from './styled';
import { initSentry } from './helper/sentry';

const index = () => {
  // if (!config.debuglog) window.console.log = () => {};

  const store = configureStore();
  initSentry();
  let locale = new URLSearchParams(window.location.search).get('locale') || 'en';
  const state = store.getState();
  if (!state.managerInfo?.languages?.includes(locale)) locale = 'en';

  ReactDOM.render(
    <IntlProvider locale={locale} messages={locales[locale].messages}>
      <Provider store={store}>
        <NotificationsWrap />
        <App />
      </Provider>
      <CreateGlobalStyle />
    </IntlProvider>,
    document.getElementById('root'),
  );
};

document.addEventListener('DOMContentLoaded', index);
