import MultiStreamsMixer from 'multistreamsmixer';
import * as chatAPI from '../api/ChatAPI';
import { callId } from '../api/ChatAPI/wsConnector';
import { logError } from '../../helper/sentry';

let clientID;
let teamID;
let peerConnCall;
let peerConnRec;
let peerConnWireTapper;

let localStream;
let gotClientAnswerCb;
let gotClientDeclineCb;
let gotClientStopCb;

let mixer;
let remoteAudioContainer;
let firstICECandidate = true;
let firstICECandidateRec = true;
let firstICECandidateWireTapper = true;
let gatherRecIce = true;
let managerBusy = false;
const gatheredRecIces = [];
let icePriority = 0;

export const setGotClientAnswerCb = (cb) => {
  gotClientAnswerCb = cb;
};

export const setGotClientDeclineCb = (cb) => {
  gotClientDeclineCb = cb;
};

export const setGotClientStopCb = (cb) => {
  gotClientStopCb = cb;
};

const peerConfig = {
  iceServers: [],
};

const constraints = {
  video: false,
  audio: { channelCount: 1 },
};

function errorHandler(error) {
  console.log(error);
  logError(error);
}

function hangUpWiretapper() {
  peerConnWireTapper.close();
  firstICECandidateWireTapper = true;
  managerBusy = false;
  peerConnWireTapper = undefined;
}

function gotIceCandidate(event) {
  console.log('gotIceCandidate ---event', event);
  if (event.candidate) {
    if (firstICECandidate) {
      chatAPI.managerOffer(peerConnCall.localDescription.sdp, clientID).then(() => {
      }).catch(errorHandler);
      firstICECandidate = false;
    }
    chatAPI.managerIce(event.candidate.toJSON(), clientID, icePriority).catch(errorHandler);
    icePriority += 1;
  }
}

function gotIceCandidateRec(event) {
  if (event.candidate) {
    if (firstICECandidateRec) {
      firstICECandidateRec = false;
      console.log('gotIceCandidateRec: ', peerConfig);
      chatAPI.managerStartRecord(peerConnRec.localDescription.sdp, clientID, peerConfig.iceServers[0])
        .then(({ sdp }) => {
          if (sdp) {
            peerConnRec.setRemoteDescription(new RTCSessionDescription({
              sdp,
              type: 'answer',
            })).catch(errorHandler);
          }
          gatherRecIce = false;
          gatheredRecIces.forEach((ice) => {
            chatAPI.managerIceRecord(ice.toJSON(), clientID).catch(errorHandler);
          });
        }).catch(errorHandler);
    } else if (gatherRecIce) {
      gatheredRecIces.push(event.candidate);
    } else {
      chatAPI.managerIceRecord(event.candidate.toJSON(), clientID).catch(errorHandler);
    }
  }
}

function gotIceCandidateWireTapper(event) {
  if (event.candidate && !managerBusy) {
    if (firstICECandidateWireTapper) {
      chatAPI.managerAnswerWiretapper(teamID, peerConnWireTapper.localDescription.sdp, managerBusy).catch(errorHandler);
      firstICECandidateWireTapper = false;
    } else {
      setTimeout(() => {
        chatAPI.managerIceWiretapper(teamID, event.candidate.toJSON()).catch(errorHandler);
      }, 1000);
    }
  } else {
    chatAPI.managerAnswerWiretapper(teamID, peerConnWireTapper.localDescription.sdp, managerBusy).catch(errorHandler);
  }
}

function initRecordingPeer() {
  peerConnRec = new RTCPeerConnection(peerConfig);
  const stream = mixer.getMixedStream();
  stream.getTracks().forEach((track) => {
    peerConnRec.addTrack(track, stream);
  });
  peerConnRec.onconnectionstatechange = () => {
    console.log(`ICE Rec Peer Connection state: ${peerConnRec.iceConnectionState}`);
    switch (peerConnRec.iceConnectionState) {
    case 'failed':
    case 'disconnected':
      peerConnRec.close();
      break;
    default:
      break;
    }
  };
  peerConnRec.onicecandidate = gotIceCandidateRec;
  peerConnRec.createOffer().then((sdp) => peerConnRec.setLocalDescription(sdp));
}

function initWireTapperPeer(data) {
  peerConnWireTapper = new RTCPeerConnection({ iceServers: [data.ice_server] });
  const stream = mixer.getMixedStream();
  stream.getTracks().forEach((track) => {
    peerConnWireTapper.addTrack(track, stream);
  });
  peerConnWireTapper.onconnectionstatechange = () => {
    console.log(`ICE WireTapper Peer Connection state: ${peerConnWireTapper.iceConnectionState}`);
    switch (peerConnWireTapper.iceConnectionState) {
    case 'connected':
      managerBusy = true;
      break;
    case 'failed':
    case 'disconnected':
      hangUpWiretapper();
      break;
    default:
      break;
    }
  };
  peerConnWireTapper.onicecandidate = gotIceCandidateWireTapper;
  peerConnWireTapper.setRemoteDescription(new RTCSessionDescription({
    sdp: data.sdp,
    type: 'offer',
  })).then(() => {
    peerConnWireTapper.createAnswer()
      .then((answer) => peerConnWireTapper.setLocalDescription(answer))
      .catch(errorHandler);
  }).catch(errorHandler);
}

function stopRecording() {
  if (peerConnRec) {
    chatAPI.managerStopRecord(clientID).then(() => {
      firstICECandidateRec = true;
      gatheredRecIces.length = 0;
      gatherRecIce = true;
      peerConnRec.close();
      peerConnRec = undefined;
    }).catch(errorHandler);
  }
}

function gotRemoteStream(event) {
  const remoteStream = event.streams[0];
  mixer = new MultiStreamsMixer([localStream, remoteStream]);
  initRecordingPeer();
  remoteAudioContainer.srcObject = remoteStream;
  remoteAudioContainer.play();
}

function hangUp() {
  if (!peerConnCall) return;
  chatAPI.managerCallEnded(clientID).catch(errorHandler);
  firstICECandidate = true;
  icePriority = 0;
  console.log('hangUp peerConnCall', peerConnCall);
  peerConnCall.close();
  peerConnCall = undefined;
  stopRecording();
}

function initPeer() {
  return navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
    localStream = stream;
  }).then(() => {
    peerConnCall = new RTCPeerConnection(peerConfig);
    localStream.getTracks().forEach((track) => {
      peerConnCall.addTrack(track, localStream);
    });
    peerConnCall.onicecandidate = gotIceCandidate;
    peerConnCall.ontrack = gotRemoteStream;
    peerConnCall.oniceconnectionstatechange = () => {
      console.log(`ICE Peer Connection state: ${peerConnCall.iceConnectionState}`);
      switch (peerConnCall.iceConnectionState) {
      case 'failed':
      case 'disconnected':
        // icePriority = 0;
        console.log(`ICE Peer Connection state: ${peerConnCall.iceConnectionState}`);
        gotClientStopCb();
        hangUp();
        break;
      default:
        break;
      }
    };
    remoteAudioContainer = new Audio();
  }).catch(errorHandler);
}

export function initCall(clientId) {
  clientID = clientId;
  chatAPI.managerGetIceServer().then((iceServer) => {
    peerConfig.iceServers = [iceServer];
    initPeer()
      .then(() => {
        peerConnCall.createOffer()
          .then((sdp) => peerConnCall.setLocalDescription(sdp));
      });
  }).catch(errorHandler);
}

export function checkUserMicrophone(cbError, cbCorrect) {
  if (navigator.mediaDevices !== undefined) {
    navigator.mediaDevices.getUserMedia(constraints).then(() => cbCorrect()).catch(() => cbError());
  }
}

export function switcherMicrophone(status) {
  localStream.getAudioTracks()[0].enabled = status;
}

export function stopCall() {
  if (peerConnCall) {
    chatAPI.managerStopCall(clientID).catch(errorHandler);
    hangUp();
  }
}

chatAPI.onClientCallAnswer(({ args }) => {
  if (args.sdp) {
    peerConnCall.setRemoteDescription(new RTCSessionDescription({
      sdp: args.sdp,
      type: 'answer',
    })).then(() => {
      gotClientAnswerCb();
      chatAPI.managerCallEstablished(clientID).catch(errorHandler);
    }).catch(errorHandler);
  }
});

chatAPI.onClientIce(({ args }) => {
  if (peerConnCall) {
    const iceCandidate = new RTCIceCandidate(args.ice);
    peerConnCall.addIceCandidate(iceCandidate).catch(errorHandler);
  }
});

chatAPI.onClientCallDelcine(() => {
  if (peerConnCall) {
    chatAPI.managerCallEnded(clientID).catch(errorHandler);
    peerConnCall.close();
    gotClientDeclineCb();
    firstICECandidate = true;
  }
});

chatAPI.onClientCallDeclineNotification(({ args }) => {
  if (callId !== args.callId) return;
  gotClientDeclineCb();
  if (peerConnCall) {
    chatAPI.managerCallEnded(clientID).catch(errorHandler);
    firstICECandidate = true;
    peerConnCall.close();
  }
});

chatAPI.onClientCallStop(({ args }) => {
  if (peerConnCall && clientID === args.client_id) {
    gotClientStopCb();
    hangUp();
  }
});

chatAPI.onRecorderIce(({ args }) => {
  if (peerConnRec) {
    const iceCandidate = new RTCIceCandidate(args.ice);
    peerConnRec.addIceCandidate(iceCandidate).catch(errorHandler);
  }
});

chatAPI.onTeamIceWiretapper(({ args }) => {
  if (peerConnWireTapper) {
    const iceCandidate = new RTCIceCandidate(args.ice);
    peerConnWireTapper.addIceCandidate(iceCandidate).catch(errorHandler);
  }
});

chatAPI.onTeamOfferWireTapper((data) => {
  teamID = data.args.team_id;
  initWireTapperPeer(data.args);
});

chatAPI.onTeamEndedWiretapper(() => {
  hangUpWiretapper();
});
