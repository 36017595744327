import { createSlice } from '@reduxjs/toolkit';

const storeName = 'managerInfo';

const initialState = {
  manager_id: '',
  isGuest: false,
};

export const managerInfoSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      const { managerID, isGuest } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.isGuest = isGuest;
      // eslint-disable-next-line no-param-reassign
      state.manager_id = managerID;
    },
    setManagerId(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.manager_id = action.payload;
    },
  },
});

export const managerInfoActions = {
  setState: managerInfoSlice.actions.setState,
  setManagerId: managerInfoSlice.actions.setManagerId,
};

export const managerInfoReducer = managerInfoSlice.reducer;
