import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import { initialRoom, imageLinksActions } from '../reducers/imageLinks';
import { splitFileNameAndUrl } from '../../helper/links';
import { messageSelectionActions } from '../reducers/messageSelection';

import imageLinksSelectors from '../selectors/imageLinks';
import { logError } from '../../helper/sentry';

export default function* getRoomImageLinks(a) {
  if (!a || !a.payload) return;
  const roomId = a.payload;
  if (typeof roomId !== 'string' || roomId.length === 0) return;
  try {
    const room = yield select(imageLinksSelectors.getImageLinks)[roomId] || { ...initialRoom };
    if (room.isRequested) {
      const { images: gotLinks } = yield call(chatAPI.getRoomImageLinks, roomId);
      if (!gotLinks || gotLinks.length === 0) {
        return;
      }
      room.isRequested = false;
      room.links = gotLinks.map((l) => splitFileNameAndUrl(l).url);
      yield put(imageLinksActions.setImageLinks({
        roomId,
        room,
      }));
      yield put(messageSelectionActions.setVisibility(false));
      yield put(messageSelectionActions.clear());
    }
  } catch (error) {
    yield call(toast.error, `getRoomImageLinks-saga: ${error.message}`);
    logError(error);
  }
}
