import styled, { createGlobalStyle } from 'styled-components';
import { MdChevronLeft } from 'react-icons/md';

export const Filter = styled.div`
  background: #202933;
  
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
  }
`;

export const Close = styled(MdChevronLeft)`
  display: none;
  
  @media (max-width: 480px) {
    display: block;
    font-size: 40px;
    color: #81a1d0;
  }
`;

export const Content = styled.div`
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
`;

export const Title = styled.div`
  padding: 10px 0 4px 10px;
  font-size: 12px;
  color: white;

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const Line = styled.div`
  display: flex;
  padding-bottom: 12px;

  & input[type=text] {
    padding: 2px;
    outline: none;
    border: none;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const Switch = styled.div`
  display: flex;
  margin: 4px 5px 0 10px;
  transform: scale(1.3);
`;

export const Input = styled.input``;

export const Date = styled.div`
  display: flex;
  border-radius: 4px;
  background: white;
  padding: 3px 2px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Record = styled.div`
  padding: 10px 0;
  width: 280px;
  display: flex;
  flex-direction: column;
  background: ${({ even }) => (even ? '#ccd4de' : '#e0ebf3')};
  
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Time = styled.div`
  padding-left: 10px;
  font-size: 14px;
`;

export const GlobalStyle = createGlobalStyle`
  .react-audio-player {
    width: 280px;
    height: 40px;
    outline: none;
  }

  .react-audio-player::-webkit-media-controls-enclosure {
    border-radius: 0;
    background-color: transparent;
  }
  
  @media (max-width: 480px) {
    .react-datepicker-popper {
      left: -60px !important;
    }

    .react-audio-player {
      width: 100%;
    }
  }
`;

Filter.Close = Close;
Filter.Content = Content;

Content.Title = Title;
Content.Line = Line;

Line.Switch = Switch;
Line.Date = Date;

Switch.Input = Input;

Record.Time = Time;
