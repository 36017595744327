import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SearchBar from './SearchBar';
import ExitModalWindow from './ExitModalWindow';
// eslint-disable-next-line import/order
import { useDispatch, useSelector } from 'react-redux';
import SettingsModalWindow from './settingsModalWindow';
import HamburgerMenu from './hamburgerMenu';
import { settingsActions } from '../../../redux/reducers/settings';
import settingsSelectors from '../../../redux/selectors/settings';
import { modalsActions } from '../../../redux/reducers/modals';
import modalsSelectors from '../../../redux/selectors/modals';
import { Wrapper, Content } from './styled';

const HeadBar = ({ intl }) => {
  const { settings: stateSettings, logout: statePopUp } = useSelector(modalsSelectors.getModals);

  const {
    volume,
    visibilityNotification,
    visibilityPushNotification,
  } = useSelector(settingsSelectors.getSettings);

  const dispatch = useDispatch();

  const changeStatePopUp = () => {
    dispatch(modalsActions.setLogoutModalWindow(!statePopUp));
  };

  const onClickGetViewFullVersion = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    params.delete('clientId');
    window.open(`${window.location.origin}?${params}`, 'CRM Chat', {
      menubar: 'no',
      toolbar: 'no',
      location: 'no',
      status: 'no',
    });
  };

  const onClickSettings = () => {
    dispatch(modalsActions.setSettingsModalWindow(!stateSettings));
  };

  const onClickVolume = (value) => {
    dispatch(settingsActions.setVolume(value));
    dispatch(settingsActions.onSendSettings());
  };

  const onClickNotification = () => {
    dispatch(settingsActions.setVisibilityNotification(!visibilityNotification));
    dispatch(settingsActions.onSendSettings());
  };

  const onClickPushNotification = () => {
    dispatch(settingsActions.setVisibilityPushNotification(!visibilityPushNotification));
    dispatch(settingsActions.onSendSettings());
  };

  return (
    <Wrapper>
      <SearchBar intl={intl} />
      <Wrapper.Content>
        <Content.Menu>
          <HamburgerMenu
            onClickSettings={onClickSettings}
            onClickLogOut={changeStatePopUp}
            onClickGetViewFullVersion={onClickGetViewFullVersion}
          />
        </Content.Menu>
      </Wrapper.Content>
      {statePopUp && <ExitModalWindow changeStatePopUp={changeStatePopUp} />}
      {stateSettings && (
        <SettingsModalWindow
          toggle={onClickSettings}
          volume={volume}
          onClickVolume={onClickVolume}
          notification={visibilityNotification}
          pushNotification={visibilityPushNotification}
          onClickNotification={onClickNotification}
          onClickPushNotification={onClickPushNotification}
        />
      )}
    </Wrapper>
  );
};

HeadBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  intl: PropTypes.object.isRequired,
};

export default injectIntl(HeadBar);
