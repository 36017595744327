import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import UserRoomItem from './UserRoomItem';
import config from '../../../config';
// import loadIcon from '../../../images/load.svg';
import { roomListActions } from '../../../redux/reducers/roomList';
import { bindClientActions } from '../../../redux/reducers/bindClient';
import { searchStringActions } from '../../../redux/reducers/searchString';
import searchStringSelectors from '../../../redux/selectors/searchString';
import { roomsActions } from '../../../redux/reducers/rooms';
import roomsSelectors from '../../../redux/selectors/rooms';
import createActions from '../../../redux/reducers/createActions';
import roomIdWithActiveCallSelectors from '../../../redux/selectors/roomIdWithActiveCall';

import { Wrapper, Content, Rooms, Scroll, EmptySearchList } from './styled';
import managerInfoSelectors from '../../../redux/selectors/managerInfo';

const SUPPORT_IDS = ['115426326', '56517192', '47960975', '16259751'];

function usePrevious(newValue) {
  const previousRef = React.useRef();

  React.useEffect(() => {
    previousRef.current = newValue;
  });

  return previousRef.current;
}

const UserRoomsList = ({ intl }) => {
  const [isWithSubordinates, setIsWithSubordinates] = useState(false);
  const rooms = useSelector(roomsSelectors.getRooms);
  const activeRoomID = useSelector(roomsSelectors.getPrevActiveRoomId);
  const activeCall = useSelector(roomIdWithActiveCallSelectors.getRoomIdWithActiveCall);
  const searchString = useSelector(searchStringSelectors.getState);
  const managerId = useSelector(managerInfoSelectors.getManagerId);

  const [isSortASC, setSortASC] = useState(true);
  const scrollRef = useRef();
  const [lastScrollPos, setLastScrollPos] = useState(undefined);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const roomsLengthPrev = usePrevious(Object.keys(rooms).length);

  React.useEffect(() => {
    if (roomsLengthPrev !== Object.keys(rooms).length) {
      setLoading(false);
    }
  }, [rooms]);

  const onSort = () => {
    setSortASC(!isSortASC);
    dispatch(createActions.onCheckUserRooms({
      count: config.numberOfRoomsRequested,
      room_id: null,
      created_at: null,
      is_with_subordinates: isWithSubordinates,
      sorting: {
        fieldName: 'lastMsgTime',
        orderBy: isSortASC ? 'asc' : 'desc',
      },
      tryBind: false,
    }));
  };

  const getUserRooms = (createdAt) => {
    const roomsIds = Object.keys(rooms);
    const lastRoomId = roomsIds.at(-1);

    setLoading(true);

    dispatch(createActions.onGetUserRooms({
      count: config.numberOfRoomsRequested,
      room_id: lastRoomId,
      created_at: createdAt,
      is_with_subordinates: isWithSubordinates,
      tryBind: false,
      sorting: {
        fieldName: 'lastMsgTime',
        orderBy: !isSortASC ? 'asc' : 'desc',
      },
    }));
  };

  const handleChangeWithSubordinates = (event) => {
    setIsWithSubordinates(event.target.checked);
    dispatch(createActions.onCheckUserRooms({
      count: config.numberOfRoomsRequested,
      room_id: null,
      created_at: null,
      is_with_subordinates: event.target.checked,
      sorting: {
        fieldName: 'lastMsgTime',
        orderBy: !isSortASC ? 'asc' : 'desc',
      },
      tryBind: false,
    }));
  };

  // const onClickLoadRooms = () => {
  //   const lastRoom = Object.keys(rooms)[Object.keys(rooms).length - 1];
  //   const array = Object.keys(rooms).map((item) => rooms[item]);
  //   if (array.length > 1) {
  //     array.sort((a, b) => (
  //       new Date(b.last_msg_time) - new Date(a.last_msg_time)));
  //   }
  //   getUserRooms(lastRoom, array[array.length - 1].last_msg_time);
  // };

  const handleOnScroll = () => {
    if (scrollRef.current && !loading) {
      const array = Object.values(rooms);

      if (array.length > 1) {
        array.sort((a, b) => (new Date(b.last_msg_time) - new Date(a.last_msg_time)));
      }

      const { clientHeight, top } = scrollRef.current?.getValues();

      if (lastScrollPos === undefined) {
        setLastScrollPos(clientHeight);
        getUserRooms(array[array.length - 1].last_msg_time);
        return;
      }

      if (top > 0.95) {
        getUserRooms(array[array.length - 1].last_msg_time);
      }
    }
  };

  const setActiveRoomCb = (roomId) => {
    if (activeRoomID === roomId) return;
    dispatch(roomsActions.setActiveRoom(roomId));
    dispatch(searchStringActions.setState(''));
    dispatch(roomListActions.setState(false));
    dispatch(bindClientActions.setState(true));
  };

  const listRooms = Object.keys(rooms).map((key) => {
    if (searchString !== ''
        && rooms[key]
        && rooms[key].client_id.indexOf(searchString) === -1
        && rooms[key].client_name.indexOf(searchString) === -1) {
      return false;
    }
    let isActiveRoom = false;
    if (key === activeRoomID) {
      isActiveRoom = true;
    }

    return (
      <UserRoomItem
        key={key}
        intl={intl}
        room={rooms[key]}
        time={rooms[key]?.latest_msg?.time || ''}
        roomId={key}
        isActive={isActiveRoom}
        setActiveRoom={setActiveRoomCb}
        conversationCount={rooms[key].conversation_count}
      />
    );
  }).filter((room) => room !== false);

  if (Number.isNaN(Number(searchString))) {
    return (<EmptySearchList>No leads found</EmptySearchList>);
  }

  const sortByTime = (roomA, roomB) => {
    if (activeCall === roomA.props.room.id) {
      return -1;
    }
    if (activeCall === roomB.props.room.id) {
      return 1;
    }
    if (isSortASC) {
      return new Date(roomB.props.time) - new Date(roomA.props.time);
    }
    return new Date(roomA.props.time) - new Date(roomB.props.time);
  };

  const sortByID = (roomA, roomB) => {
    const roomAName = roomA.props.room.id.indexOf(searchString);
    const roomBName = roomB.props.room.id.indexOf(searchString);

    if (roomAName > roomBName) {
      return 1;
    }
    if (roomAName < roomBName) {
      return -1;
    }
    return 0;
  };

  if (searchString !== '') {
    listRooms.sort(sortByID);
  } else {
    listRooms.sort(sortByTime);
  }

  return (
    <>
      <Wrapper>
        <Wrapper.Sort onClick={onSort}>
          {intl.formatMessage({ id: 'sortByActivity' })}
          <Content.Icon>
            {isSortASC ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
          </Content.Icon>
        </Wrapper.Sort>
        {SUPPORT_IDS.includes(managerId) ? <span /> : (
          <Wrapper.Label>
            <Wrapper.Content>
              <Wrapper.Checkbox value={isWithSubordinates} onChange={handleChangeWithSubordinates} />
            </Wrapper.Content>
            TeamLead
          </Wrapper.Label>
        )}
      </Wrapper>
      <Rooms>
        <Rooms.Scroll
          ref={scrollRef}
          onScroll={handleOnScroll}
        >
          <Scroll.List>{listRooms}</Scroll.List>
        </Rooms.Scroll>
      </Rooms>
    </>
  );
};

UserRoomsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.any.isRequired,
};

export default UserRoomsList;
