import { createSlice } from '@reduxjs/toolkit';

const storeName = 'editedMessage';

const initialState = {
  visibility: false,
  edited: false,
  text: '',
  id: null,
};

export const editedMessageSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setVisibility(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.visibility = action.payload;
    },
    setEdited(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.edited = action.payload;
    },
    setText(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.text = action.payload;
    },
    setId(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.id = action.payload;
    },
  },
});

export const editedMessageActions = {
  setId: editedMessageSlice.actions.setId,
  setText: editedMessageSlice.actions.setText,
  setEdited: editedMessageSlice.actions.setEdited,
  setVisibility: editedMessageSlice.actions.setVisibility,
};

export const editedMessageReducer = editedMessageSlice.reducer;
