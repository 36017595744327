import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import fileListSelectors from '../../../../../../redux/selectors/fileList';
import { Wrapper, Window, Header, Body, File, Footer } from './styled';

const ConfirmationFilesModalWindow = ({ changeShowConfirmationModal, onSend, changeListFile, clearFilesValue }) => {
  const files = useSelector(fileListSelectors.getFileList);

  useEffect(() => {
    if (files.length === 0) {
      changeShowConfirmationModal();
      clearFilesValue();
    }
  }, [files]);

  return (
    <Wrapper>
      <Wrapper.Window>
        <Window.Header>
          <Header.Title>Confirmation send files</Header.Title>
          <Header.IconClose onClick={changeShowConfirmationModal} />
        </Window.Header>
        <Window.Body>
          {files.map((file, index) => (
            <Body.File key={file.name}>
              <File.Icon />
              <File.Name title={file.name}>
                {file.name}
              </File.Name>
              <File.Delete
                onClick={() => {
                  changeListFile(index.toString());
                }}
              />
            </Body.File>
          ))}
        </Window.Body>
        <Window.Footer>
          <Footer.ButtonClose onClick={changeShowConfirmationModal}>Cancel</Footer.ButtonClose>
          <Footer.ButtonSend onClick={() => onSend(files)}>Send</Footer.ButtonSend>
        </Window.Footer>
      </Wrapper.Window>
    </Wrapper>
  );
};

export default ConfirmationFilesModalWindow;
