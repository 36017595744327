import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import { editedMessageActions } from '../reducers/editedMessage';
import editedMessageSelectors from '../selectors/editedMessage';
import { messagesActions } from '../reducers/messages';
import messagesSelectors from '../selectors/messages';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* sendEditMessage(action) {
  if (!action || !action.payload) return;
  try {
    const { text, roomId } = action.payload;
    const { id } = yield select(editedMessageSelectors.getStore);
    const rooms = JSON.parse(JSON.stringify(yield select(roomsSelectors.getRooms)));
    const messagesReducer = yield select(messagesSelectors.getStore);
    const { messages, expirationTime } = JSON.parse(JSON.stringify(messagesReducer[roomId]));
    const { client_id, latest_msg } = rooms[roomId];
    const argsMessage = { msg_id: id, client_id, text };
    const args = yield call(chatAPI.sendEditMessage, argsMessage);
    const msg = Object.keys(messages).filter((key) => messages[key].id === args.msg_id);
    messages[msg[0]].type = 4;
    messages[msg[0]].text = text;
    if (latest_msg.id === args.msg_id) {
      latest_msg.text = args.text;
      yield put(roomsActions.setUpdateRoomSettings({
        id: roomId,
        room: {
          latest_msg,
        },
      }));
    }
    yield put(editedMessageActions.setEdited(false));
    yield put(editedMessageActions.setVisibility(false));
    yield put(editedMessageActions.setId(null));
    yield put(editedMessageActions.setText(''));
    yield put(messagesActions.setMessages({ messages, room_id: roomId, rooms, expirationTime }));
  } catch (error) {
    yield put(editedMessageActions.setEdited(false));
    yield put(editedMessageActions.setVisibility(false));
    yield put(editedMessageActions.setId(null));
    yield put(editedMessageActions.setText(''));
    yield call(toast.error, `sendEditMessage-saga: ${error.message}`);
    logError(error);
  }
}
