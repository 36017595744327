import styled from 'styled-components';
import { MdAdd } from 'react-icons/md';
import { MenuButton } from '@szhsin/react-menu';

export const Wrapper = styled(MenuButton)`
  border: none;
  border-radius: 0;
  background: inherit;
  font-size: 1.5em;
  text-decoration: none;
  cursor: pointer;
  outline:none;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background: inherit;
  }
`;

export const Icon = styled(MdAdd)`
  color: #2b61b1;
`;

Wrapper.Icon = Icon;
