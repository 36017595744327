import React from 'react';
import moment from 'moment';
import { Wrapper } from './styled';
import svcLang from '../../../../../locales/svcMessages';

const SystemMessage = (props) => {
  const { message: { text, time }, room: { client_lang: Lang } } = props;

  const svcTypes = {
    1: 'SvcCallStarted',
    2: 'SvcCallEnded',
    3: 'SvcCallRejected',
    4: 'SvcCallMissed',
    5: 'SvcSwitchTo',
  };

  const getText = () => {
    switch (text.split(';')[0]) {
    case '1':
    case '2':
    case '3':
    case '4':
      return svcLang[svcTypes[text]][Lang];
    default: return `${text.split(';')[1]} ${svcLang[svcTypes['5']][Lang]}`;
    }
  };

  return (
    <Wrapper>
      <Wrapper.Text>
        {`${moment(time).format('hh.mm')} ${getText()}`}
      </Wrapper.Text>
    </Wrapper>
  );
};

export default SystemMessage;
