import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import VisibilitySensor from 'react-visibility-sensor';
import _debounce from 'lodash/debounce';
import { hideMenu } from 'react-contextmenu';
import config from '../../../config';
import { emptyRoom, messagesActions } from '../../../redux/reducers/messages';
import chatMessagesUpdaterSelectors from '../../../redux/selectors/chatMessagesUpdater';
import messagesSelectors from '../../../redux/selectors/messages';
import createActions from '../../../redux/reducers/createActions';
import { Wrapper, Container, MessageNewDay } from './styled';
import MessageContainer from './MessageContainer';

export const RoomType = PropTypes.shape({
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
});

const Chat = (props) => {
  const { activeRoom = {}, activeRoomID, managerID } = props;
  const { oldest_msg_id = 0, latest_msg = {}, client_id, unread_msg_count } = activeRoom;
  const scrollRef = useRef(undefined);
  const chatContainerRef = useRef(undefined);
  const [countMsg, setCountMsg] = useState([]);
  let lastScrollPos;
  let historyAlreadyFetched = false;
  let unreadAlreadyFetched = false;
  let maxId = 0;
  const rooms = useSelector((messagesSelectors.getStore));
  const room = rooms[activeRoomID];
  const lastUpdate = useSelector(chatMessagesUpdaterSelectors.getChatMessagesUpdater);
  const allRoomsMessages = useSelector(messagesSelectors.getStore);
  const messagesActiveRoomID = allRoomsMessages[activeRoomID];
  const {
    messages,
    historyEndReached,
    chatScrollPosition,
    initialHistoryFetched,
    unreadEndReached,
  } = room || emptyRoom;

  const dispatch = useDispatch();

  useEffect(() => {
    if (messages) scrollRef.current.scrollTop(chatScrollPosition.scrollTop);
    if ((!messages && !initialHistoryFetched) || !historyEndReached) {
      let fromMsg = latest_msg?.id;

      if (fromMsg === 0 && messages.length > 0) {
        fromMsg = messages[messages.length - 1].id;
      }

      if (fromMsg > 0) {
        dispatch(createActions.onGetHistoryMessage(
          {
            roomId: activeRoomID,
            msgId: fromMsg,
            count: config.messageHistoryQueryCount,
          },
        ));
      }
    }
  }, [activeRoomID]);

  useEffect(() => {
    const clientHeight = scrollRef.current.getClientHeight();
    const scrollHeight = scrollRef.current.getScrollHeight();
    const { room_id, client_id: updaterId } = lastUpdate;
    if (
      activeRoomID === room_id
      && clientHeight === scrollHeight
      && updaterId !== managerID
      && !unreadEndReached) {
      dispatch(createActions.onNextUnreadMessage({
        roomId: activeRoomID,
        msgId: maxId,
      }));
    }
    if (room_id === activeRoomID) {
      if (chatScrollPosition.top >= 0.98) {
        scrollRef.current.scrollTop(scrollHeight - clientHeight);
      } else if (client_id === managerID) {
        scrollRef.current.scrollTop(scrollHeight - clientHeight);
      } else {
        scrollRef.current.scrollTop(chatScrollPosition.scrollTop);
      }
    }
  }, [lastUpdate]);

  useEffect(() => {
    if ((messages.length - countMsg.length) === 1) scrollRef.current.scrollToBottom();
    setCountMsg(messages);
    if (!initialHistoryFetched && ((messages && messages.length > 0) || historyEndReached)) {
      scrollRef.current.scrollToBottom();
      dispatch(messagesActions.setRoomScroll({
        chatScrollPosition: scrollRef.current.getValues(),
        roomId: activeRoomID,
      }));
      dispatch(messagesActions.setInitialHistoryFetched({
        roomId: activeRoomID,
        initialHistoryFetched: true,
      }));
    }
  }, [activeRoomID, messages]);

  useEffect(() => {
    scrollRef.current.scrollToBottom();
  }, [activeRoomID]);

  useEffect(() => {
    if (unreadEndReached) {
      const clientHeight = scrollRef.current.getClientHeight();
      const scrollHeight = scrollRef.current.getScrollHeight();
      if (clientHeight === scrollHeight) {
        dispatch(createActions.onNextUnreadMessage({
          roomId: activeRoomID,
          msgId: maxId,
        }));
      }
    }
  }, [unreadEndReached]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const initHeight = chatContainerRef.current.clientHeight;
      window.addEventListener('resize', _debounce(() => {
        if (scrollRef.current && scrollRef.current.getValues()) {
          const { clientHeight, scrollHeight } = scrollRef.current.getValues();
          if (chatContainerRef.current.clientHeight < initHeight && scrollHeight > clientHeight) {
            scrollRef.current.scrollTop(scrollHeight - clientHeight);
          }
        }
      }, 150));
    }
  }, []);

  let currentDay;

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const getTimeForDivider = (msgTime) => {
    let timeForDivider = '';
    if (!currentDay) {
      currentDay = msgTime;
      return timeForDivider;
    }

    if (msgTime.getFullYear() !== currentDay.getFullYear()
      || msgTime.getDay() !== currentDay.getDay()
      || msgTime.getMonth() !== currentDay.getMonth()) {
      timeForDivider = `${currentDay.getDate()} ${monthNames[currentDay.getMonth()]} ${currentDay.getFullYear()}`;
      currentDay = msgTime;
    }
    return timeForDivider;
  };

  const messagesJsx = messages.map((msg) => {
    const isMyMsg = (managerID === msg.sender || msg.sender !== client_id);
    const cs = document.getElementById('chat_scroller');
    const et = document.getElementsByClassName('system_message_item expiration');
    Array.prototype.forEach.call(et, (el, index) => {
      if (index !== 0) {
        // eslint-disable-next-line no-param-reassign
        el.style.display = 'none';
      }
    });
    const { id } = msg;
    const divider = getTimeForDivider(new Date(msg.time));
    const timeDate = new Date(msg.time);
    const timeExpirationDate = new Date(messagesActiveRoomID?.expirationTime);
    return (
      <VisibilitySensor key={id} containment={cs} partialVisibility>
        {({ isVisible }) => {
          if (isVisible && id > maxId) maxId = id;
          return (
            <MessageContainer
              message={msg}
              isMyMsg={isMyMsg}
              timeDivider={divider}
              room={activeRoom}
              clientId={client_id}
              expirationState={timeDate <= timeExpirationDate}
              action={{
                visibility,
                deleteMsgs,
                setQuotedMessage,
                editMsg,
                deleteHistory,
              }}
            />
          );
        }}
      </VisibilitySensor>
    );
  });

  if (messages.length !== 0) {
    const timeFirstMsg = new Date(messages[messages.length - 1].time);
    messagesJsx.push((
      <MessageNewDay key={timeFirstMsg.toString()}>
        <MessageNewDay.Text>
          {`${timeFirstMsg.getDate()} ${monthNames[timeFirstMsg.getMonth()]} ${timeFirstMsg.getFullYear()}`}
        </MessageNewDay.Text>
      </MessageNewDay>
    ));
  }

  const handleScrollStop = () => {
    if (oldest_msg_id && oldest_msg_id > 0 && unread_msg_count !== 0) {
      dispatch(createActions.onNextUnreadMessage({
        roomId: activeRoomID,
        msgId: maxId,
      }));
    }
    if (scrollRef.current) {
      dispatch(messagesActions.setRoomScroll({
        chatScrollPosition: scrollRef.current.getValues(),
        roomId: activeRoomID,
      }));
    }
  };

  const handleOnScroll = () => {
    hideMenu();

    if (scrollRef.current) {
      if (typeof lastScrollPos === 'undefined') {
        lastScrollPos = scrollRef.current.getValues().top;
        return;
      }

      if (lastScrollPos > scrollRef.current.getValues().top) {
        lastScrollPos = scrollRef.current.getValues().top;
        if ((lastScrollPos < 0.1) && !historyAlreadyFetched && !historyEndReached) {
          historyAlreadyFetched = true;
          let fromMsg = 0;
          if (messages.length > 0) {
            fromMsg = messages[messages.length - 1].id;
          } else {
            fromMsg = oldest_msg_id > 0 ? oldest_msg_id : latest_msg.id;
          }
          if (fromMsg > 0) {
            dispatch(createActions.onGetHistoryMessage(
              {
                roomId: activeRoomID,
                msgId: fromMsg,
                count: config.messageHistoryQueryCount,
              },
            ));
          }
        }
      }

      if (lastScrollPos < scrollRef.current.getValues().top) {
        lastScrollPos = scrollRef.current.getValues().top;
        if (lastScrollPos > 0.9 && oldest_msg_id > 0) {
          let fromMsg = 0;
          if (messages.length > 0) {
            fromMsg = messages[0].id;
          } else {
            fromMsg = oldest_msg_id > 0 ? oldest_msg_id : latest_msg.id;
          }
          if (fromMsg > 0 && !unreadEndReached && !unreadAlreadyFetched) {
            unreadAlreadyFetched = true;
            dispatch(createActions.onGetRoomUnreadMessages({
              roomId: activeRoomID,
              msgId: fromMsg,
              count: config.messageHistoryQueryCount,
            }));
          }
        }
      }
    }
  };

  const visibility = (msgId) => {
    dispatch(createActions.onMessageSelectionVisibility({
      visibility: true,
      msgId,
    }));
  };

  const deleteMsgs = (msgs) => {
    dispatch(createActions.onDeleteMessages(msgs));
  };

  const deleteHistory = (id) => {
    dispatch(createActions.onDeleteHistoryMessages(id));
  };

  const setQuotedMessage = (message) => {
    dispatch(createActions.onQuotedMessage({
      visibility: true,
      message,
    }));
  };

  const editMsg = (id, text) => {
    dispatch(createActions.onEditingMessage({ id, text }));
  };

  return (
    <Wrapper>
      <Wrapper.Container ref={chatContainerRef}>
        <Scrollbars
          id="chat_scroller"
          autoHide
          ref={scrollRef}
          onScroll={handleOnScroll}
          onScrollStop={handleScrollStop}
        >
          <Container.Messages>{messagesJsx}</Container.Messages>
        </Scrollbars>
      </Wrapper.Container>
    </Wrapper>
  );
};

Chat.propTypes = {
  managerID: PropTypes.string.isRequired,
  activeRoom: RoomType.isRequired,
  activeRoomID: PropTypes.string.isRequired,
};

export default Chat;
