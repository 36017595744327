import { createSlice } from '@reduxjs/toolkit';

const storeName = 'microphoneStatus';

const initialState = {
  state: true,
};

export const microphoneStatusSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const microphoneStatusActions = {
  setState: microphoneStatusSlice.actions.setState,
};

export const microphoneStatusReducer = microphoneStatusSlice.reducer;
