import { createAction, createSlice } from '@reduxjs/toolkit';

const storeName = 'settings';

const initialState = {
  volume: 1.0,
  visibilityNotification: true,
  visibilityPushNotification: true,
};

export const settingsSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setVolume(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.volume = action.payload;
    },
    setSettings(state, action) {
      // eslint-disable-next-line no-param-reassign
      state = action.payload;
    },
    setVisibilityNotification(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.visibilityNotification = action.payload;
    },
    setVisibilityPushNotification(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.visibilityPushNotification = action.payload;
    },
  },
});

export const settingsActions = {
  setVolume: settingsSlice.actions.setVolume,
  setSettings: settingsSlice.actions.setSettings,
  setVisibilityNotification: settingsSlice.actions.setVisibilityNotification,
  setVisibilityPushNotification: settingsSlice.actions.setVisibilityPushNotification,

  onSendSettings: createAction(`${storeName}/onSendSettings`),
};

export const settingsReducer = settingsSlice.reducer;
