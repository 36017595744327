import { put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { messageSelectionActions } from '../reducers/messageSelection';
import messageSelectionSelectors from '../selectors/messageSelection';
import { logError } from '../../helper/sentry';

export default function* messageSelectionVisibility(action) {
  if (!action && !action.payload) return;
  try {
    const { visibility, msgId } = action.payload;
    const { selectedMessages } = yield select(messageSelectionSelectors.getMessageSelection);
    const array = JSON.parse(JSON.stringify(selectedMessages));
    if (selectedMessages.includes(msgId)) {
      const index = array.indexOf(msgId);
      if (index > -1) array.splice(index, 1);
    } else {
      array.push(msgId);
    }
    yield put(messageSelectionActions.setVisibility(visibility));
    if (msgId === 'delete') {
      yield put(messageSelectionActions.clear());
    } else {
      yield put(messageSelectionActions.setSelectedMessages(array));
    }
  } catch (error) {
    yield call(toast.error, `messageSelectionVisibility-saga: ${error.message}`);
    logError(error);
  }
}
