import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import { recordingsActions } from '../reducers/recordings';
import { logError } from '../../helper/sentry';

export default function* getRecordings(a) {
  if (!a || !a.payload) return;
  try {
    const { leadId } = a.payload;
    const args = yield call(chatAPI.getRecordings, leadId);
    const { recordings } = args;
    yield put(recordingsActions.setRecording({
      recordings,
      leadId,
    }));
  } catch (error) {
    yield call(toast.error, `getRecordings-saga: ${error.message}`);
    logError(error);
  }
}
