/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';

const storeName = 'statistics';

const initialState = {
  statistic: null,
  filters: {
    isLoading: true,
  },
};
export const statisticsSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setStatistic(state, action) {
      state.statistic = action.payload;
      state.filters = {
        ...state.filters,
        from: action.payload.date_from ? new Date(action.payload.date_from) : null,
        to: action.payload.date_to ? new Date(action.payload.date_to) : null,
        isLoading: false,
      };
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const statisticActions = {
  setStatistic: statisticsSlice.actions.setStatistic,
  setFilters: statisticsSlice.actions.setFilters,
  getStatisticsRequest: createAction(`${storeName}/getStatisticsRequest`),
};

export const statisticsReducer = statisticsSlice.reducer;
