import React from 'react';
import { AiOutlineWarning, VscClose, VscQuote } from 'react-icons/all';
import { WarningMessage, Wrapper } from './styled';
import { Container, Preloader, Content } from '../../Chat/MessageContainer/ImageMessage/styled';
import { splitFileNameAndUrl } from '../../../../helper/links';

const Quoted = ({ clientVersion, text, sender, clientId, time, onClose, isImage }) => {
  const { url = '' } = (isImage && splitFileNameAndUrl(text)) || {};
  const imageView = splitFileNameAndUrl(text)
    ? (
      <Container.QuotedImage
        down
        src={url}
        unloader="Ops... Failed to download image"
        loader={(
          <Preloader>
            <Preloader.Content>
              <Content.First />
              <Content.Second />
              <Content.Third />
            </Preloader.Content>
          </Preloader>
        )}
      />
    )
    : (
      <Preloader>
        <Preloader.Content>
          <Content.First />
          <Content.Second />
          <Content.Third />
        </Preloader.Content>
      </Preloader>
    );
  return (
    <>
      {clientVersion === 'Mobile Version' && (
        <WarningMessage>
          <AiOutlineWarning />
          Client v1.0 (Mobile client): Inappropriate rendering
        </WarningMessage>
      )}
      <Wrapper>
        <VscQuote />
        <Wrapper.Message title={text}>
          {isImage ? imageView : text}
        </Wrapper.Message>
        <Wrapper.Sender>
          {sender === clientId ? 'Client' : 'Manager'}
          {`, ${time}`}
        </Wrapper.Sender>
        <Wrapper.Close onClick={onClose}>
          <VscClose />
        </Wrapper.Close>
      </Wrapper>
    </>
  );
};

export default Quoted;
