import { createSlice } from '@reduxjs/toolkit';

const storeName = 'unreadMsgCount';

const initialState = {
  state: 0,
};

export const unreadMsgCountSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.state = action.payload;
    },
  },
});

export const unreadMsgCountActions = {
  setState: unreadMsgCountSlice.actions.setState,
};

export const unreadMsgCountReducer = unreadMsgCountSlice.reducer;
