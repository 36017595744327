import styled, { keyframes } from 'styled-components';

const blinker = keyframes`
  from { opacity: 1.0; }
  to { opacity: 0.0; }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .75em 1.5em .75em 1.5em;
  background-color: #CFD2DE;
  height: 2.6em;
  align-items: center;
  position: relative;
  
  @media (max-width: 480px) {
    background-color: #202933;
    padding: 0.75em 1em .75em 1em;
  }
`;

export const ButtonBack = styled.div`
  display: none;
  color: #202933;
  font-size: 40px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 480px) {
    display: flex;
    color: #81a1d0;
  }
`;

export const Unread = styled.div`
  display: none;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  top: 4px;
  left: 8px;
  background: #81a1d0;
  animation-name: ${blinker};
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1.0,0,0,1.0);
  animation-duration: 2s;

  @media (max-width: 480px) {
    display: block;
  }
`;

export const Info = styled.div`
  display: inline-block;
  width: 60px;
  font-size: 1.25em;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1 0;
  padding-right: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Name = styled.div`
  text-align: left;
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  user-select: text;
  font-weight: bold;

  @media (max-width: 480px) {
    color: #fff;
  }
`;

export const Description = styled.div`
  padding: 2px;
  font-size: .7em;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 480px) {
    margin-top: 3px;
  }
`;

export const Status = styled.div`
  margin-right: 5px;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: #4A9D45;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: #4c4949;
  
  & > svg {
    margin-left: 7px;
    margin-right: 1px;
  }

  @media (max-width: 480px) {
    color: #fff;
  }
`;

export const SubTitle = styled.span`
  padding-right: 7px;
  border-right: 1px solid #4c4949;

  @media (max-width: 480px) {
    border-right: 1px solid #fff;
  }
`;

export const Lang = styled.span``;

export const Recording = styled.div`
  color: ${({ visibility }) => (visibility ? '#81a1d0' : '#000')};
  margin-left: 5px;
  cursor: pointer;
  
  @media (max-width: 480px) {
    & > svg {
      color: #fff;
    }
  }
`;

Wrapper.ButtonBack = ButtonBack;
Wrapper.Info = Info;

ButtonBack.Unread = Unread;

Info.Content = Content;

Content.Name = Name;
Content.Description = Description;

Description.Status = Status;
Description.Container = Container;

Container.SubTitle = SubTitle;
Container.Lang = Lang;
