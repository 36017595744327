import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Window = styled.div`
  background: #fff;
  color: black;
  max-width: 360px;
  width: 100%;
  border-radius: .3rem;
  
  @media (max-width: 400px) {
    max-width: 320px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

export const Title = styled.h5`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

export const SubTitle = styled.p`
  margin: 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ButtonClose = styled.div`
  color: #ffffff;
  background-color: #6c757d;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: all .3s cubic-bezier(.47,0,.745,.715);
  margin-left: 10px;
  
  &:hover {
    background-color: #5a6268;
  }
`;

export const ButtonExit = styled.div`
  color: #ffffff;
  background-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: all .3s cubic-bezier(.47,0,.745,.715);
  margin-left: 10px;

  &:hover {
    background: #228cdb;
  }
`;

Wrapper.Window = Window;

Window.Header = Header;
Window.Body = Body;
Window.Footer = Footer;

Header.Title = Title;

Body.SubTitle = SubTitle;

Footer.ButtonClose = ButtonClose;
Footer.ButtonExit = ButtonExit;
