import { createAction } from '@reduxjs/toolkit';

const storeName = 'createActions';

const createActions = {
  onAuth: createAction(`${storeName}/onAuth`),
  onInitChat: createAction(`${storeName}/onInitChat`),
  onChangeCallStatus: createAction(`${storeName}/onChangeCallStatus`),
  onChangeClientStatus: createAction(`${storeName}/onChangeClientStatus`),
  onChangeFileList: createAction(`${storeName}/onChangeFileList`),
  onCheckingEditedMessage: createAction(`${storeName}/onCheckingEditedMessage`),
  onClientMsgRead: createAction(`${storeName}/onClientMsgRead`),
  onCloseEditedMessage: createAction(`${storeName}/onCloseEditedMessage`),
  onDeleteHistoryMessages: createAction(`${storeName}/onDeleteHistoryMessages`),
  onDeleteMessages: createAction(`${storeName}/onDeleteMessages`),
  onEditingMessage: createAction(`${storeName}/onEditingMessage`),
  onGetHistoryMessage: createAction(`${storeName}/onFileList`),
  onGetRecordings: createAction(`${storeName}/onGetRecordings`),
  onGetRoomImageLinks: createAction(`${storeName}/onGetRoomImageLinks`),
  onGetRoomUnreadMessages: createAction(`${storeName}/onGetRoomUnreadMessages`),
  onGetUserRoom: createAction(`${storeName}/onGetUserRoom`),
  onGetUserRooms: createAction(`${storeName}/onGetUserRooms`),
  onCheckUserRooms: createAction(`${storeName}/onCheckUserRooms`),
  onMessageSelectionVisibility: createAction(`${storeName}/onMessageSelectionVisibility`),
  onMicrophoneStatus: createAction(`${storeName}/onMicrophoneStatus`),
  onNextUnreadId: createAction(`${storeName}/onNextUnreadId`),
  onNextUnreadMessage: createAction(`${storeName}/onNextUnreadMessage`),
  onGetUserRoomsSaga: createAction(`${storeName}/onGetUserRoomsSaga`),
  onOpenChat: createAction(`${storeName}/onOpenChat`),
  onQuotedMessage: createAction(`${storeName}/onQuotedMessage`),
  onReceivedEditedMessage: createAction(`${storeName}/onReceivedEditedMessage`),
  onReceivedMessage: createAction(`${storeName}/onReceivedMessage`),
  onSearchClient: createAction(`${storeName}/onSearchClient`),
  onSendEditMessage: createAction(`${storeName}/onSendEditMessage`),
  onSendFiles: createAction(`${storeName}/onSendFiles`),
  onSendFireBaseToken: createAction(`${storeName}/onSendFireBaseToken`),
  onSendManagerSettings: createAction(`${storeName}/onSendManagerSettings`),
  onSendMessage: createAction(`${storeName}/onSendMessage`),
  onShowErrorNotify: createAction(`${storeName}/onShowErrorNotify`),
  onShowInfoNotify: createAction(`${storeName}/onShowInfoNotify`),
  onShowOfflineCallPreloader: createAction(`${storeName}/onShowOfflineCallPreloader`),
  onStartOfflineCall: createAction(`${storeName}/onStartOfflineCall`),
  onTryBindClient: createAction(`${storeName}/onTryBindClient`),
  onUnreadMsgCount: createAction(`${storeName}/onUnreadMsgCount`),
  onUpdateUserRooms: createAction(`${storeName}/onUpdateUserRooms`),
  onClientConnected: createAction(`${storeName}/onClientConnected`),
  onClientDisconnected: createAction(`${storeName}/onClientDisconnected`),
  onChangeClientStatuses: createAction(`${storeName}/onChangeClientStatuses`),
};

export default createActions;
