import config from '../config';

export const extractFileNameAndUrl = (str) => {
  const matches = str.match('^name:{(.+)},url:{(.+)}$');
  if (matches.length === 3) {
    return {
      name: matches[1],
      url: matches[2],
    };
  }
  return undefined;
};

export const splitFileNameAndUrl = (str) => {
  const matches = str.split('_');
  if (matches.length > 2) {
    return {
      name: matches.slice(2).join('_'),
      url: `${config.baseFileStorageURL}/${encodeURIComponent(str)}`,
    };
  }
  return undefined;
};
