import styled, { keyframes } from 'styled-components';
import Img from 'react-image';

const size = '50px';
const thickness = '4px';
const duration = '1s';

const first = keyframes`
  0% { 
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); 
  }
  100% { 
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); 
  }
`;

const second = keyframes`
  0% { 
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); 
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
`;

const third = keyframes`
  0% { 
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); 
  }
  100% { 
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); 
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em 0.7em;
  border-radius: 5px;
  word-break: break-word;
  color: #fff;
  background-color: ${({ isMyMsg, msgDelete, expiration }) => {
    if (expiration) return '#494949';
    // eslint-disable-next-line no-nested-ternary
    const result = msgDelete ? '#cfd2de' : isMyMsg ? '#75808d' : '#387ce2e6';
    return result;
  }};
  opacity:${({ msgDelete }) => (msgDelete ? 0.25 : 1)};
  text-align: ${({ isMyMsg }) => (isMyMsg ? 'left' : 'inherit')};
`;

export const Container = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Image = styled(Img)`
  max-height: 300px;
  height: 100%;
  max-width: 100%;
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 13px;
`;

export const QuotedImage = styled(Img)`
  max-height: 150px;
  height: 100%;
  max-width: 100%;
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 13px;
`;

export const Preloader = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: relative;
  width: ${size};
  height: ${size};
`;

export const First = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${duration} linear infinite;
  border-bottom: ${thickness} solid #3b99f8;
  animation-name: ${first};
`;

export const Second = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${duration} linear infinite;
  border-right: ${thickness} solid #d28908;
  animation-name: ${second};
`;

export const Third = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${duration} linear infinite;
  border-top: ${thickness} solid #0ab405;
  animation-name: ${third};
`;

Wrapper.Container = Container;

Container.Image = Image;
Container.QuotedImage = QuotedImage;

Preloader.Content = Content;

Content.First = First;
Content.Second = Second;
Content.Third = Third;
