import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const RoomType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  unreadMsgCount: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  latestMsg: PropTypes.object,
  call_permission: PropTypes.oneOf(['allowed', 'not_allowed', 'unknown']),
  chat_permission: PropTypes.oneOf(['allowed', 'not_allowed', 'unknown']),
});
