const actions = {};

Object.defineProperties(actions, {
  UPDATE_ROOM_SETTINGS: { value: 'UPDATE_ROOM_SETTINGS', writable: false },
  SHOW_OFFLINE_CALL_PRELOADER: { value: 'SHOW_OFFLINE_CALL_PRELOADER', writable: false },
  UNREAD_MSG_COUNT: { value: 'UNREAD_MSG_COUNT', writable: false },

  TEXT: { value: 0, writable: false },
  SYSTEM_MESSAGE_TURN_OFF: { value: 1, writable: false },
  SYSTEM_MESSAGE_TURN_ON: { value: 2, writable: false },
  ANOTHER_FILE: { value: 6, writable: false },
  MSG_STATUS_READ: { value: 2, writable: false },
  MSG_TYPE_TXT: { value: 0, writable: false },
  MSG_TYPE_FILE: { value: 1, writable: false },
  MSG_TYPE_IMAGE: { value: 2, writable: false },
  MSG_TYPE_SVC: { value: 3, writable: false },
  MSG_TYPE_EDIT: { value: 4, writable: false },
});

export default actions;
