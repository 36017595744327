import React from 'react';
import { MdDelete, VscClose } from 'react-icons/all';
import AddMenu from './AddMenu';
import cancelPic from '../../../../images/cancel.svg';
import sendPic from '../../../../images/send.svg';
import { Wrapper, Deleted, Cancel, Icon, SendBoxContainer, Button } from './styled';

const SendBox = (props) => {
  const {
    text,
    roomId,
    visibility,
    placeholder,
    selectedMessages,
    editedMessageStore,
    sendMsg,
    setText,
    deleteMessages,
    handleOnKeyDown,
    closeEditMessage,
    cancelSelectedMessage,
  } = props;

  return (
    <Wrapper>
      {visibility ? (
        <Wrapper.Deleted>
          <Deleted.Cancel onClick={cancelSelectedMessage}>
            <VscClose />
            <Cancel.Text>Cancel</Cancel.Text>
          </Deleted.Cancel>
          <Deleted.SelectedMessages>
            {`Selected ${selectedMessages.length} message(s)`}
          </Deleted.SelectedMessages>
          <Deleted.Icon onClick={deleteMessages}>
            <MdDelete />
            <Icon.Text>Delete</Icon.Text>
          </Deleted.Icon>
        </Wrapper.Deleted>
      ) : (
        <>
          {!editedMessageStore.visibility && <AddMenu roomId={roomId} />}
          <Wrapper.SendBoxContainer>
            <SendBoxContainer.Input
              value={text}
              maxRows={15}
              minRows={1}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={handleOnKeyDown}
              placeholder={placeholder}
            />
            {!editedMessageStore.edited && editedMessageStore.visibility ? (
              <SendBoxContainer.Button onClick={closeEditMessage}>
                <Button.Image src={cancelPic} alt="Cancel" />
              </SendBoxContainer.Button>
            ) : (
              <SendBoxContainer.Button onClick={sendMsg}>
                <Button.Image src={sendPic} alt="Send" />
              </SendBoxContainer.Button>
            )}
          </Wrapper.SendBoxContainer>
        </>
      )}
    </Wrapper>
  );
};

export default SendBox;
