import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* (a) {
  if (!a || !a.payload) return;
  const { room_id, message_id } = a.payload;
  if (typeof room_id !== 'string' || room_id.length === 0) {
    console.error(new Error('invalid roomId parameter'));
    return;
  }
  if (typeof message_id !== 'number' || message_id < 0) {
    console.error(new Error('invalid messageId parameter'));
    return;
  }

  try {
    const rooms = yield select(roomsSelectors.getRooms);
    const room = rooms[room_id] || {};
    let { unread_msg_count = 0 } = room;
    unread_msg_count = message_id ? unread_msg_count : 0;
    yield put(roomsActions.setUpdateRoomSettings({
      id: room_id,
      room: {
        oldest_msg_id: message_id,
        unread_msg_count,
      },
    }));
  } catch (error) {
    yield call(toast.error, `nextUnreadId-saga: ${error.message}`);
    logError(error);
  }
}
