import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as chatAPI from '../../infra/api/ChatAPI';
import { roomListActions } from '../reducers/roomList';
import { bindClientActions } from '../reducers/bindClient';
import { searchStringActions } from '../reducers/searchString';
import { roomsActions } from '../reducers/rooms';
import roomsSelectors from '../selectors/rooms';
import { logError } from '../../helper/sentry';

export default function* tryBind(action) {
  if (!action || !action.payload) return;
  const searchClientID = action.payload;
  if (searchClientID) {
    try {
      const args = yield call(chatAPI.tryBindClient, searchClientID);
      const { bound, isManager, newRoom } = args;

      yield put(bindClientActions.setState(bound));

      if (!bound) {
        yield put(roomsActions.setActiveRoom(''));
        return;
      }

      if (isManager) {
        yield put(searchStringActions.setState(''));
      }

      let activeRoomID;
      const rooms = yield select(roomsSelectors.getRooms);

      if (newRoom) {
        rooms[newRoom.id] = newRoom;
        yield put(roomsActions.setUserRooms({ rooms, activeRoomID: newRoom.id }));
      } else {
        const arrayRoom = Object.values(rooms);
        for (let i = 0; i < arrayRoom.length; i++) {
          if (arrayRoom[i].client_id === searchClientID) {
            activeRoomID = arrayRoom[i].id;
            break;
          }
        }

        yield put(roomsActions.setActiveRoom(activeRoomID));
      }

      yield put(roomListActions.setState(false));
    } catch (error) {
      yield put(roomsActions.setActiveRoom(''));
      yield put(bindClientActions.setState(false));
      yield call(toast.error, `tryBindClient-saga: ${error.message}`);
      logError(error);
    }
  } else {
    console.log(`tryBind invalid searchClientID value:${searchClientID}`);
  }
}
